import logo from '../../../../assets/images/evolve.png';
import orangeAbs from '../../../../assets/images/evolveEnergy/orangeAbs.svg';
import Jumping from '../../../../assets/images/evolveEnergy/JumpingWomanSilhouette.svg';

const EnergyEvolutionBanner = () => {
    return (
        <div className="flex flex-col md:flex-row md:h-screen">
            {/* Left section */}
            <div className="text-white md:w-[40%] flex flex-col justify-center p-5 md:p-0 evolveBgDark">
                <div className='md:w-[80%] md:mx-auto'>
                    <div className='w-[130px] md:w-[200px] mb-5'>
                        <img src={logo} alt='' />
                    </div>

                    <h2 className="md:text-8xl text-xl flex-wrap md:flex-nowrap font-bold leading-tight mb-4 flex md:flex-col gap-2.5 md:items-start">
                        <span>ADVANCING</span>
                        <span>THE ENERGY</span>
                        <span className='relative text-white flex'>
                            EVOLUTION
                            <img src={orangeAbs} alt='orangeAbs' className='md:-mt-[70px] md:w-8 w-4 -mt-5' />
                        </span>
                    </h2>
                </div>
            </div>

            {/* Right section */}
            <div className="relative md:w-[60%]">
                <img
                    src={Jumping}
                    alt="Energy evolution concept"
                    className="w-full h-full object-cover"
                />
            </div>
        </div>
    );
};

export default EnergyEvolutionBanner;