import { createTheme } from "@mantine/core";
import { parsePhoneNumberFromString } from "libphonenumber-js";
export const theme = createTheme({});

export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const formatPhoneNumber = (phone: string) => {
  const phoneNumber = parsePhoneNumberFromString(phone);
  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.number;
  } else {
    return phone;
  }
};

export const testimonialsData = [
  {
    name: "Sean M.",
    title: "CEO of InnovateTech Solutions",
    review:
      "Partnering with Gaskon has been a game-changer for our business strategy. Their insights and support have been invaluable.",
  },
  {
    name: "James Thompson",
    title: "Founder of GreenWave Enterprises",
    review:
      "Gaskon's risk management strategies have been instrumental in mitigating potential threats and ensuring the stability of our company.",
  },
  {
    name: "Tracy H.",
    title: "Managing Director of SafeGuard Industries",
    review:
      "Gaskon's financial planning expertise helped us secure significant investment and streamline our operations. Highly recommend!",
  },
  {
    name: "Laura Martinez",
    title: "Co-Founder of BrightPath Innovations",
    review:
      "Thanks to Gaskon, we were able to develop a robust business strategy that propelled our startup to new heights.",
  },
  {
    name: "Emily D.",
    title: "COO of FinTech Growth Partners",
    review:
      "Their consulting services have been pivotal in guiding our strategic initiatives.",
  },
  {
    name: "Michael S.",
    title: "CTO of SecureNet Technologies",
    review:
      "Their technical expertise helped us overhaul our security infrastructure with outstanding results.",
  },
  {
    name: "Rachel K.",
    title: "CFO of Horizon Enterprises",
    review:
      "Gaskon's financial strategies have greatly improved our operational efficiency.",
  },
  {
    name: "David L.",
    title: "VP of Operations at Alpha Solutions",
    review:
      "We were able to successfully scale our operations thanks to their guidance.",
  },
];

export const statisticsData = [
  {
    value: "500+",
    label:
      "Businesses have contributed to growth & the achievement of their goals.",
  },
  {
    value: "1000+",
    label: "Projects culminated with outstanding success and excellence.",
  },
  {
    value: "$750M+",
    label: "Revenue Generated with different companies around the world.",
  },
  {
    value: "$1B+",
    label: "Funds raised to fuel innovation and growth worldwide.",
  },
];
