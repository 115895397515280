import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginBg from "../../../assets/images/icons/login-bg.png";
import AppleIcon from "../../../assets/images/icons/apple_logo.png.png";
import GoogleIcon from "../../../assets/images/icons/google_symbol.png.png";
import Logo from "../../../assets/images/icons/logo.svg";
import "./login.scss";
import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { InfoIcon } from "lucide-react";

interface JwtPayload {
  role: string;
  // Add other properties if needed
}

const ClientLogin = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [invalidField, setInvalidField] = useState("");
  const [invalidError, setInvalidError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateFields = () => {
    if (
      !/^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/.test(
        userName
      )
    ) {
      setInvalidField("credserror-USER");
      return false;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        password
      )
    ) {
      setInvalidField("credserror-PASSWORD");
      return false;
    }
    return true;
  };

  useEffect(() => {
    localStorage.removeItem("isAuthenticated");
    let authToken = Cookies.get("authToken");
    if (authToken && authToken !== "") {
      const payload = jwtDecode(authToken);
      if (
        payload &&
        typeof payload.exp === "number" &&
        payload.exp * 1000 > Date.now()
      ) {
        navigate("/dashboard");
      }
    }
  }, []);

  const handleLogin = () => {
    // if (!validateFields()) return;
    // if(userName === 'admin.lussolabs@gmail.com' && password === 'AZaz09$$') {
    //   navigate("/dev/dashboard");
    // }
    setIsLoading(true);
    let apiRequest = {
      email: userName,
      password: password,
    };
    axios
      .post("https://api.gaskon.io/api/v1/login", apiRequest)
      .then((response) => {
        console.log("response", response);
        let authToken = response.data?.token;
        Cookies.set("authToken", authToken, { expires: 7 }); // Expires in 7 days
        // if (response.data.isNewUser) {
        //   navigate('/auth/verify');
        // } else {
        let token = Cookies.get("authToken");
        console.log("$$$$$$$$$$$$$$$$$tokenin", token);
        if (token) {
          const payload = jwtDecode<JwtPayload>(token);
          // if (payload.role === 'user') {
          //   navigate('/explore');
          // } else if (payload.role === 'developer') {
          // let isSubscribed = Cookies.get('subscription');
          // if (isSubscribed === 'yes') {
          navigate("/dashboard");
          // } else {
          //   navigate('/subscribe');
          // }
          // }
        }
        // }
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
        setInvalidField("credserror");
      });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submission
    console.log("Inside handle submit");
    handleLogin();
  };

  return (
    <div className="min-h-screen md:min-h-auto md:h-screen flex flex-col justify-center md:flex-row">
      {/* Left Side: Form */}
      <div className="flex flex-col justify-center items-center w-full md:w-[55%] bg-white p-6">
        <form className="w-full md:max-w-lg" onSubmit={handleSubmit}>
          <div className="mb-8">
            <div className="md:h-[150px]">
              <img
                src={Logo}
                alt="Logo"
                className="cursor-pointer w-32 mx-auto md:mx-0 mb-5 md:mb-0"
                onClick={() => navigate("/home")}
              />
            </div>

            <div className="flex flex-col gap-4">
              <h1 className="text-lg font-semibold text-center md:text-start text-[#989898]">Sign in to your account</h1>
              <h2 className="text-4xl font-bold text-center md:text-start">ELEVATE YOUR <br /> BUSINESS WITH US</h2>
            </div>
          </div>

          <div className="bg-white w-full rounded-lg shadow-lg overflow-hidden p-4"
            style={{
              boxShadow: '0px 24px 54px 0px #18181812'
            }}>

            {/* Email Input */}
            <div className="border-b border-gray-200 mb-2">
              <label className="block text-sm font-medium text-gray-500 mb-1 px-2">Email Id</label>
              <input
                type="text"
                value={userName}
                onChange={(e) => {
                  setInvalidField("");
                  setUserName(e.target.value);
                }}
                className="block w-full text-gray-900 border-none focus:ring-0 p-2 focus:outline-none"
                placeholder="Enter your email"
              />
            </div>

            {/* Password Input */}
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-500 mb-1 px-2">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  setInvalidField("");
                  setPassword(e.target.value);
                }}
                className="block w-full text-gray-900 border-none focus:ring-0 p-2 focus:outline-none"
                placeholder="Enter your password"
              />
            </div>

            {invalidField && (
              <div className="text-red-600 text-sm mt-2 flex gap-1 items-center">
                <InfoIcon color="red" size={16} />
                {invalidField === "credserror-USER"
                  ? "Invalid mail"
                  : invalidField === "credserror-PASSWORD"
                    ? "Invalid Password"
                    : "Invalid mail/password"}
              </div>
            )}
          </div>

          <div className="text-right my-6">
            <Link to="/forgot" className="text-md font-medium uppercase text-[#989898] hover:text-gray-900">Forgot password?</Link>
          </div>

          <button className="w-full bg-black text-white py-2 rounded-md hover:bg-gray-800 font-medium text-lg">
            Sign In
          </button>

          <div className="my-4 text-center text-sm text-gray-500">or</div>

          <button className="w-full flex items-center justify-center bg-white border border-gray-300 py-2 rounded-md hover:bg-gray-100 mb-3">
            <img src={GoogleIcon} alt="Google Icon" className="w-5 mr-2" />
            Sign in with Google
          </button>

          <button className="w-full flex items-center justify-center bg-white border border-gray-300 py-2 rounded-md hover:bg-gray-100">
            <img src={AppleIcon} alt="Apple Icon" className="w-5 mr-2" />
            Sign in with Apple
          </button>

          <div className="text-start mt-3 font-bold">
            <Link to="/signup" className="text-md text-[#0177D1] underline">
              New User? <span>Sign Up</span>
            </Link>
          </div>

          <div className="text-center mt-10 text-sm text-[#989898]">
            By signing in, you agree to our
            <Link to="/terms-of-use" className="underline">Terms and Conditions</Link> &
            <Link to="/privacy-policy" className="underline">Privacy policy</Link>
          </div>
        </form>
      </div>

      {/* Right Side: Image */}
      <div className="hidden md:block md:w-[45%]" >
        <img
          src={LoginBg}
          alt="Background"
          className="w-full h-full object-cover"
        />
      </div >
    </div >
  );
};

export default ClientLogin;
