import React from 'react'
import NewTopHeader from '../../../../components/NewHeader'
import GaskonBanner from './Banner'
import TrustedPartnerSection from './TrustedPartnerSection'
import WhatWeDoSection from './WhatDoWeDo'
import NetworkingOpportunity from './NetworkingOpportunity'
import BenefitsOfListing from './BenefitsOfListing'
import DashboardShowcase from './DashboardShowcase'
import SecuritySection from './SecuritySection'
import WhiteLabelShowcase from './WhiteLabelShowcase'
import ContactSection from './ContactSection'
import FooterCommon from '../../../../components/common/FooterCommon'

function GaskonHome() {
  return (
    <div className='bg-white'>
      {/* Header */}
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <div style={{
          position: 'absolute', top: 10, width: '80%', backdropFilter: 'blur(30px)',
          background: '#FFFFFF30', zIndex: '1000', borderRadius: 30
        }}>
          <NewTopHeader />
        </div>
      </div>

      {/* banner */}
      <GaskonBanner />

      {/* Partner */}
      <TrustedPartnerSection />

      {/* What we do */}
      <WhatWeDoSection />

      {/* Networking */}
      <NetworkingOpportunity />

      {/* Benefits of Listing */}
      <BenefitsOfListing />

      {/* Show case */}
      <DashboardShowcase />

      {/* Security */}
      <SecuritySection />

      {/* White Label Showcase */}
      <WhiteLabelShowcase />

      {/* Contact */}
      <ContactSection />

      {/* Footer */}
      <FooterCommon />
    </div>
  )
}

export default GaskonHome