import React from 'react';
import './Overview.scss';
import QuickStats from '../../../assets/images/icons/quick-stats.svg';
import ActivePortfolios from '../../../assets/images/icons/active-portfolio.svg';
import EyeIcon from '../../../assets/images/icons/eye.svg';
import Inquiries from '../../../assets/images/icons/inquiries.svg';
import DollarIcon from '../../../assets/images/icons/dollar.svg';
import NewPortfolio from '../../../assets/images/NewProfile.png';

interface OverviewProps {
  handleCreatePortfolioClick: () => void;
}

const Overview: React.FC<OverviewProps> = ({handleCreatePortfolioClick}) => {
  return (
    <div className="overview">
      <div className='flexRow alignBetween'>
        <div>
          <h1>Welcome Yeshu 🤝</h1>
          <p>We're excited to have you onboard!</p>
        </div>
        <div>
          <button onClick={() => {
            console.log('checking')
            handleCreatePortfolioClick();
          }
          } className='NewPortfolioButton'>
            <img style={{height:20, width:20, marginRight:8}} src={NewPortfolio} alt='' />
            New Portfolio</button>
        </div>
      </div>
      <div className="stats">
        <div className="stat">
        <div className="icon-wrap"><img src={QuickStats} alt="QuickStats" className="icon" /></div>
          <div className="info">
            <span>Quick Stats (KPIs)</span>
            <strong>250</strong>
          </div>
        </div>
        <div className="stat">
        <div className="icon-wrap"><img src={ActivePortfolios} alt="ActivePortfolios" className="icon" /></div>
          <div className="info">
            <span>Active Portfolios</span>
            <strong>5</strong>
          </div>
        </div>
        <div className="stat">
        <div className="icon-wrap"><img src={Inquiries} alt="Inquiries" className="icon" /></div>
          <div className="info">
            <span>Investor Inquiries</span>
            <strong>10</strong>
          </div>
        </div>
        <div className="stat">
        <div className="icon-wrap"><img src={EyeIcon} className="icon" /></div>
          <div className="info">
            <span>Total Views</span>
            <strong>1,200</strong>
          </div>
        </div>
        <div className="stat">
          <div className="icon-wrap"><img src={DollarIcon} className="icon" /></div>
          <div className="info">
            <span>Revenue Generated</span>
            <strong>$178,890</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
