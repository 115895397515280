import React from 'react';
import './FinancialOverview.scss';

const FinancialOverview: React.FC = () => {
  return (
    <div className="financial-overview">
      <div className="header">
        <h2>Financial Overview</h2>
        <a href="#">View More</a>
      </div>
      <div className="budget">
        <span>Total Budget</span>
        <strong>$1,00,000</strong>
      </div>
      <div className="progress">
        <div className="progress-bar">
          <div className="spent"></div>
          <div className="remaining"></div>
        </div>
        <div className="info">
          <span>Spent: $600,000</span>
          <span>Remaining: $400,000</span>
        </div>
      </div>
    </div>
  );
};

export default FinancialOverview;
