import React, { useState } from 'react';
import graph from './Image/graph.png'

const TabButton = ({ active, onClick, children }: any) => (
    <button
        className={`pe-4 py-2 font-normal ${active ? 'text-black border-b-[1px] border-b-gray-300' : 'text-[#767676]'}`}
        onClick={onClick}
    >
        {children}
    </button>
);

const FinancialPerformance = () => {
    const [activeTab, setActiveTab] = useState('revenue');

    return (
        <div className='relative text-[#414141]'>
            {/* Top line (desktop only) */}
            <div className="hidden md:block absolute top-0 left-0 right-0 h-px bg-gray-200"></div>

            <div className='md:max-w-[90%] mx-auto relative p-3'>
                <div className="relative">
                    {/* Left line (desktop only) */}
                    <div className="hidden md:block absolute top-0 bottom-0 left-0 w-px bg-gray-200"></div>

                    <div className="pt-8 pl-8">
                        <h1 className="text-3xl font-semibold mb-6">Financial Performance</h1>

                        <div className="mb-4">
                            <TabButton active={activeTab === 'revenue'} onClick={() => setActiveTab('revenue')}>
                                Revenue Growth
                            </TabButton>
                            <TabButton active={activeTab === 'profit'} onClick={() => setActiveTab('profit')}>
                                Profit Growth
                            </TabButton>
                        </div>

                        {activeTab === 'revenue' && (
                            <div className="object-cover bg-no-repeat h-fit">
                                <img src={graph} alt="Revenue Graph" className="w-full h-auto" />
                            </div>
                        )}
                        {activeTab === 'profit' && (
                            <div>
                                <p>Profit Growth content goes here</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default FinancialPerformance;