import React from 'react'
import icons from './Image/Vector.png'
import './css/portfolio.scss'

export default function Key_Highlights() {
    return (
        <div className='kbannar flex flex-col md:flex-row justify-center items-center gap-5 md:gap-12 p-5 md:p-12'>
            <h2 className='md:text-5xl text-3xl capitalize font-bold'>Key Highlights</h2>
            <div className='flex flex-col md:flex-row justify-center items-center gap-3 md:gap-8'>
                <div className='kli'>
                    <img src={icons} className='klimg' />
                    <p className='kp'>Diverse property portfolio across prime locations.</p>
                </div>

                <div className='kli'>
                    <img src={icons} className='klimg' />
                    <p className='kp'>Diverse property portfolio across prime locations.</p>
                </div>

                <div className='kli'>
                    <img src={icons} className='klimg' />
                    <p className='kp'>Diverse property portfolio across prime locations.</p>
                </div>
            </div>
        </div>
    )
}
