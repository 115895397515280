import React from 'react';
import './Portfolios.scss';

const Portfolios: React.FC = () => {
  return (
    <div className="portfolios">
      <div className="header">
        <h2>Your Portfolios</h2>
        <a href="#">View all</a>
      </div>
      <div className="portfolio-list">
        <div className="portfolio">
          <div className="info">
            <strong>Tech Innovators Inc.</strong>
            <a href="#">View Details</a>
          </div>
          <div className="status in-review">In Review</div>
        </div>
        <div className="portfolio">
          <div className="info">
            <strong>Green Energy Solutions</strong>
            <a href="#">View Details</a>
          </div>
          <div className="status approved">Approved</div>
        </div>
        <div className="portfolio">
          <div className="info">
            <strong>HealthTech Pioneers</strong>
            <a href="#">View Details</a>
          </div>
          <div className="status in-review">In Review</div>
        </div>
      </div>
    </div>
  );
};

export default Portfolios;
