import React from 'react'
import './css/plan.scss'
import city1 from './Image/city1.png'
import city2 from './Image/city2.png'
import city3 from './Image/city3.png'

export default function Plan() {
    const ComparisonTable = () => {
        return (
            <div className="md:w-full overflow-x-auto">
                <table className="w-full border-collapse border-[.5px] border-[#76767680]">
                    <thead className='bg-transparent'>
                        <tr className="text-lg">
                            <th className="p-3 text-left font-medium text-gray-600 border">Metric</th>
                            <th className="p-3 text-left font-medium text-black border bg-[#F3EBCF] border-b-[.5px] border-b-[#76767680]">Century 21</th>
                            <th className="p-3 text-left font-medium text-gray-600 border">ABC Realty</th>
                            <th className="p-3 text-left font-medium text-gray-600 border">XYZ Developments</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="text-lg">
                            <td className="p-3 border text-gray-600">Average Property Value</td>
                            <td className="p-3 border font-medium bg-[#F3EBCF]">$500,000</td>
                            <td className="p-3 border text-gray-600">$480,000</td>
                            <td className="p-3 border text-gray-600">$470,000</td>
                        </tr>
                        <tr className="text-lg">
                            <td className="p-3 border text-gray-600">ROI (5-year average)</td>
                            <td className="p-3 border font-medium bg-[#F3EBCF]">20%</td>
                            <td className="p-3 border text-gray-600">18%</td>
                            <td className="p-3 border text-gray-600">17%</td>
                        </tr>
                        <tr className="text-lg">
                            <td className="p-3 border text-gray-600">Sustainability Certifications</td>
                            <td className="p-3 border font-medium bg-[#F3EBCF]">LEED Platinum</td>
                            <td className="p-3 border text-gray-600">LEED Gold</td>
                            <td className="p-3 border text-gray-600">LEED Silver</td>
                        </tr>
                        <tr className="text-lg">
                            <td className="p-3 border text-gray-600">Green Building Initiatives</td>
                            <td className="p-3 border font-medium bg-[#F3EBCF]">Advanced</td>
                            <td className="p-3 border text-gray-600">Moderate</td>
                            <td className="p-3 border text-gray-600">Basic</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    // render
    return (
        <>
            <div className='md:max-w-[90%] mx-auto relative p-3'>
                <div className="hidden md:block absolute top-0 bottom-0 left-10 w-px bg-gray-200"></div>
                <div>
                    <div className='row1 flex md:flex-row flex-col justify-center relative'>
                        <div className='order-2 md:order-1 plleft relative md:py-12 md:pe-8 md:w-[50%] flex flex-col justify-center gap-2'>
                            <h3 className='pltit text-xl font-medium mb-1'>
                                Increasing Demand for Sustainable Properties:
                            </h3>
                            <p className='pldesc text-md text-[#767676] md:w-[80%]'>
                                Consumers are increasingly prioritizing eco-friendly and energy-efficient properties. UrbanNest Realty meets this demand through a strong commitment to sustainable building practices. Our developments feature eco-friendly designs and technologies, which have earned us multiple certifications and awards. Our properties include energy-efficient appliances, green roofing, solar panels, and sustainable materials, all designed to reduce environmental impact and operating costs for residents. By prioritizing sustainability, we not only contribute to a healthier planet but also enhance property value and appeal to a growing segment of environmentally conscious buyers.
                            </p>
                            <div className="hidden md:block absolute top-0 bottom-0 -right-5 w-px bg-gray-200"></div>
                        </div>
                        <div className='plright flex justify-end order-1 md:order-2'>
                            <img src={city1} className='object-cover w-full' />
                        </div>
                        <div className="hidden md:block absolute bottom-0 left-0 right-0 h-px bg-gray-200"></div>
                    </div>

                    <div className='row1 flex md:flex-row flex-col justify-center relative'>
                        <div className='pllefti order-1 md:order-1'>
                            <img src={city2} className='object-cover w-full' />
                        </div>

                        <div className='order-2 md:order-2 plrighti relative md:py-12 md:pe-8 md:w-[50%] flex flex-col justify-center gap-2'>
                            {/* <div className='plrighti'> */}
                            <h3 className='pltit text-xl font-medium mb-1'>
                                Increasing Demand for Sustainable Properties:
                            </h3>
                            <p className='pldesc text-md text-[#767676] md:w-[80%]'>
                                Consumers are increasingly prioritizing eco-friendly and energy-efficient properties. UrbanNest Realty meets this demand through a strong commitment to sustainable building practices. Our developments feature eco-friendly designs and technologies, which have earned us multiple certifications and awards. Our properties include energy-efficient appliances, green roofing, solar panels, and sustainable materials, all designed to reduce environmental impact and operating costs for residents. By prioritizing sustainability, we not only contribute to a healthier planet but also enhance property value and appeal to a growing segment of environmentally conscious buyers.
                            </p>
                            <div className="hidden md:block absolute top-0 bottom-0 -left-10 w-px bg-gray-200"></div>
                        </div>
                    </div>

                    <div className='row1 flex md:flex-row flex-col justify-end'>
                        <div className='order-2 md:order-1 plleft relative md:py-12 md:pe-8 md:w-[50%] flex flex-col justify-end gap-2'>
                            <h3 className='pltit text-xl font-medium mb-1'>
                                Key Competitors
                            </h3>
                            <p className='pldesc text-md text-[#767676] md:w-[80%]'>
                                To illustrate UrbanNest Realty's competitive edge, we provide a detailed comparative analysis against key competitors, ABC Realty and XYZ Developments. This comparison focuses on critical metrics such as property value, return on investment (ROI), and sustainability initiatives.
                            </p>
                            <div className="hidden md:block absolute top-0 bottom-0 right-0 w-px bg-gray-200"></div>

                        </div>
                        <div className='plright order-1 md:order-2'>
                            <img src={city3} className='object-cover w-full' />
                        </div>
                    </div>
                </div>

                {/* Table */}
                <div className='md:ms-2 p-5 w-full flex md:flex-row md:justify-start mt-5'>
                    <ComparisonTable />
                </div>
                
            </div>

        </>
    )
}
