import React, { useState } from 'react';
import Logo from '../../../assets/images/icons/logo.svg';
import GreenTick from '../../../assets/images/icons/green_tick.svg';
import RedTick from '../../../assets/images/icons/wrongIcon.png';
import RightArrow from '../../../assets/images/icons/arrow-right-cropped.svg';
import EyeIcon from '../../../assets/images/icons/eye-icon.png';
import '../signup/SignUp.scss';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const ResetPassByToken = () => {
    const [password, setPassword]: any = useState('');
    const [confirmPassword, setConfirmPassword]: any = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [invalidField, setInvalidField] = useState('');
    const navigate = useNavigate()
    const [rule1, setRule1] = useState(false);
    const [rule2, setRule2] = useState(false);
    const [rule3, setRule3] = useState(false);


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const validatePassword = (password: any) => {
        // Rule 1: At least 8 characters
        setRule1(password.length >= 8)

        // Rule 2: Contain a number or symbol
        setRule2(/[\d@$!%*?&]/.test(password));

        // Rule 3: Include a mix of uppercase and lowercase letters
        setRule3(/^(?=.*[a-z])(?=.*[A-Z])/.test(password));

        return true;

    };

    const validateConfirmPassword = (pass: any) => {
        return pass == password;
    }


    const resetPassword = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent default form submission

        if (!validatePassword(password) || (password !== confirmPassword)) {
            toast.warning('Password not valid');
            return
        };

        setLoading(true);
        const body = {
            "isNewUser": false,
            "oldPassword": "string",
            "newPassword": "string"
        }

        axios
            .post(process.env.REACT_APP_BASE_URL + 'resetPassword', body)
            .then((resp) => {
                setLoading(false);
                toast.success('Password reset successfully');
                navigate('/login'); // Redirect to login after successful reset
            })
            .catch((err) => {
                console.log(err?.response?.data);
                setLoading(false);
                if (err?.response?.data?.message) {
                    toast.warning(err?.response?.data?.message);
                }
                console.error(err);
            });
    };


    return (
        <div className="relative">
            <div className="max-w-xl mx-auto px-4 py-8">
                <div className="flex flex-col md:flex-row gap-4 md:justify-center items-center mb-8">
                    <Link to="/login" className="flex items-center text-black hover:text-gray-700 absolute left-6">
                        <img src={RightArrow} alt="Back" className="w-4 h-4 mr-2 transform" />
                        <span>Back</span>
                    </Link>
                    <img src={Logo} alt="Gaskon Logo" className="w-32 cursor-pointer"
                        onClick={() => navigate('/home')} />
                </div>

                <h1 className="text-3xl font-bold text-center mb-4">RESET YOUR PASSWORD</h1>
                <p className="text-gray-500 text-center mb-6">Almost done. Enter your new password and you’re good to go.</p>

                <form onSubmit={resetPassword}>
                    <div className="mx-auto md:p-6 space-y-1">
                        <div
                            className="bg-white rounded-lg space-y-4 py-6"
                            style={{ boxShadow: '0px 24px 54px 0px #18181812', border: '1px solid #E8E8E8' }}
                        >
                            <div className="space-y-4">
                                <div className="border-b border-gray-300 px-6">
                                    <label className="block text-sm text-gray-500 mb-1">New Password*</label>
                                    <div className="relative">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={(e) => {
                                                setInvalidField('');
                                                setPassword(e.target.value);
                                                validatePassword(e.target.value);
                                            }}
                                            className="w-full p-2 text-lg font-semibold focus:outline-none focus:border-blue-500 focus:bg-transparent"
                                        />
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 flex items-center pr-3"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            <img src={EyeIcon} alt="Toggle password visibility" className="h-5 w-5 text-gray-400" />
                                        </button>
                                    </div>
                                </div>

                                <div className="px-6">
                                    <label className="block text-sm text-gray-500 mb-1">Confirm New Password*</label>
                                    <div className="relative">
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            value={confirmPassword}
                                            onChange={(e) => {
                                                setInvalidField('');
                                                setConfirmPassword(e.target.value);
                                                validateConfirmPassword(e.target.value);
                                            }}
                                            className="w-full p-2 text-lg font-semibold focus:outline-none focus:border-blue-500 focus:bg-transparent"
                                        />
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 flex items-center pr-3"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        >
                                            <img src={EyeIcon} alt="Toggle confirm password visibility" className="h-5 w-5 text-gray-400" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-lg pt-5">
                            <div className="password-strength-ct w-full">
                                <span className="uppercase">Password Strength: </span>
                                <span
                                    style={{
                                        color:
                                            rule1 && rule2 && rule3
                                                ? 'green'
                                                : (rule1 && rule2) || (rule1 && rule3) || (rule2 && rule3)
                                                    ? '#A78837'
                                                    : 'red',
                                    }}
                                    className="strength-status uppercase"
                                >
                                    {rule1 && rule2 && rule3
                                        ? 'Good'
                                        : (rule1 && rule2) || (rule1 && rule3) || (rule2 && rule3)
                                            ? 'Average'
                                            : 'Bad'}
                                </span>
                                <ul className="text-[#1e1e1e]">
                                    <li className={`${!rule1 ? 'text-[#989898] font-medium' : 'text-[#1e1e1e] font-bold'}`}>
                                        {rule1 ? <img src={GreenTick} alt="GreenTick" className="me-1" /> : <img src={RedTick} alt="RedTick" className="me-1" />}
                                        At least 8 characters
                                    </li>
                                    <li className={`${!rule2 ? 'text-[#989898] font-medium' : 'text-[#1e1e1e] font-bold'}`}>
                                        {rule2 ? <img src={GreenTick} alt="GreenTick" className="me-1" /> : <img src={RedTick} alt="RedTick" className="me-1" />}
                                        Contain a number or symbol
                                    </li>
                                    <li className={`${!rule3 ? 'text-[#989898] font-medium' : 'text-[#1e1e1e] font-bold'}`}>
                                        {rule3 ? <img src={GreenTick} alt="GreenTick" className="me-1" /> : <img src={RedTick} alt="RedTick" className="me-1" />}
                                        Include a mix of uppercase & lowercase letters
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex justify-center pt-6">
                            <button
                                type="submit"
                                disabled={password !== confirmPassword || loading || password?.trim() === ''}
                                className={`w-full disabled:cursor-not-allowed bg-[#1e1e1e] text-white py-4 font-bold text-lg rounded-lg flex justify-center items-center ${loading ? 'opacity-50' : ''}`}
                            >
                                {loading ? (
                                    <>
                                        <svg
                                            className="animate-spin h-5 w-5 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                            ></path>
                                        </svg>
                                        Resetting...
                                    </>
                                ) : (
                                    'Reset Password'
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    )
}

export default ResetPassByToken;