import React, { useState } from 'react';
import './evolvehome.scss';
import Bg1 from './image/evolvebackground1.png'; // Adjust the path based on your project structure
// import logo from './image/evolvelogo.svg';
import logo from '../../../../assets/images/evolve.png';
import Bg2 from '../../../../assets/images/icons/Bg2.mp4';
import Files from '../../../../assets/images/Files.png';
import FinTool from '../../../../assets/images/FinTool.png';
import Flowerpot from '../../../../assets/images/Flowerpot.png';
import Document from '../../../../assets/images/Document.png';
import Battery from '../../../../assets/images/Battery.png';
import MarketTailWind from '../../../../assets/images/MarketTailwind.png';
import EnergySolution from '../../../../assets/images/EnergySolution.png';
import EnergySolutionSquare from '../../../../assets/images/EnergySolutionSquare.png';
import OpenIcon from '../../../../assets/images/openGreenBg.png';
import CloseIcon from '../../../../assets/images/closeGreenBg.png';
import IMOne from '../../../../assets/images/IMOne.png';
import IMTwo from '../../../../assets/images/IMTwo.png';
import IMThree from '../../../../assets/images/IMThree.png';
import imageFour from '../../../../assets/images/imageFour.png';
import imageFive from '../../../../assets/images/imageFive.png';
import imageSix from '../../../../assets/images/imageSix.png';
import Diagram from '../../../../assets/images/BigDiagram.png';
import DiagramMobile from '../../../../assets/images/BigDiagramMobile.png';
import Jumping from '../../../../assets/images/JumpingWomanSilhouette.png';
import Flower from '../../../../assets/images/Flower.svg';
import Stars from '../../../../assets/images/StarShining.png';
import Highlight from '../../../../assets/images/Highlight.png';
import Growth from '../../../../assets/images/ProjectGrowth.png';
import Group from '../../../../assets/images/GroupMem.png';
import Charge from '../../../../assets/images/Charge.png';
import solar from '../../../../assets/images/solar.png';
import thumbsup from '../../../../assets/images/thumbsup.png';
import tickChecked from '../../../../assets/images/tickChecked.png';
import BusinessModelBg from '../../../../assets/images/businessModelBg.png';
import LeftDesign from '../../../../assets/images/left_vector_design.png';
import RightDesign from '../../../../assets/images/right_vector_design.png';
import HighLight from '../../../../assets/images/Highlight_05.png'
import Dashboard from '../../../../components/Dashboard';
import ProfileCard from '../../../../components/ProfileCard';
import Timeline from '../../../../components/Timeline';
import Footer from '../../../../components/Footer';
import TopHeader from '../../../../components/NewHeader';
import FounderSection from '../../../../components/FounderSection';
import lineIcon from '../../../../assets/images/evolveEnergy/line.svg'
import lineTransparentIcon from '../../../../assets/images/evolveEnergy/line-transparent.svg'
import blackLine from '../../../../assets/images/evolveEnergy/blackLine.svg'
import c1 from '../../../../assets/images/evolveEnergy/c1.svg'
import c2 from '../../../../assets/images/evolveEnergy/c2.svg'
import c3 from '../../../../assets/images/evolveEnergy/cardBG.svg'
import c4 from '../../../../assets/images/evolveEnergy/c4.svg'
import c5 from '../../../../assets/images/evolveEnergy/c5.svg'
import card5Bg from '../../../../assets/images/evolveEnergy/card5bg.svg'
import c6 from '../../../../assets/images/evolveEnergy/c6.svg'
import keyIcon from '../../../../assets/images/evolveEnergy/key.svg'
import quoteIcon from '../../../../assets/images/evolveEnergy/quote.png'
import cursorFollower from '../../../../assets/images/evolveEnergy/CursorFollower.svg'
import EvolveEnergyGrid from './EvolveEnergyGrid';
import InvestmentOptions from './InvestmentOptions';
import EnergyEvolutionBanner from './EnergyEvolutionBanner';
import GetInTouchButton from '../../../../components/Contact_Us/GetInTouchButton';
const milestones = [
  { date: '2012-2013', description: 'He began his career at Green Mountain Energy, earning the Power Player Award in 2012 and 2013.' },
  { date: '2013', description: 'Joined Source Power & Gas in 2013, contributing to its rapid growth and subsequent acquisition by ERM Power.' },
  { date: '2015', description: 'Founded CNRG Group in 2015, focusing on integrating retail power with comprehensive energy solutions.' },
  { date: 'Project', description: 'Led a significant $12 million energy efficiency project for the Dallas Cowboys, resulting in the formation of Environ Partners and later Pro Star Energy Solutions.' },
];

const energySolutions = [
  {
    name: 'Solar',
    solutions: ['Rooftop Distributed Generation', 'Utility Scale & Integrated Hedging Strategy', ' Solar + Storage']
  },
  {
    name: 'Led Lighting',
    solutions: ['Lighting Retrofit', 'New Construction']
  },
  {
    name: 'Procurement',
    solutions: ['Negotiating Rates & Terms', 'Energy Monitoring & Reporting']
  },
  {
    name: 'Demand Response',
    solutions: ['Grid Balance & Rebates', 'Backup Generation']
  },
  {
    name: 'HVAC Optimisation',
    solutions: ['HVAC Health Assessment Optimization']
  },
  {
    name: 'IOT',
    solutions: ['Advanced Monitoring', 'Automation']
  }
]

const businessModels = [
  {
    name: 'Power Purchase Agreements (PPAs)',
    description: 'Evolve Energy offers investment opportunities with substantial tax benefits.',
    solutions: ['REAP Grants: Cover 50% of project costs', 'Tax Credits: Cover 30-50% of project costs.']
  },
  {
    name: 'Financial Strategy',
    description: 'Evolve Energy employs a financial strategy that allows clients to finance the development of commercial solar farms and grid infrastructure, providing them with significant returns and tax benefits.',
    solutions: [
      'Investment in infrastructure development with tax incentives.',
      'Clients receive long-term returns from renewable energy projects.'
    ]
  },
  {
    name: 'Acquisitions',
    description: 'Evolve Energy is actively acquiring books of business to expand its renewable energy portfolio, including a current negotiation for a $60M infrastructure acquisition.',
    solutions: [
      'Acquisition of high-value renewable energy assets.',
      'Negotiation for $60M worth of existing infrastructure projects.'
    ]
  }
]

const investmentModels = [
  {
    number: '01',
    title: 'Equity Position',
    description: 'Currently, 6% equity available in a high-growth solar project (Three Nails).',
    image: IMOne, // Replace with the actual path
    backgroundColor: '#29A66D',
  },
  {
    number: '02',
    title: 'REAP Projects',
    description: 'ROI: 6-9 months with 180% return.Tax Credits: Receive 40% of the job cost in transferable tax credits at .80/1 value.Down Payment: 50% of the project cost.',
    image: IMThree, // Replace with the actual path
    backgroundColor: '#2C2C2C',
  },
  {
    number: '03',
    title: 'PPA Deals',
    description: 'Detailed descriptions pending.',
    image: IMTwo, // Replace with the actual path
    backgroundColor: '#29A66D',
  },
  {
    number: '04',
    title: 'Short-Term Returns',
    description: 'Returns: 15% on a 6-month investment timeline.Ideal for investors unable to use tax credits and not classified as C corporations.Involves pre-approved REAP Grant projects.',
    image: imageFour, // Replace with the actual path
    backgroundColor: '#29A66D',
  },
  {
    number: '05',
    title: 'Tax Credit Purchase',
    description: 'Returns: Purchase tax credits at .90¢ on the dollar plus 10%.Suitable for investors seeking straightforward, immediate ROI.Tax credits are placed and serviced, with investments held in an IRA for security.',
    image: imageFive, // Replace with the actual path
    backgroundColor: '#29A66D',
  },
  {
    number: '06',
    title: 'Prepaid Tax Credits',
    description: 'Returns: Purchase tax credits at 75¢ on the dollar, with 20% over 30 years.Requires funding before construction, ideal for long-term partners sharing in project profits from inception.',
    image: imageSix, // Replace with the actual path
    backgroundColor: '#29A66D',
  },
];
const EvolveHome: React.FC = () => {

  const [openIndex, setOpenIndex] = useState(0);

  const EducationalCertificates = () => {
    return (
      <div className="education-certifications">
        <div className="title-container">
          <div className='flex gap-1.5'>
            <img src={Stars} alt='' className='w-10' />
            <h1>EDUCATION</h1>
          </div>
          <h1>CERTIFICATIONS</h1>
        </div>
        <div className="certifications-container">
          <div className="certifications-column">
            <ul>
              <li>Harvard Tax Law</li>
              <li>Yale Taxation Program</li>
              <li>Master Electrician</li>
            </ul>
          </div>
          <div className="certifications-column">
            <ul>
              <li>Legal Masters of Taxation NYU</li>
              <li>College Professor of Finance</li>
            </ul>
          </div>
          <div className="certifications-column">
            <ul>
              <li>Certified Public Accountants (CPAs)</li>
              <li>Certified Financial Planners (CFPs)</li>
            </ul>
          </div>
        </div>
        {/* <div className='flower'>
          <img src={Flower} alt='' />
        </div> */}
      </div>
    );
  }

  const ProjectGrowth = () => {
    return (
      <div className="project-growth">
        <div className="title-container">
          <h1>PROJECT</h1>
          <div className='items-center w-9 h-9 md:w-[60px] md:h-[60px]' style={{ display: 'flex', gap: 16 }}>
            <img src={Growth} alt="Growth" />
            <h1>GROWTH</h1>
          </div>
        </div>
        <ul className="details-container gap-10 md:gap-[150px] p-3 md:py-[50px]">
          <li className="detail">
            <strong className={'md:text-[2rem] text-[1.5rem]'}>Solar Farms:</strong> Each project spans 30 acres with a contract value between $60-90M, projected to last 30 years.
          </li>
          <li className="detail">
            <strong className={'md:text-[2rem] text-[1.5rem]'}>Evolve Energy Goal:</strong> Completion of 20 such projects within the year.
          </li>
          <li className="detail">
            <strong className={'md:text-[2rem] text-[1.5rem]'}>Projected Multiplier:</strong> A conservative estimate of a 20X return.
          </li>
        </ul>
        {/* <div className='flower'>
          <img src={Flower} alt='' />
        </div>
        <div className='left_vector_design'>
          <img src={LeftDesign} alt='' />
        </div>
        <div className='right_vector_design'>
          <img src={RightDesign} alt='' />
        </div> */}
      </div>
    );
  }

  const FinancialOverview = () => {
    return (
      <div className="evolve-financial-overview md:max-w-[90%] px-[30px] md:px-[90px] py-[90px] md:py-[120px]">
        <div className="section">
          {
            HeadingTitle('Current Financial Movements', 'bold')
          }
          <div className='leftBorder mt-5 pl-5 md:pl-8'>
            <div className="movement">
              <div className="details">
                <p className='flex gap-1.5 text-md md:text-2xl font-medium'>
                  <img src={blackLine} className='w-[2px]' />
                  Funds Transacted</p>
                <h3 className='font-medium text-xl md:text-4xl'>$5M SUCCESSFULLY SECURED.</h3>
              </div>
            </div>
            <div className="movement pt-5">
              <div className="details">
                <p className='flex gap-1.5 text-md md:text-2xl font-medium'>
                  <img src={blackLine} className='w-[2px]' />
                  In Process:</p>
                <h3 className='font-medium text-xl md:text-4xl'>$30M EXPECTED TO BE FINALIZED BY THE END OF NEXT WEEK</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="section" style={{ marginTop: 105 }}>
          {
            HeadingTitle('Financial Structuring', 'bold')
          }
          <div className='leftBorder mt-5 pl-5 md:pl-8'>
            <div className="movement">
              <div className="details">
                <p className='flex gap-1.5 text-md md:text-2xl font-medium'>
                  <img src={blackLine} className='w-[2px]' />
                  Investment Structure</p>
                <h3 className='font-medium text-xl md:text-4xl'>RECORDED AS DEBT</h3>
              </div>
            </div>
            <div className="movement pt-5">
              <div className="details">
                <p className='flex gap-1.5 text-md md:text-2xl font-medium'>
                  <img src={blackLine} className='w-[2px]' />
                  Compensation</p>
                <h3 className='font-medium text-xl md:text-4xl'>AT ACQUISITION, INVESTORS ARE COMPENSATED FOR BOTH THE DEBT AND THE FACE VALUE OF SHARES.</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const SecuredFunds = () => {
    return (
      <div className="secured-funds">
        <div className="headline">
          OVER $10B SECURED FROM PRESTIGIOUS INSTITUTIONS INCLUDING JPMORGAN, ICARUS, ORKIN, AND MARCUM.
        </div>
        <div className="subtext">
          <strong>Power Purchase Agreements (PPAs):</strong> Funds designated for PPAs, with equity sourced from close networks.
        </div>
        {/* <div className='flower'>
          <img src={Flower} alt='' />
        </div> */}
      </div>
    );
  }

  const HeadingTitle = (title: any, isBold: any) => {
    return (
      <div className='headingTitle'>
        <img className='highlightImage' src={HighLight} alt='' />
        {
          isBold ?
            <h1 className='text-xl md:text-5xl font-bold'>{title}</h1>
            :
            <h1>{title}</h1>
        }
      </div>
    )
  }

  const GradientCode = () => {
    return (
      <div className='hidden md:block'>
        <div style={{ position: 'absolute', top: 50, left: 0 }}>
          <div style={{
            width: 300, height: 300, background: 'linear-gradient(127deg, rgba(13, 237, 212, 0.47) 0%, rgba(13, 237, 212, 0) 100%)', borderRadius: 9999, filter: 'blur(100px)',
            marginTop: 220,
            marginLeft: 30
          }} />
        </div>
        <div style={{ position: 'absolute', top: 70, left: 100 }}>
          <div style={{
            marginTop: 220,
            marginLeft: 30,
            width: 300, height: 300, background: 'linear-gradient(127deg, rgba(13, 237, 212, 0.47) 0%, rgba(13, 237, 212, 0) 100%)', borderRadius: 9999, filter: 'blur(100px)'
          }} />
        </div>
        {/* <div style={{position:'absolute', top:120, left: 140}}>        
          <div style={{width:100, height:100, background: 'linear-gradient(127deg, rgba(13, 237, 212, 0.47) 0%, rgba(13, 237, 212, 0) 100%)', borderRadius: 9999, filter: 'blur(20px)'}} />
        </div>
        <div style={{position:'absolute', top:140, left: 140}}>        
          <div style={{width:100, height:100, background: 'linear-gradient(127deg, rgba(13, 237, 212, 0.47) 0%, rgba(13, 237, 212, 0) 100%)', borderRadius: 9999, filter: 'blur(20px)'}} />
        </div>
        <div style={{position:'absolute', top:140, left: 160}}>        
          <div style={{width:100, height:100, background: 'linear-gradient(127deg, rgba(13, 237, 212, 0.47) 0%, rgba(13, 237, 212, 0) 100%)', borderRadius: 9999, filter: 'blur(20px)'}} />
        </div>
        <div style={{position:'absolute', top:160, left: 160}}>        
          <div style={{width:100, height:100, background: 'linear-gradient(127deg, rgba(13, 237, 212, 0.47) 0%, rgba(13, 237, 212, 0) 100%)', borderRadius: 9999, filter: 'blur(20px)'}} />
        </div>
        <div style={{position:'absolute', top:160, left: 180}}>        
          <div style={{width:100, height:100, background: 'linear-gradient(127deg, rgba(13, 237, 212, 0.47) 0%, rgba(13, 237, 212, 0) 100%)', borderRadius: 9999, filter: 'blur(20px)'}} />
        </div>
        <div style={{position:'absolute', top:180, left: 180}}>        
          <div style={{width:100, height:100, background: 'linear-gradient(127deg, rgba(13, 237, 212, 0.47) 0%, rgba(13, 237, 212, 0) 100%)', borderRadius: 9999, filter: 'blur(20px)'}} />
        </div> */}
      </div>
    )
  }

  return (
    <div className='container'>
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <div style={{
          position: 'absolute', top: 10, width: '80%', backdropFilter: 'blur(30px)',
          background: '#FFFFFF30', zIndex: '1000', borderRadius: 30
        }}>
          <TopHeader />
        </div>
      </div>
      <div className="investment-opportunity-page h-[80%]" style={{ backgroundImage: `url(${Bg1})` }}>
        <div className="content-wrapper md:w-[65%]">
          <img className="logo" src={logo} alt="Evolve Logo" />
          <div className="heading">Investment <br />Opportunity</div>
          <div className="year-wrapper">
            <div className="text-white font-medium flex gap-2.5 items-center text-xl">
              <img src={lineIcon} alt='line icon' />
              2024</div>
          </div>
        </div>
      </div>

      <div className="executive-summary-section">
        <div className="left-side">
          <video className="background-video " autoPlay loop muted>
            <source src={Bg2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="right-side">
          <div className="summary-content-wrapper z-10 my-5 md:my-0">
            <div className="summary-heading my-3 md:my-0">
              <img className="summary-icon" src={blackLine} alt="Icon" />
              <div className="summary-title">Executive Summary</div>
            </div>
            <div className="summary-text">
              At E-VoIve Energy, we're delivering comprehensive energy solutions for commercial and industrial clients, providing reliable infrastructure and production at below-market rates to drive sustainability and cost savings.
            </div>
          </div>
          {
            GradientCode()
          }
        </div>
      </div>

      <div className="energy-partnerships-container">
        <div className="heading-section my-5">
          {
            HeadingTitle('Expanding market presence via strategic Energy Partnerships', null)
          }
          <p>
            By leveraging key partnerships, we are able to expand market share and drive innovation in the energy sector.
            Our approach integrates the following core components to ensure success:
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-8 mx-auto md:mx-0 p-4">
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <img src={Files} alt="Precise Data Collection & Tracking" className="w-12 h-12 mb-4" />
            <span className="text-md md:text-2xl font-medium">Precise Data Collection & Tracking</span>
          </div>
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <img src={Document} alt="Strategic Electricity Contract Procurement" className="w-12 h-12 mb-4" />
            <span className="text-md md:text-2xl font-medium">Strategic Electricity Contract Procurement</span>
          </div>
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <img src={Battery} alt="Deployment of Energy Conservation Measures" className="w-12 h-12 mb-4" />
            <span className="text-md md:text-2xl font-medium">Deployment of Energy Conservation Measures</span>
          </div>
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <img src={Flowerpot} alt="Sustainable Generation/Consumption Offsets" className="w-12 h-12 mb-4" />
            <span className="text-md md:text-2xl font-medium">Sustainable Generation/Consumption Offsets</span>
          </div>
          <div className="flex flex-col items-center md:items-start text-center md:text-left md:col-start-2">
            <img src={FinTool} alt="Effective Use of Financial Tools" className="w-12 h-12 mb-4" />
            <span className="text-md md:text-2xl font-medium">Effective Use of Financial Tools</span>
          </div>
        </div>
      </div>

      <div className="market-tailwind-container"
      >
        <div className="left-section">
          <h1>MARKET</h1>
          <div className="icon-heading">
            <img src={MarketTailWind} alt="Market Icon" />
            <h2 className='-mb-3'>TAILWIND</h2>
          </div>
          <p>
            Leveraging key tax incentives and rebates, E-volve Energy reduces costs and enhances sustainability for clients.
          </p>
        </div>
        <div className="right-section mt-3 md:mt-0">
          <div className="list-item">
            <span className="number-outline">01</span>
            <span className="description">PTC & ITCTAX Credits</span>
          </div>
          <div className="list-item">
            <span className="number-outline">02</span>
            <span className="description">Utility Incentives</span>
          </div>
          <div className="list-item">
            <span className="number-outline">03</span>
            <span className="description">REAP Depreciations</span>
          </div>
          <div className="list-item">
            <span className="number-outline">04</span>
            <span className="description">Electric Buyback</span>
          </div>
        </div>
      </div>

      <div className='energy-solution-container'>
        <div className='left-section'>
          <div className='img-container'>
            <img className='bImage' src={EnergySolution} alt='' />
            <div className='text-container'>
              <div className='box'>
                {/* <div className='w-[80%] py-5 mx-auto'> */}
                <h1 className='md:text-9xl'>Innovative Energy Solution</h1>
                <span>We offer and provide a suite of comprehensive energy solutions designed to optimise eficiency, reduce costs, and promote sustainability for commercial and industrial clients.
                </span>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='right-section'>
          {
            energySolutions.map((energy, index) => {
              return (
                <div
                  onClick={() => { setOpenIndex(index) }}
                  key={energy.name} className='name-solution'>
                  <div className='name-icon'>
                    <h1>{energy.name}</h1>
                    {
                      openIndex === index
                        ?
                        <img src={OpenIcon} alt='' />
                        :
                        <img src={CloseIcon} alt='' />
                    }
                  </div>
                  {
                    openIndex === index
                      ?
                      <div>
                        <ul className='z-20'>
                          {
                            energy.solutions.map((solution, index) => {
                              return <li key={index} className='z-10 font-medium py-4'>{solution}</li>;
                            })
                          }
                        </ul>
                      </div>
                      :
                      ''
                  }
                  {
                    index !== energySolutions.length - 1
                      ?
                      <hr className='w-[55%] my-4'></hr>
                      : ''
                  }
                </div>
              );
            })
          }
          {
            GradientCode()
          }
        </div>
      </div>

      <div className='dashBoard-Stats p-3 md:p-8'>
        <div className='topstats'>
          <div className='basicInfo md:min-w-[45%]'>
            <div className='flex-col gap-3 md:w-1/3'>
              <div className='relative align-end green-card h-36'>
                <div className='absolute left-5 h-[100px] w-[100px]'>
                  <img src={c1} />
                </div>
                <span className='stat-name flex gap-1.5 text-md absolute right-2 bottom-[60px]'>
                  <img src={lineTransparentIcon} alt='black line' className='w-[3px]' /> Founded in
                </span>
                <span className='stat-count absolute right-2 bottom-1'>2019</span>
              </div>
              <div
                style={{
                  backgroundColor: '#2A2F2D', padding: 16,
                  minHeight: 100,
                  backgroundImage: `url(${c3})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}

                className='align-center flex-col rounded-2xl h-48 md:h-64'>
                <div className='flex flex-col'>
                  <span className='stat-name flex gap-1.5 text-lg'>
                    <img src={lineTransparentIcon} alt='black line' className='w-[3px]' />
                    Gross margin at scale
                  </span>
                  <span className='stat-count'>40- 50 %</span>
                </div>
              </div>
            </div>

            <div className='flex-col md:w-2/3' style={{
              gap: 16
            }}>
              <div className='relative flex-row h-36 md:h-32'
                style={{
                  backgroundColor: '#437E68', padding: 16, borderRadius: 12, justifyContent: 'flex-end'
                }}>
                <div className='absolute left-5 bottom-0'>
                  <img src={c2} />
                </div>                <span className='stat-name flex flex-row h-fit gap-1.5 mr-10'>
                  <img src={lineTransparentIcon} alt='black line' className='w-[3px]' />
                  Employees
                </span>
                <span className='stat-count -mt-2'>37</span>
              </div>
              <div className='h-48 md:h-auto relative card5bg' style={{
                backgroundColor: '#435B51',
                borderRadius: 12,
                padding: 10,
                position: 'relative'
              }}>
                {/* bottom zIndex */}
                {/* <div className=''>
                    <img src={c4} alt='img' className='absolute right-0 h-fit top-0 z-[1]' />
                  </div> */}

                {/* top zIndex */}
                <div className='align-start z-[10]'>

                  <span className='stat-name flex gap-1.5'>
                    <img src={lineTransparentIcon} alt='black line' className='w-[3px]' />
                    Headquarters
                  </span>
                  <span className='stat-count'
                  >GRAND PRAIRIE</span>
                  <span className='stat-name'>TEXAS</span>

                </div>
                <div className='align-end z-[10]'>
                  <span className='stat-name flex gap-1.5'>
                    <img src={lineTransparentIcon} alt='black line' className='w-[3px]' />
                    Office Location
                  </span>
                  <span className='stat-count'
                  >DALLAS</span>
                  <span className='stat-name'>TEXAS</span>
                </div>
              </div>
            </div>
          </div>

          <div className='revenue flex-1 hidden md:flex card3BG'>
            <div className='align-start'>
              <span className='stat-name flex gap-1.5'>
                <img src={lineTransparentIcon} alt='black line' className='w-[3px]' />
                12 month Gross Revenue (2022)</span>
              <span className='stat-count'>
                $20 M*
              </span>
            </div>
            <div className='align-end'>
              <span className='stat-name flex gap-1.5'>
                <img src={lineTransparentIcon} alt='black line' className='w-[3px]' />
                12 month Gross Revenue (2023)</span>
              <span className='stat-count'>
                $67 M*
              </span>
            </div>
          </div>
        </div>
        <div className='bottomstats'>
          <div
            className='bottomCard h-48 md:h-auto flex flex-col justify-center md:w-[26%] closeContractBG'
            style={{
              backgroundColor: '#339971',
            }}
          >
            <span className='stat-name flex gap-1.5'>
              <img src={lineTransparentIcon} alt='black line' className='w-[3px]' />
              Closed Contracts - 24Q1
            </span>
            <span className='stat-count text-4xl my-2 md:my-5'>
              $8.9 M
            </span>
          </div>
          <div
            className='bottomCard h-48 md:h-auto flex flex-col justify-center md:w-[26%] closeContract2BG'
            style={{
              backgroundColor: '#437E68',
            }}
          >
            <span className='stat-name flex gap-1.5'>
              <img src={lineTransparentIcon} alt='black line' className='w-[3px]' />
              Closed Contracts - 24Q2
            </span>
            <span className='stat-count text-4xl my-2 md:my-5'>
              $14 M
            </span>
          </div>
          <div
            className='bottomCard md:align-center hidden md:flex md:w-[48%]'
            style={{
              // width: '48%',
              backgroundColor: '#2A2F2D',
              padding: '48px 24px'
            }}
          >
            <div style={{ width: '80%' }}>
              <span className='energy'>
                E-Volve Energy
              </span>
              <div className='flex gap-2'>
                <img src={c6} alt='glance icon' />
                <span className='energy'>
                  at a Glance
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>



      {/* <div> */}
      <FounderSection />
      {/* </div> */}
      <div>
        <section className="grant-info bg-[#F3F3F3] py-0">
          <div className="usda-reap-grants">
            <h2 className='flex gap-1.5 text-3xl md:text-5xl mb-5 font-bold'>
              <img src={blackLine} alt='black line' className='w-[3px] md:w-[4px]' />
              USDA REAP Grants</h2>
            <div className="grant-details">
              <div className="grant-card">
                <h3>USDA REAP Grant - 50% of the total project cost</h3>
                <p>E-volve works closely with the USDA to complete the grant application process in-house so that our clients don't have to provide you with a seamless funding process.</p>
              </div>
              <div className="grant-card">
                <h3>Federal Investment Tax Credit: 30-50% Of the total</h3>
                <p>We are dedicated to renewable related and commercial developments. Our team of experienced developers has a proven track record of maximizing the value of incentives, including tax credits.</p>
              </div>
              <div className="grant-card">
                <h3>Depreciation: 20-30% 5-Year Schedule</h3>
                <p>Federal Depreciation:</p>
                <ul>
                  <li>State Depreciation</li>
                  <li>Bonus Depreciation (MACRS 5 Years)</li>
                </ul>
                <p>Increase cash flow and overall return of your project. Typical tax products purchase from 3 to 5 years, together with 25 years of new electricity generation.</p>
              </div>
            </div>
          </div>
          <div className="eligible-projects flex-col md:flex-row justify-center md:items-center">
            <h1 className='text-xl md:text-3xl font-bold'>Eligible Projects</h1>
            <span className='text-lg'> • Renewable Energy - Solar, Wind, Geothermal, Biomass. </span>
            <span className='text-lg'>  • Energy Efficiency Upgrades - Lighting, Batteries, HVAC & more.</span>
          </div>

          <div className="ppa-section p-5 md:pt-[100px] md:max-w-[90%] mx-auto">
            <h2 className='flex gap-1.5'>
              <img src={blackLine} alt='black line' className='w-[4px]' />
              Power Purchase Agreement (PPA)</h2>
            <p className='text-xl'>A Solar Power Purchase Agreement (PPA) works by having E-Volve install, own, & operate a solar energy system on a customer’s property. We handle the design, permitting, financing, installation, and ongoing maintenance of the system.</p>
            <p className='text-xl mt-10'>The customer agrees to purchase the generated solar energy at a predetermined rate, typically lower than local utility rates, with no upfront costs involved. The contract usually lasts for 10 to 30 years, during which the customer benefits from stable and predictable electricity costs.</p>
          </div>
          <div >
            <img className='diagram' src={Diagram} alt='' />
          </div>
          <div >
            <img className='diagramMobile' src={DiagramMobile} alt='' />
          </div>

          <div className="usda-reap-grants" style={{ background: '#f6fffc' }}>
            <div className='texasSolarBg'>
              <h2 className='flex gap-1.5 text-3xl md:text-5xl font-medium mb-5'>
                <img src={blackLine} alt='black line' className='w-[4px]' />
                The Texas Solar Advantage</h2>
              <p className='text-[#2A2A2A] text-xl py-5'>Texas's favorable conditions and policies make it a prime location for solar energy investment, and E-VoIve Energy is ready to lead the charge.
              </p>
              <div className="grant-details mt-5 rounded-full">
                <div className="grant-card">
                  <h4>01</h4>
                  <h3>Solar Capacity</h3>
                  <p>Texas ranks <strong>2nd in the U.S.</strong> for installed solar capacity.
                  </p>
                </div>
                <div className="grant-card">
                  <h4>02</h4>
                  <h3>Growth rate</h3>
                  <p>The Solar market in Texas has grown by over <strong>100% in the past five years.</strong>
                  </p>
                </div>
                <div className="grant-card">
                  <h4>03</h4>
                  <h3>Solar Jobs</h3>
                  <p>Texas is home to over <strong>10,000 solar jobs,</strong> reflecting the inbdustry's significant economic impact.
                  </p>
                </div>
                <div className="grant-card">
                  <h4>04</h4>
                  <h3>Potential</h3>
                  <p>Texas has the potential to power over <strong>10 million homes</strong> with solar energy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className='captial-management bg-[#F6FFFC]'>
        <div className='left'>
          <div style={{ width: '80%' }}>
            <p className='flex gap-1.5 font-medium text-xl'>
              <img src={blackLine} alt='black line' className='w-[2px]' />
              PPA Case Study</p>
            <h1>
              Double-R Capital Management
            </h1>
            <p className='text-xl marginY2'>
              Double-R Capital Management collaborated with E-VoIve Energy to deploy solar systems on three of their buildings, prioritizing excellence, professionalism, and punctuality. Confronted with rising energy expenses tied to traditional utility sources, their buildings grappled with $79,466 in electricity costs annually. In pursuit of a sustainable and cost-effective energy alternative, the company enlisted E-VoIve Energy's expertise to secure control over electricity expenditures for the foreseeable future.
            </p>
          </div>
        </div>
        <div className='right'>
          <img className='image' src={EnergySolutionSquare} alt='' />
        </div>
      </div>

      <section className="grant-info" style={{ padding: 0, background: '#F6FFFC' }}>
        <div className="usda-reap-grants p-3 pb-0 md:p-0" style={{ background: '#F6FFFC' }}>
          <h2 className='font-bold text-2xl md:text-4xl mb-5 ml-1 md:mb-10'>Solar System Details</h2>
          <div className="grant-details" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
            <div className="grant-card">
              <div className='green-view bg-[#3C564B]'>
                <span>833 Greenview</span>
              </div>
              <ul className='list'>
                <li>System Size: 155.7 kW</li>
                <li>Annual Production: 204,741  kWh</li>
                <li>Offset (year 1): 85%</li>
              </ul>
            </div>
            <div className="grant-card">
              <div className='green-view bg-[#437E68]'>
                <span>813 Greenview</span>
              </div>
              <ul className='list'>
                <li>System Size: 103.7 kW</li>
                <li>Annual Production: 189,741  kWh</li>
                <li>Offset (year 1): 85%</li>
              </ul>
            </div>
            <div className="grant-card">
              <div className='green-view bg-[#4BBB90]'>
                <span>817 Greenview</span>
              </div>
              <ul className='list'>
                <li>System Size: 99.07 kW</li>
                <li>Annual Production: 176,741  kWh</li>
                <li>Offset (year 1): 85%</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="eligible-projects">
          <h1 className='font-bold text-3xl'>Results</h1>
          <span className='text-lg'>Year 1 Savings: $57,406 Projected 25-Year Savings:
            $1,288,292</span>
        </div>
        <EducationalCertificates />
      </section>

      <div className="energy-partnerships-container">
        <div className="heading-section">
          {
            HeadingTitle('Energy Construction & Utility Management', null)
          }
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-8 mx-auto md:mx-0 p-4">
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <img src={Group} alt="Group" className="w-12 h-12 mb-4" />
            <span className="text-md md:text-2xl font-medium">Serving over 5,000 commercial customers across 17 states.</span>
          </div>
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <img src={Charge} alt="Charge" className="w-12 h-12 mb-4" />
            <span className="text-md md:text-2xl font-medium">Managing over 250 MW of annual energy load.</span>
          </div>
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <img src={solar} alt="Solar" className="w-12 h-12 mb-4" />
            <span className="text-md md:text-2xl font-medium">Installed over 50 MW of commercial solar energy.</span>
          </div>
          <div className="flex flex-col items-center md:items-start text-center md:text-left">
            <img src={thumbsup} alt="Thumbsup" className="w-12 h-12 mb-4" />
            <span className="text-md md:text-2xl font-medium">Over 100 years of combined experience in energy construction and utility management.</span>
          </div>
          <div className="flex flex-col items-center md:items-start text-center md:text-left md:col-start-2">
            <img src={tickChecked} alt="TickChecked" className="w-12 h-12 mb-4" />
            <span className="text-md md:text-2xl font-medium">Completed marquee projects such as AT&T Stadium, home of the Dallas Cowboys.</span>
          </div>
        </div>
      </div>

      <div>
        <ProjectGrowth />
        <SecuredFunds />
        <FinancialOverview />
      </div>

      <div className='energy-solution-container'>
        <div className='left-section'>
          <div className='img-container'>
            <img className='bImage' src={BusinessModelBg} alt='' />
            <div className='text-container'>
              <div className='box'>
                <h1>Business Model & Incentives</h1>
                <span>We offer and provide a suite of comprehensive energy solutions designed to optimise eficiency, reduce costs, and promote sustainability for commercial and industrial clients.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='right-section'>
          {
            businessModels.map((energy, index) => {
              return (
                <div
                  onClick={() => { setOpenIndex(index) }}
                  key={energy.name} className='name-solution'>
                  <div className='name-icon'>
                    <h1>{energy.name}</h1>
                    {
                      openIndex === index
                        ?
                        <img src={OpenIcon} alt='' />
                        :
                        <img src={CloseIcon} alt='' />
                    }
                  </div>
                  {
                    openIndex === index
                      ?
                      <div>
                        <span>{energy.description}</span>
                        <ul>
                          {
                            energy.solutions.map((solution, index) => {
                              return <li key={index} className='z-10 font-medium py-4'>{solution}</li>;
                            })
                          }
                        </ul>
                      </div>
                      :
                      ''
                  }
                  {
                    index !== businessModels.length - 1
                      ?
                      <hr className='w-[55%] my-4'></hr>
                      : ''
                  }
                </div>
              );
            })
          }
          {
            GradientCode()
          }
        </div>
      </div>
      <section className="grant-info bg-[#F3F3F3] reapGrant">
        <div className="usda-reap-grants p-5 md:p-0 pb-0 ">
          <h2 className='flex gap-1.5 text-3xl md:text-5xl mb-5 md:mb-10 font-bold'>
            <img src={blackLine} alt='black line' className='w-[3px] md:w-[4px]' />
            REAP Grant Projects</h2>
          <div className="grant-details">
            <div className="grant-card">
              <h3>Funding Overview:</h3>
              <ul>
                <li><strong>ROI:</strong> 6-9 months with a 180% return.</li>
                <li><strong>Tax Credits:</strong> Receive 40% of the job cost in transferable tax credits at .80/1 value.</li>
                <li><strong>Initial Investment:</strong> 50% of the project cost down.</li>
              </ul>
            </div>
            <div className="grant-card">
              <h3>Example</h3>
              <ul>
                <li>
                  <strong>Project Overhead:</strong> 1M, with Evolve billing the utility provider $2M.
                </li>
                <li><strong>REAP Grant:</strong> Covers the client’s initial investment in full.</li>
                <li><strong>Tax Credits:</strong> Yield additional benefits, usable over 25 years or sellable to other entities.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="eligible-projects">
          <h1 className='font-bold text-2xl'>Eligible Projects</h1>
          <span className='text-lg'> • Renewable Energy- Solar, Wind, Geothermal, Biomass. </span>
          <span className='text-lg'>  • Energy Efficiency Upgrades -	Lighting, Batteries, HVAC & more.</span>
        </div>
      </section>

      <div>
        <section className="key-opportunities">
          <div className="section-header md:p-5">
            <div className='flex flex-col md:flex-row md:items-center gap-0 md:gap-3'>
              <h2 className='flex gap-1.5 mb-0 text-3xl md:text-6xl'>
                <img src={keyIcon} />
                KEY </h2>
              <h2 className='-mt-3 md:mt-0 uppercase text-3xl md:text-6xl'>Opportunities</h2>
            </div>
            <p>Whether you seek high-growth potential, steady income, or a balanced approach, our carefully curated opportunities offer strategic pathways for investors to achieve success in diverse market conditions. Discover how each model can provide unique advantages tailored to your investment style and objectives.</p>
          </div>
          {/* <div className="investment-cards">
            {investmentModels.map((model, index) => (
              <div key={index} className="investment-card" style={{ backgroundColor: model.backgroundColor }}>
                {
                  index % 2 == 0
                    ?
                    <div className="card-content">
                      <div className="card-text">
                        <div className="card-number">{model.number}</div>
                        <h3>{model.title}</h3>
                        <p>{model.description}</p>
                      </div>
                      <div className="card-image">
                        <img src={model.image} alt={model.title} />
                      </div>
                    </div>
                    :
                    <div className="card-content">
                      <div className="card-image">
                        <img src={model.image} alt={model.title} />
                      </div>
                      <div className="card-text">
                        <div className="card-number">{model.number}</div>
                        <h3>{model.title}</h3>
                        <p>{model.description}</p>
                      </div>
                    </div>
                }
              </div>
            ))}
          </div> */}
          <InvestmentOptions data={investmentModels} />
        </section>
      </div>

      <div className="additional-benefits-container">
        <div className="header-container">
          {/* <div className="header-icon"></div> */}
          <div className='headingTitle text-3xl font-bold md:text-3xl ml-0'>
            <img className='highlightImage' src={HighLight} alt='' />
            <h1 style={{ marginTop: 0 }}>Additional Benefits & Testimonial</h1>
          </div>
        </div>
        <p>
          E-Volve Energy's innovative solutions deliver significant savings and sustainability,
          exemplified by the successful partnership with Double-R Capital Management.
        </p>
        <ul>
          <li>Enhanced operational efficiency</li>
          <li>Improved reputation as a leader in sustainable business practices</li>
          <li>Significant reduction in carbon footprint</li>
        </ul>
        <div className="testimonial-container rounded-3xl">
          <div className="testimonial-quote">
            <span className='pb-3'>
              <img src={quoteIcon} alt='quoteIcon' className='w-8' />
            </span>
            <span className='text-md my-2'>
              E-Volve has not only enhanced the operational efficiency of our buildings but has also
              reinforced our company's reputation as a leader in sustainable business practices. I
              highly recommend E-Volve to any organization seeking innovative energy solutions tailored
              to their specific needs. Their professionalism, expertise, and commitment to excellence
              make them an invaluable partner in driving positive change.
            </span>
          </div>
          <div className="testimonial-Author flex flex-col md:flex-row gap-1 md:items-center md:justify-end text-left">
            <span className='font-bold'>- Bruce Renouard</span>
            <span className='hidden md:block'>,</span>
            <span className='text-sm'>Owner, Double-R Capital Management</span>
          </div>
        </div>
      </div>

      <EnergyEvolutionBanner />
      {/* Contact us */}
      <div className='fixed bottom-4 right-3 text-end'>
        <GetInTouchButton />
      </div>
      <div style={{ width: '100%' }}>
        <Footer />
      </div>

    </div>
  );
};

export default EvolveHome;
