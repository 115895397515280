import React from 'react';
import { ArrowRight } from 'lucide-react';

const FloatingButton = ({ link, title }: { link: string, title?: string }) => {
    const handleClick = () => {
        window.open(link, '_blank', 'noopener,noreferrer');
    };

    return (
        <button
            className="z-20 fixed bottom-8 right-8 flex items-center gap-2 px-6 py-3 
                 text-white rounded-full shadow-xl hover:text-lg hover:bg-gradient-to-r to-blue-500 from-cyan-400 
                 hover:shadow-xl transition-all duration-300
                 font-medium"
            onClick={handleClick}
            style={{
                background: 'linear-gradient(92.96deg, #0076D0 37.17%, #0EFED4 106.2%)'
            }}
        >
            {title || 'Schedule Call'}
            <ArrowRight className="w-4 h-4" />
        </button>
    );
};

export default FloatingButton;