import React from 'react'
import StrengthsComparisonTable from './StrengthsComparisonTable'

function LussoStandOut() {
    return (
        <div>
            <div className='my-10 md:mt-[100px] md:mb-[100px] text-center'>
                <div className="relative mb-5">
                    <p className="text-[#01D8E2] text-sm uppercase tracking-wider mb-2">
                        Competitive Landscape
                    </p>
                    <h1 className="text-4xl md:text-4xl lg:text-4xl font-bold leading-tight uppercase">
                        How Lusso.ai Stands Out
                    </h1>
                    <div className="w-[60%] absolute -bottom-4 left-1/2 transform -translate-x-1/2 h-px bg-gradient-to-r from-transparent via-white to-transparent opacity-30" />

                </div>
                <p className='text-md text-white md:max-w-5xl md:mx-auto'>Lusso.ai operates in a highly competitive space alongside industry giants like Google, Amazon, and Shopify. However, Lusso.ai differentiates itself through fully personalized AI-driven solutions and a unique integrated marketplace approach.</p>
            </div>

            <StrengthsComparisonTable />
        </div>
    )
}

export default LussoStandOut