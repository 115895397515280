import FaceBookIcon from "../assets/images/icons/FaceBookIcon.svg";
import XIcon from "../assets/images/icons/XIcon.svg";
import InstagramIcon from "../assets/images/icons/InstagramIcon.svg";
import LinkedInIcon from "../assets/images/icons/LinkedInIcon.svg";
import FooterAnimation from "./FooterAnimation";
import Logo from "../assets/images/icons/logo.svg";
import "./Footer.scss";
const Footer = () => {
  return (
    <footer>
      <div
        className="footer-gradient-content"
        style={{ height: "100px" }}
      ></div>
      <div className="footer-content" style={{ marginTop: "80px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="w-28">
              <img src={Logo} className="logo " />
            </div>
            {/* <div style={{ height: "35px" }}></div>  */}
            <div className="elevate-your">
              <h1 className="font-test-founders font-semibold text-6xl">
                Elevate
              </h1>
              <div
                className="font-test-founders font-semibold text-3xl md:text-6xl mb-4"
                style={{ display: "flex", flexDirection: "row", gap: "14px" }}
              >
                <h1>Your</h1>
                <FooterAnimation />
              </div>
            </div>
          </div>
          <div
            className="footer-rights"
          >
            All Rights Reserved Gaskon
          </div>
        </div>
        <div className="footer-menu">
          <div style={{ display: "flex", flexDirection: "column", justifyContent: 'space-between' }}>
            <div style={{ display: "flex", flexDirection: "row", gap: '50px' }}>
              <div className="menu">
               
                <ul>
                  <li>Home</li>
                  <li>Portfolio</li>
                  <li>About Us</li>
                  <li>Contact</li>
                </ul>
              </div>
              <div className="license">
                
                <ul>
                  <li>Privacy Policy</li>
                  <li>Copyright</li>
                </ul>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "space-around",
                gap: "35px",
              }}
            >
              <div className="icon-container"><img src={FaceBookIcon} style={{ width: '15px', height: '15px' }}/></div>
              <div className="icon-container"><img src={XIcon} style={{ width: '15px', height: '15px' }}/></div>
              <div className="icon-container"><img src={LinkedInIcon} style={{ width: '15px', height: '15px' }}/></div>
              <div className="icon-container"><img src={InstagramIcon} style={{ width: '15px', height: '15px' }}/></div>
            </div>
          </div>
          <div className="contact-info">
            <ul>
            <h4>Office</h4>
            <li>945-279-2074</li>
              <h4>Mobile</h4>
              <li>817-880-1304</li>
              <h4 className="mt-5">Email</h4>
              <li>info@gaskon.io</li>
            </ul>
            <ul>
              <h4 className="mt-10">Address: </h4>
              <li className="text-wrap">
                1203 S White Chapel Blvd #100, Southlake, Texas, 76092
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
