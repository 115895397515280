import React from 'react'
import startQuote from '../../../../assets/images/lusso/leftQuote.svg'
import endQuote from '../../../../assets/images/lusso/rightQuote.svg'
import bottomGradient from '../../../../assets/images/lusso/borderBottomGradient.svg'
import bg from '../../../../assets/images/lusso/newsbg.svg'

const testimonials = [
  {
    quote:
      'Lusso.ai transformed our marketing efforts. We saw a 200% increase in conversions in just two weeks!',
    author: 'Sarah L., CEO, Bright Horizons Apparel',
  },
  {
    quote:
      "Our SEO rankings soared within the first month of using Lusso.ai's optimization tools. We've never seen such rapid growth.",
    author: 'Michael P., Founder, Greenwave Electronics',
  },
]

export default function CustomerTestimonials() {
  return (
    <div className='bg-gradient-to-b from-[#010101] to-[#060409] text-white'>
      <div className='bg-cover bg-center bg-no-repeat w-full min-h-screen p-8 md:py-[100px]' style={{ backgroundImage: `url(${bg})`, backgroundPosition: 'top' }}>
        <div className="md:py-[100px] text-center">
          <div className="relative mb-5">
            <p className="text-[#01D8E2] text-sm uppercase tracking-wider mb-2">
              Case Study
            </p>
            <h1 className="text-4xl md:text-4xl lg:text-4xl font-bold leading-tight uppercase">
              $100K Revenue in 24 Hours
            </h1>
            <div className="w-[60%] absolute -bottom-4 left-1/2 transform -translate-x-1/2 h-px bg-gradient-to-r from-transparent via-white to-transparent opacity-30" />
          </div>
          <p className="text-md text-white md:max-w-5xl md:mx-auto">
            Client: E-Commerce Brand Using Lusso.ai’s advanced marketing
            automation and personalized outreach tools, a mid-sized e-commerce
            business generated $100K in revenue within 24 hours of launching a
            targeted campaign. This effort drove over 500 new subscribers with a
            total spend of just $19K—a clear example of ROI in action.
          </p>
        </div>

        <div className="text-white mb-[100px]">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-2xl font-bold mb-4 relative flex flex-col items-center">
              What Our Clients Say
              <img
                src={bottomGradient}
                alt="border"
                className="h-[20px] w-[200px]"
              />
            </h2>
            {/* <p className="text-lg leading-relaxed mt-6 opacity-80">
              Client: E-Commerce Brand Using Lusso.ai’s advanced marketing
              automation and personalized outreach tools, a mid-sized e-commerce
              business generated $100K in revenue within 24 hours of launching a
              targeted campaign. This effort drove over 500 new subscribers with
              a total spend of just $19K—a clear example of ROI in action.
            </p> */}
          </div>
        </div>

        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="flex flex-col relative md:max-w-lg">
              <p className="text-xl mb-4 flex-grow flex gap-3">
                <span>
                  <img
                    src={startQuote}
                    alt="start"
                    className="h-10 w-10 -mt-2"
                  />{' '}
                </span>
                <p className="text-2xl font-semibold">
                  {testimonial.quote}
                </p>
                <span className="self-end">
                  <img src={endQuote} alt="start" className="h-10 w-10" />{' '}
                </span>
              </p>
              <span className="text-md text-gray-300 ms-5">
                {testimonial.author}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
