import laptopImg from '../../../../assets/images/gaskon/laptop.svg'

const WhiteLabelShowcase = () => {
    return (
        <div className="w-full bg-[#EFF6FC]">
            <div className="md:w-[80%] mx-auto flex flex-col lg:flex-row items-center justify-between">

                {/* Text Section */}
                <div className="lg:w-1/2 px-6 lg:px-12 flex flex-col gap-5">
                    <span className="text-5xl min-h-[50px] font-bold bg-gradient-to-r from-[#0076D0] via-cyan-400 to-[#0EFED4]  bg-clip-text text-transparent">
                        White Label Offering
                    </span>
                    <p className="text-md text-gray-600">
                        Gaskon’s platform is available as a white-label solution, allowing businesses
                        to rebrand and offer the platform under their own name with ease.
                    </p>
                    <p className="text-md text-gray-600">
                        Customizability–Tailor the platform to fit your unique business needs—no
                        compromise on performance or security, ensuring seamless functionality.
                    </p>
                </div>

                {/* Laptop Display */}
                <div className="lg:w-1/2 mt-8 lg:mt-0 relative flex justify-center">
                    <div className="w-full max-w-4xl">
                        <img
                            src={laptopImg}
                            alt="White label dashboard interface"
                            className="w-full h-auto object-cover scale-125"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhiteLabelShowcase;
