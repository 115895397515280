// @ts-nocheck
import React from 'react';
import s1 from '../../../../assets/images/lusso/success1.gif'
import s2 from '../../../../assets/images/lusso/success2.gif'
import s3 from '../../../../assets/images/lusso/success3.gif'

const SuccessStory = ({ icon, title, description }: { icon: any, title: string, description: string }) => (
    <div className="flex flex-col items-start text-left">
        {icon}
        <h3 className="text-2xl font-bold mt-4 mb-2">{title}</h3>
        <p className="text-sm text-gray-400 md:max-w-[400px]">{description}</p>
    </div>
);

const SuccessStories = () => {
    const stories = [
        {
            icon: <img src={s1} alt="Person icon" className="w-12 h-12" />,
            title: "Youngest CEO",
            description: "Successfully took a company public in collaboration with Boustead Securities."
        },
        {
            icon: <img src={s2} alt="OpenAI logo" className="h-12 w-auto" />,
            title: "OpenAI Consultant",
            description: "Consulted on the original OpenAI team, showcasing expertise in AI development."
        },
        {
            icon: <img src={s3} alt="Rocket icon" className="w-12 h-12" />,
            title: "Token Launch Success",
            description: "Multiple multi-million-dollar token launches, including $1B market caps for projects like Hodge Finance and Neiro."
        }
    ];

    return (
        <div className="bg-[#020202] text-white p-8 md:p-0 md:py-[200px]">
            <div className='md:w-[80%] md:mx-auto'>
                <p className="relative text-4xl mb-8 font-bold uppercase w-fit pb-2 after:absolute after:content-[''] after:h-[1px] 
                    after:bg-gradient-to-r after:from-white after:via-white after:to-[#020202] after:bottom-0 after:left-0 after:right-0 after:w-full">
                    Success Stories
                </p>
                {/* <h2 className="text-4xl font-bold mb-8 border-b border-gray-700 pb-2 ">SUCCESS STORIES</h2> */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 pt-[5rem]">
                    {stories.map((story, index) => (
                        <SuccessStory key={index} {...story} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SuccessStories;