import React, { useEffect, useRef, useState } from 'react'
import contactBg from '../../assets/images/evolveEnergy/contactBg.svg'
import sendIcon from '../../assets/images/evolveEnergy/sendIcon.svg'
import { Calendar } from 'lucide-react'
import RightArrow from '../../assets/images/icons/rightArrowBgBlack.png';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

export function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email);
}

const GetInTouchButton = () => {
  const token = Cookies.get('authToken');

  const [isOpen, setIsOpen] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }



  const handleSubmit = async (e: any) => {
    e.preventDefault()
    try {
      const { name, email, phone, message } = formData;

      // Validate the form data
      if (!name || !email || !phone || !message) {
        toast.error('Please fill in all the required fields.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }

      if (!isValidEmail(email)) {
        toast.error('Please enter a valid email address.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        return;
      }

      const payload = {
        name,
        email,
        phone,
        message,
        meetingLink: '',
      };
      let headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(`
      ${process.env.REACT_APP_BASE_URL}enquiry/${process.env.REACT_APP_CLIENT_EVOLVE_ID}`,
        payload,
        { headers: headers });

      if (response.status === 200) {
        // Reset the form data
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
        setIsOpen(false);

        // Show a success message using Toastify
        toast.success('Form submitted successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        // Handle the error case
        toast.error('Error submitting the form.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      toast.error('An error occurred while submitting the form.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };


  const popupRef = useRef<any>(null); // Ref for the popup container

  const handleClickOutside = (event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false); // Close the popup if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener when the popup is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up the event listener when the popup is closed
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);


  // render
  return (
    <div className="relative z-[999]">
      <button
        onClick={() => setIsOpen(true)}
        className="flex items-center bg-white text-black font-bold py-3 px-6 rounded-full shadow-lg hover:bg-gray-100 transition duration-300"
      >
        <span className="mr-2">Get in touch</span>
        <img src={RightArrow} alt='' />
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4"
          style={{ zIndex: 10000 }}>
          <div ref={popupRef} className="bg-white rounded-3xl shadow-xl max-w-[95%] md:max-w-7xl w-full md:max-h-[90%] overflow-hidden p-3">
            <div className="flex flex-col lg:flex-row md:gap-3">
              <div className="lg:w-[40%] p-3 px-4 md:p-6 bg-[#E6F1FA] rounded-xl overflow-y-auto">
                <div className="flex justify-between items-center mb-2">
                  <h2 className="text-xl font-bold">GET IN TOUCH WITH US</h2>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="md:hidden text-white bg-[#686868] rounded-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <p className="text-xs text-[#676767] mb-3 text-start">
                  Fill out the form below or Schedule a meeting with us
                </p>


                <form onSubmit={handleSubmit} className="text-start contus">
                  <div className="mb-4 bg-white rounded-2xl w-full md:max-w-md mx-auto pt-5"
                    style={{ border: '1px solid #E8E8E8' }}>
                    <div className="mb-4 pb-5 px-4 md:px-8  border-b-[1px] border-b-slate-400">
                      <label
                        htmlFor="name"
                        className="block text-gray-700 font-bold mb-2"
                      >
                        Name*
                      </label>
                      <input
                        required
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter Name"
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4 pb-5 px-4 md:px-8  border-b-[1px] border-b-slate-400">
                      <label
                        htmlFor="email"
                        className="block text-gray-700 font-bold mb-2"
                      >
                        Email ID*
                      </label>
                      <input
                        required
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter Email"
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4 pb-5 px-4 md:px-8  border-b-[1px] border-b-slate-400">
                      <label
                        htmlFor="phone"
                        className="block text-gray-700 font-bold mb-2"
                      >
                        Phone Number*
                      </label>
                      <PhoneInput
                        country={"us"}
                        value={formData.phone} // The phone number state
                        onChange={(value: any) => {
                          setFormData({ ...formData, phone: value });
                        }}  // Handler to set the phone number
                        enableSearch={true} // Allows country search
                        placeholder="Enter phone number"
                        buttonStyle={{
                          border: "none"
                        }}
                        inputStyle={{ border: 'none' }}
                        inputClass="appearance-none border-0 rounded w-full py-2 pe-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" // Your custom input styles
                      />
                    </div>
                    <div className="mb-4 px-4 md:px-8">
                      <label
                        htmlFor="message"
                        className="block text-gray-700 font-bold mb-2"
                      >
                        Message*
                      </label>
                      <textarea
                        required
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows={2}
                        placeholder="Leave us your message here"
                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      required
                      type="checkbox"
                      id="privacy-policy"
                      name="privacy-policy"
                      className="form-checkbox h-5 w-5 text-blue-500 cursor-pointer"
                    />
                    <label
                      htmlFor="privacy-policy"
                      className="ml-2 text-[#686868] font-normal cursor-pointer"
                    >
                      I agree to Privacy Policy and terms. We promise to
                      keep your information safe and sound.
                    </label>
                  </div>
                  <button
                    type="submit"
                    style={{ border: '1px solid #808B8B33' }}
                    className="mb-4 w-full bg-white border-2 border-blue-300 text-md md:text-lg gap-2 flex items-center justify-center text-[#0177D1] font-bold p-3 rounded-xl focus:outline-none focus:shadow-outline"
                  >
                    <img src={sendIcon} alt='send' />
                    Send
                  </button>

                  <div className="flex items-center my-6">
                    <div className="flex-grow border-t border-gray-400"></div>
                    <span className="flex-shrink mx-4 text-[#676767] font-bold">or</span>
                    <div className="flex-grow border-t border-gray-400"></div>
                  </div>
                  <button
                    type="button"
                    onClick={() => window.open('https://calendly.com/lussolabs/evolve-energy-investment-enquiry', '_blank')}
                    className="bg-[#1E1E1E] flex gap-2 items-center text-md md:text-lg justify-center text-white font-bold p-3 rounded-xl focus:outline-none focus:shadow-outline mt-4 w-full"
                  >
                    <Calendar />
                    Schedule Meeting
                  </button>
                </form>
              </div>
              <div className="hidden lg:block lg:w-[55%] rounded-xl">
                <img
                  src={contactBg}
                  alt="Solar Panels"
                  className="w-full h-full object-cover rounded-xl"
                />
              </div>
              <div className='hidden md:block h-auto'>
                <button
                  onClick={() => setIsOpen(false)}
                  className="text-white bg-[#686868] rounded-full h-auto mt-3 p-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-[24px]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default GetInTouchButton
