import React from 'react'
import bg from '../../../../assets/images/lusso/currentOpportunitiesBg.svg'
import InvestmentOpportunityTable from './InvestmentOpportunityTable'
import StrategicFocusAreas from './StrategicFocusAreas'
import bottomGradient from '../../../../assets/images/lusso/borderBottomGradient.svg'
import SuccessStories from './SuccessStories'

function CurrentOpportunities() {
    return (
        <>
            <div className='bg-[#040407] md:pb-[100px] p-5'>
                {/* Path to ROI */}
                <div className="text-white md:w-[80%] mx-auto ">
                    <div className="max-w-3xl mx-auto text-center">
                        <h2 className="text-2xl font-bold mb-4 relative flex flex-col items-center">
                            Clear Path to ROI
                            <img src={bottomGradient} alt='border' className='h-[20px] w-[200px]' />
                        </h2>
                        <p className="text-lg leading-relaxed mt-6 opacity-80">
                            Investors can expect a structured approach to return on investment through Lusso.ai's focused use of funds.
                            By prioritizing product excellence and customer acquisition, Lusso.ai positions itself for high-growth
                            scalability, ensuring significant returns for early investors.
                        </p>
                    </div>
                </div>
            </div>

            <div className='text-white bg-cover bg-center bg-no-repeat w-full' style={{
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top',
                backgroundRepeat: 'no-repeat',
                width: '100%',
            }}>
                <div className='md:w-[80%] mx-auto md:min-h-[180vh] md:py-[200px] p-5'>
                    {/* Title */}
                    <div className='md:py-[100px] text-center'>
                        <div className="relative mb-5">
                            <p className="text-[#01D8E2] text-sm uppercase tracking-wider mb-2">
                                Open Investment Positions
                            </p>
                            <h1 className="text-4xl md:text-4xl lg:text-4xl font-bold leading-tight uppercase">
                                Explore Current Opportunities
                            </h1>
                            <div className="w-[60%] absolute -bottom-4 left-1/2 transform -translate-x-1/2 h-px bg-gradient-to-r from-transparent via-white to-transparent opacity-30" />

                        </div>
                        <p className='text-md text-white md:max-w-5xl md:mx-auto'>Lusso.ai presents unique investment opportunities for those looking to partner with a rapidly growing, AI-driven platform. Below are the current positions available, highlighting the amount raised, the valuation, and the terms of each open opportunity.</p>
                    </div>


                    {/* Table */}
                    <InvestmentOpportunityTable />

                    {/* Strategic Focus Areas */}
                    <StrategicFocusAreas />

                </div>
            </div>

        </>

    )
}

export default CurrentOpportunities