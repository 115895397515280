import React, { useEffect, useState } from 'react';
import '../client/dashboardlayout/DashboardLayout.scss';
import Header from './Header';

import Graph from '../../assets/images/icons/graph.svg';
import TeamIcon from '../../assets/images/icons/TeamIcon.svg';
import TasksIcon from '../../assets/images/icons/TasksIcon.svg';
import DocumentIcon from '../../assets/images/icons/DocumentIcon.svg';
import QuickStats from '../../assets/images/icons/quick-stats.svg';
import ActivePortfolios from '../../assets/images/icons/active-portfolio.svg';
import DollarIcon from '../../assets/images/icons/dollar.svg';
import SettingsIcon from '../../assets/images/icons/SettingsIcon.svg';
import SupportIcon from '../../assets/images/icons/SupportIcon.svg';
import ListIcon from '../../assets/images/admin/list.svg'
import Cookies from 'js-cookie';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import Sidebar from '../../components/common/Sidebar';

const menuList = [
  { title: 'Overview', src: Graph, path: '' },
  { title: 'Investor Requests', src: ActivePortfolios, path: '/investor-request' },
  { title: 'Business Listings', src: ListIcon, path: '/business-listings' },
  { title: 'Portfolio Management', src: DocumentIcon, path: '/portfolio' },
  { title: 'Reports & Analytics', src: QuickStats, path: '/document' },
  { title: 'User Management', src: TeamIcon, path: '' },
  { title: 'Settings', src: SettingsIcon },
  { title: 'Support', src: SupportIcon },
];

const AdminLayout: React.FC = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // To prevent flashing
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    const authToken = Cookies.get('authToken') || null;

    if (!authToken || authToken === '') {
      navigate("/admin");
    } else {
      try {
        const payload = jwtDecode(authToken) as { exp: number };

        if (payload.exp * 1000 < Date.now()) {
          // Token expired
          navigate("/admin");
        } else {
          // Token is valid, allow the user to access the route
          setIsLoading(false); // Only set loading to false if authenticated
        }
      } catch (error) {
        // Invalid token, navigate to login
        navigate("/admin");
      }
    }
  }, [navigate]);

  // Don't render the layout until authentication check is done
  // if (isLoading) {
  //   return null; // You can return a loader here if you want
  // }

  // Check if the current route is '/admin' (login page)
  const isAdminRoute = location.pathname === '/admin';

  if (isAdminRoute) {
    return <Outlet />; // Render the login route
  }

  // Render the full admin layout for other routes
  return (
    <div className={`dashboard-layout ${isSidebarOpen ? 'side-open' : 'side-close'}`}>
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        menuList={menuList}
        admin={false}
        setSelectedMenuIndex={setSelectedMenuIndex}
        toggleSidebar={toggleSidebar}
        setIsSidebarOpen={setIsSidebarOpen}
        isAdmin={true}
      />
      <div className="client-dashboard-main-content bg-white">
        <Header />
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
