import React from 'react'
import './edge.scss'
import map from './Image/map.png'
import bag from './Image/bag.png'
import shop from './Image/shop.png'
import trans from './Image/trans.png'
import park from './Image/park.png'
import ic1 from '../../../../assets/images/century/upcoming1a.svg'
import ic2 from '../../../../assets/images/century/upcoming1b.svg'
import ic3 from '../../../../assets/images/century/upcoming1c.svg'
import cp1 from './Image/cpcard1.png'
import cp2 from './Image/cpcard2.png'
import cp3 from '../../../../assets/images/century/cp3Bg.svg'
import cp4 from '../../../../assets/images/century/cp4Bg.svg'

function FuturePlansGoals() {

    const RenderItem = ({ img, imgDesc, hText, hTitle, hDesc, desc1, desc2, desc3 }: any) => {
        return (
            <div className='ecard'>
                <div className='cpimg relative'>
                    <img src={img} className='md:w-full md:h-auto' />
                    <p className='pos py-1 px-3 rounded-xl mb-3 ml-2 bg-opacity-20 absolute right-2'>{imgDesc}</p>
                </div>

                <div className='cdesc flex flex-col gap-2'>
                    <p className='phighlights'>{hText}</p>
                    <div className='subetit flex flex-col gap-2'>
                        <h4 className='text-[#0C070F] text-lg'>{hTitle}</h4>
                        <p className='text-md'>{hDesc}</p>
                    </div>
                    <div className='citems'>
                        <img src={ic1} />
                        <p>{desc1}</p>
                    </div>
                    <div className='citems'>
                        <img src={ic2} />
                        <p>{desc2}</p>
                    </div>
                    <div className='citems'>
                        <img src={ic3} />
                        <p>{desc3}</p>
                    </div>
                </div>
            </div>
        )
    }

    // render
    return (
        <div className='relative'>
            <div className="hidden md:block absolute bottom-0 left-0 right-0 h-px bg-gray-200"></div>

            <div className="md:max-w-[90%] mx-auto p-5 md:p-0 pb-10">
                <div className="relative md:py-[50px]">
                    <div className="hidden md:block absolute top-0 bottom-0 left-10 w-px bg-gray-200"></div>
                    <div className=' md:w-[90%] mx-auto'>
                        <div className='flex flex-col gap-5'>
                            <div className='md:ms-[150px] flex flex-col gap-5 md:w-[50%] mb-10 md:mb-[50px]'>
                                <div className='etits'>
                                    <h2 className='text-3xl md:text-4xl font-medium'>Future Plans and Goals</h2>
                                </div>
                                <div className='subetit flex flex-col gap-2 '>
                                    <h4 className='text-[#414141] text-2xl font-medium'>Strategic Vision</h4>
                                    <p className='text-md'>Century 21 Realty is committed to expanding into emerging markets, leveraging advanced technology for smarter real estate solutions. Our focus on innovation and sustainability drives our growth and enhances our offerings, ensuring we meet the evolving needs of our clients and investors.</p>
                                </div>
                            </div>
                            <div className='md:ms-[150px] flex flex-col gap-5 md:w-[50%] mb-10 md:mb-[30px]'>
                                <div className='subetit flex flex-col gap-2 '>
                                    <h4 className='text-[#414141] text-2xl font-medium'>Upcoming Projects</h4>
                                    <p className='text-md'>Expand into emerging markets, leveraging technology for smarter real estate solutions</p>
                                </div>
                            </div>
                            <div className='flex flex-wrap gap-10 md:gap-[100px] md:mx-auto md:ms-[150px] '>
                                <RenderItem
                                    img={cp1}
                                    imgDesc={'Construction began in early 2023'}
                                    hText={'Expected Completion: 2025'}
                                    hTitle={'The Greenfield Project'}
                                    hDesc={'A pioneering eco-friendly residential community designed to set new standards in sustainable living.'}
                                    desc1={'Solar-powered homes, green roofs, rainwater harvesting systems, and extensive green spaces.'}
                                    desc2={'Strategically located near key transportation routes and local amenities.'}
                                    desc3={'Aimed at reducing carbon footprints and promoting sustainable lifestyles among residents.'}
                                />

                                <RenderItem
                                    img={cp2}
                                    imgDesc={'Construction began in early 2023'}
                                    hText={'Expected Completion: 2026'}
                                    hTitle={'TechPark Development'}
                                    hDesc={'A state-of-the-art commercial tech hub tailored for cutting-edge businesses and startups.'}
                                    desc1={'Smart building technology, high-speed internet, co-working spaces, and eco-friendly design elements.'}
                                    desc2={`Situated in the heart of the city’s tech corridor, adjacent to major transport links and urban conveniences.`}
                                    desc3={'Expected to attract leading tech companies and startups, boosting local economy and innovation.'}
                                />

                                <RenderItem
                                    img={cp3}
                                    imgDesc={'Construction began in early 2023'}
                                    hText={'Expected Completion: 2025'}
                                    hTitle={'Harborview Residences'}
                                    hDesc={'A luxury waterfront residential complex offering stunning views and premium amenities.'}
                                    desc1={'Smart home technology, private balconies, fitness center, swimming pool, and concierge services.'}
                                    desc2={`Located along the city's picturesque waterfront, close to shopping, dining, and cultural attractions.`}
                                    desc3={'Aimed at attracting high-net-worth individuals seeking exclusive, high-quality living spaces.'}
                                />

                                <RenderItem
                                    img={cp4}
                                    imgDesc={'Construction began in early 2023'}
                                    hText={'Expected Completion: 2026'}
                                    hTitle={'Sunset Plaza'}
                                    hDesc={'A vibrant mixed-use development combining residential, commercial, and recreational spaces.'}
                                    desc1={'Modern apartments, retail shops, restaurants, office spaces, and green public areas.'}
                                    desc2={'Centrally located in an up-and-coming neighborhood, with excellent connectivity to major transport routes.'}
                                    desc3={'Designed to foster community engagement and stimulate local economic growth by providing a dynamic, integrated living and working environment.'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FuturePlansGoals