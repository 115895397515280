import React, { useState } from 'react';
import './Pricing.scss';
import { Link } from 'react-router-dom';
import RightArrow from '../../../assets/images/icons/arrow-right-cropped.svg';
import Logo from '../../../assets/images/icons/logo.svg';
import Tick from '../../../assets/images/icons/blackTick.png';


const PricingComponent: React.FC = () => {
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'quarterly' | 'annual'>('monthly');

  const handleBillingChange = (cycle: 'monthly' | 'quarterly' | 'annual') => {
    setBillingCycle(cycle);
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', marginTop: '40px' }}>
                <Link to="/login" style={{ position: 'absolute', left: 0 }}>
                    <div className='back-button'>
                        <div style={{ "display": 'flex', 'flexDirection': 'column', justifyContent: "center" }}><img src={RightArrow} alt="Logo" className='right-arrow' /></div>
                        <div style={{ "display": 'flex', 'flexDirection': 'column', justifyContent: "center", marginLeft: "10px", color: 'black' }}>Back</div>
                    </div>
                    </Link>
                <div style={{ margin: '0 auto' }}>
                    <img src={Logo} alt="Logo" className='gaskon-logo' />
                </div>

            </div>
      <div className="pricing-container">
        <h1>Select the plan that best fits your needs.</h1>
        <p>Select a plan that fits your needs, starting at just $20/month — cancel anytime.</p>

        {/* Billing Toggle */}
        <div className="billing-toggle">
          <div className="tabs">
            <button
              onClick={() => handleBillingChange('monthly')}
              className={billingCycle === 'monthly' ? 'active' : ''}
            >
              Monthly
            </button>
            <button
              onClick={() => handleBillingChange('quarterly')}
              className={billingCycle === 'quarterly' ? 'active' : ''}
            >
              Quarterly
            </button>
            <button
              onClick={() => handleBillingChange('annual')}
              className={billingCycle === 'annual' ? 'active' : ''}
            >
              Annual
            </button>
            <span className={`slider ${billingCycle}`}></span>
          </div>
        </div>

        {/* Pricing Cards */}
        <div className="pricing-cards">
          {/* Basic Plan */}
          <div className="pricing-card">
            <h2>Basic Plan</h2>
            <p>Affordable option for getting started</p>
            <div className="price">$5,000 <div style={{fontSize:'1rem'}}>/Month</div></div>
            <button>Get Started</button>
            <ul>
              <li style={{display:'flex', alignItems:'center', gap:8}}>
                <img src={Tick} alt=''/>
                Expert dedicated consulting services</li>
              <li style={{display:'flex', alignItems:'center', gap:8}}>
                <img src={Tick} alt=''/>
                Project Management</li>
              <li style={{display:'flex', alignItems:'center', gap:8}}>
                <img src={Tick} alt=''/>
                Analytics</li>
              <li style={{display:'flex', alignItems:'center', gap:8}}>
                <img src={Tick} alt=''/>
                AI</li>
            </ul>
          </div>

          {/* Premium Plan */}
          <div className="pricing-card">
            <h2>Premium Plan</h2>
            <p>Balanced option for growing needs</p>
            <div className="price">$10,000 <div style={{fontSize:'1rem'}}>/Month</div></div>
            <button>Get Started</button>
            <ul>
              <li style={{display:'flex', alignItems:'center', gap:8}}>
                <img src={Tick} alt=''/>All Basic plan features</li>
              <li style={{display:'flex', alignItems:'center', gap:8}}>
                <img src={Tick} alt=''/>Portfolio Creation</li>
              <li style={{display:'flex', alignItems:'center', gap:8}}>
                <img src={Tick} alt=''/>2500+ Accredited Investors</li>
              <li style={{display:'flex', alignItems:'center', gap:8}}>
                <img src={Tick} alt=''/>Portfolio data tracking</li>
            </ul>
          </div>

          {/* Enterprise Plan */}
          <div className="pricing-card">
            <h2>Enterprise Plan</h2>
            <p>Premium option with custom pricing</p>
            <div className="price">$25,000 <div style={{fontSize:'1rem'}}>/Month</div></div>
            <button>Get Started</button>
            <ul>
              <li style={{display:'flex', alignItems:'center', gap:8}}>
                <img src={Tick} alt=''/>All Premium Plan Features</li>
              <li style={{display:'flex', alignItems:'center', gap:8}}>
                <img src={Tick} alt=''/>Dedicated Gaskon Team Liaison</li>
              <li style={{display:'flex', alignItems:'center', gap:8}}>
                <img src={Tick} alt=''/>High-Caliber Financial Institutions & Relationships</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
