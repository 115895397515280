import React from 'react'
import NewTopHeader from '../../../../components/NewHeader'
import lineIcon from '../../../../assets/images/evolveEnergy/line.svg'
import logo from '../../../../assets/images/lusso/logo.svg';
import Bg1 from '../../../../assets/images/lusso/Bg.svg';

function LussoBanner() {
    return (
        <div
            className="relative w-full bg-no-repeat bg-cover bg-center bg-gradient-to-t "
            style={{
                backgroundImage: `url(${Bg1})`,
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                height: '120vh'
            }}
        >
            {/* Header */}
            <div className='flex justify-center'>
                <div className='absolute top-[10px] w-[80%] bg-[#FFFFFF30]' style={{
                    backdropFilter: 'blur(30px)', zIndex: '1000', borderRadius: 30
                }}>
                    <NewTopHeader />
                </div>
            </div>
            {/* Content - Right-aligned */}
            <div className="flex-grow flex justify-end h-full items-end md:items-center">
                <div className="relative z-10 w-full md:w-[40%] p-8 mr-8">
                    <img className="w-50 mb-6" src={logo} alt="Lusso.AI Logo" />
                    <h1 className="text-8xl font-bold text-white mb-4">AI-DRIVEN<br className='mb-2' />DIGITAL <br className='mb-2'/>COMMERCE</h1>
                    <p className="text-xl text-white mb-4">Unlocking the Future of AI-Driven Digital Commerce</p>
                    <button className="uppercase mt-8 bg-[#7D3CF3] text-white px-6 py-3 rounded-full hover:bg-purple-700 transition duration-300"
                        style={{ border: '0.61px solid #9F70F3' }}>
                        Explore Opportunities
                    </button>
                </div>
            </div>
        </div>
        // <div className="h-[10px] bg-gradient-to-b from-transparent to-black"></div>

    )
}

export default LussoBanner