type ApiEndPointsType = {
  requestAccess: {
      url: string;
      method: string;
    };
    verifyPhone: {
      url: string;
      method: string;
    };
    verifyEmail: {
      url: string;
      method: string;
    };
  };

export const apiEndPoints: ApiEndPointsType = {
  requestAccess: {
    url: "portfolioAccess/requestAccess",
    method: "POST",
  },

  verifyPhone: {
    url: "verifyOTP",
    method: "POST",
  },
  verifyEmail: {
    url: "portfolioAccess/verifyEmail",
    method: "POST",
  },
};
