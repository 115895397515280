// CircularProgressWithLabel.tsx
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

interface CircularProgressWithLabelProps {
  value: number;
}

// Custom styled CircularProgress for gradient
const GradientCircularProgress = styled(CircularProgress)({
  svg: {
    circle: {
      stroke: `url(#customGradient)`,
    },
  },
});

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({ value }) => {
  return (
    <Box position="relative" display="inline-flex">
      <svg width="0" height="0">
        <defs>
          <linearGradient id="customGradient" x1="1" y1="0" x2="0" y2="1">
            <stop offset="37.17%" style={{ stopColor: '#0076D0', stopOpacity: 1 }} />
            <stop offset="106.2%" style={{ stopColor: '#0EFED4', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
      </svg>
      <GradientCircularProgress variant="determinate" value={value} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="black" // Set the label color to black
          fontWeight="bold" // Make the label bold
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
