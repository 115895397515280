import React from 'react';

const StyledTitle = ({ title }: { title: string }) => (
    <div className="relative flex flex-col items-center my-12">
        <div className="hidden md:block w-px h-24 bg-gray-300"></div>

        <div className="flex items-center w-full justify-center mb-4">
            <div className="hidden md:block flex-grow h-px bg-gray-300 mx-4"></div>
            <h2 className="text-3xl font-medium px-4 text-[#414141]">{title}</h2>
            <div className="hidden md:block flex-grow h-px bg-gray-300 mx-4"></div>
        </div>
        <div className="hidden md:block w-px h-24 bg-gray-300"></div>
    </div>
);

const SubSection = ({ leftHighlight, leftDesc, title, rightHighlight, rightDesc }: any) => (
    <div className="flex flex-col md:flex-row items-center justify-between mb-8">
        <div className="w-full md:w-1/3 mb-4 md:mb-0 flex flex-col gap-5">
            <p className="text-sm font-semibold bg-[#F3EBCF] text-black w-fit p-2">{leftHighlight}</p>
            <h4 className="text-4xl font-medium text-[#414141]">{leftDesc}</h4>
        </div>
        <div className="w-full md:w-1/3 text-center mb-4 md:mb-0">
            <StyledTitle title={title} />
        </div>
        <div className="w-full md:w-1/3 text-left md:items-end flex flex-col gap-5">
            <span className="text-sm font-semibold bg-[#F3EBCF] text-black w-fit px-2 md:text-end">{rightHighlight}</span>
            <h4 className="text-4xl font-medium text-[#414141] md:text-end">{rightDesc}</h4>
        </div>
    </div>
);

const NearbyHotspots = () => {
    return (
        <div className="md:max-w-[80%] mx-auto px-4 py-8 bg-transparent">
            <div className="text-center mb-12">
                <h1 className="text-5xl font-semibold text-[#414141]">Nearby Hotspots</h1>
            </div>

            <SubSection
                leftHighlight="The Heights"
                leftDesc="Near top-rated schools, central park, shopping mall, and general hospital."
                title="Local Amenities"
                rightHighlight="Midtown Plaza"
                rightDesc="Proximity to public transit, restaurants, and business district."
            />

            <SubSection
                leftHighlight="The Heights"
                leftDesc="5% job market growth, 2% population increase"
                title="Economic Indicators"
                rightHighlight="The Heights"
                rightDesc="$1 billion in new commercial investments"
            />

            <SubSection
                leftHighlight="The Heights"
                leftDesc="Upcoming subway extension near The Heights"
                title="Future Development"
                rightHighlight="The Heights"
                rightDesc="New tech hub development adjacent to Midtown Plaza"
            />
        </div>
    );
};

export default NearbyHotspots;