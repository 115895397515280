import React from 'react';
import InvestmentCard from './InvestmentCard';

const InvestmentOptions = ({ data }: any) => {

    const cardStyles = [
        "#4BBB90",
        "#2a2a2a",
        "#3C564B",
        "#4BBB90",
        "#2a2a2a",
        "#3E574C"
    ];

    const cardLinks = [
        '',
        '/abc',
        '/abc2',
        null,
        null,
        null
    ]

    // render
    return (
        <div className="flex flex-col gap-8 investment-cards">
            {data.map((item: any, index: any) => (
                <div
                    key={item.number}
                    style={{ background: cardStyles[index] }}
                    className={`flex h-[500px] flex-col md:flex-row md:justify-between ${index % 2 ? 'md:flex-row-reverse' : ''
                        } items-center p-6 pb-0 rounded-2xl`}
                >
                    {/* Text Section */}
                    <div className={`md:w-[35%] w-full text-white ${index % 2 ? 'md:pe-[50px]' : 'md:ps-[50px]'}`}>
                        <div className="card-text md:mx-auto md:w-full">
                            <InvestmentCard
                                number={item.number}
                                title={item.title}
                                description={item.description}
                                bg={cardStyles[index]}
                                link={cardLinks[index]}
                            />
                        </div>
                    </div>

                    {/* Image Section */}
                    <div className="rounded-xl w-full md:w-[55%] mt-4 md:mt-0">
                        <div>
                            <img
                                src={item.image}
                                alt={item.title}
                                className="w-full h-auto object-cover scale-150 md:scale-110"
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>

    );
};

export default InvestmentOptions;
