import React from 'react'
import chatIcon from '../../../../assets/images/document/chat.svg'
import folderIcon from '../../../../assets/images/document/folder.svg'
import exploreIcon from '../../../../assets/images/document/explore.svg'
import { File, PlusIcon } from 'lucide-react'

const CreateSection = ({toggleAI}:any) => {
    return (
        <div>
            <div className='text-[#1e1e1e] text-3xl font-bold mb-4'>Documents</div>
            <div className='flex gap-5 flex-col md:flex-row items-center'>
                <div className='cursor-pointer flex w-full bg-white hover:bg-[#E1E5E43D] md:w-[20%] justify-between p-5 text-[#1E1E1E] border-[1px] border-[#E8E8E8] rounded-xl'>
                    <div className='flex flex-col gap-2.5'>
                        <File color='#1E1E1E' size={32} className='bg-[#B0D5F1] p-1.5 rounded-lg' />
                        <p className='font-semibold text-lg'>New Upload</p>
                    </div>
                    <PlusIcon size={21} />
                </div>

                <div className='cursor-pointer flex w-full bg-white hover:bg-[#E1E5E43D] md:w-[20%] justify-between p-5 text-[#1E1E1E] border-[1px] border-[#E8E8E8] rounded-2xl'>
                    <div className='flex flex-col gap-2.5'>
                        <img src={folderIcon} alt='chatIcon' className='bg-[#1E1E1E] p-2 rounded-lg h-10 w-10' />
                        <p className='font-semibold text-lg'>Create Folder</p>
                    </div>
                    <PlusIcon size={21} />
                </div>

                <div onClick={toggleAI} className='cursor-pointer flex w-full bg-white hover:bg-[#E1E5E43D] md:w-[20%] justify-between p-5 text-[#1E1E1E] border-[1px] border-[#E8E8E8] rounded-2xl'>
                    <div className='flex flex-col gap-2.5'>
                        <img src={chatIcon} alt='chatIcon' className='bg-[#1E1E1E] p-2 rounded-lg h-10 w-10' />
                        <p className='font-semibold text-lg'>Ask AI</p>
                    </div>
                    <img src={exploreIcon} alt='chatIcon' className='h-8 w-8' />
                </div>
            </div>
        </div>
    )
}

export default CreateSection