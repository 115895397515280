import React from 'react'
import cp1 from './Image/cpcard1.png'
import cp2 from './Image/cpcard2.png'
import cp3 from './Image/cpcard3.png'
import cp4 from './Image/cpcard4.png'
import loc from './Image/loc.png'
import dart from './Image/dart.png'

function PropertiesSection() {
    return (
        <div className='bg-[#F8F6EF] p-5 relative'>
            <div className='cur_pro bg-white p-5 md:p-12 rounded-3xl flex-col md:max-w-[80%] mx-auto'>
                <h2 className='cur_tit text-4xl font-medium mb-10'>Current Properties</h2>

                <div className='w-full flex-col md:flex-row gap-5 flex'>
                    <div className='pcard md:w-1/2'>
                        <div className='cpimg'>
                            <img src={cp1} className='md:w-full md:h-auto' />
                            <p className='sps rounded-xl mt-3 mr-2 bg-opacity-20'>Rooftop garden, gym, swimming pool</p>
                            <p className='pos rounded-xl mb-3 ml-2 bg-opacity-20'>For sale</p>
                        </div>
                        <div className='cpcard-desc'>
                            <p className='highlight'>Residential</p>
                            <h1 className='cpcard-title text-xl md:text-2xl font-medium'>The Heights</h1>
                            <div className='plocat'>
                                <img src={loc} className='ploc' />
                                <p className='pcity'>Downtown</p>
                                <img src={dart} className='pdart' />
                                <p className='psqft'>3,000 sq. ft.</p>

                            </div>
                        </div>
                    </div>

                    <div className='pcard md:w-1/2'>
                        <div className='cpimg'>
                            <img src={cp2} className='md:w-full md:h-auto' />
                            <p className='sps rounded-xl mt-3 mr-2 bg-opacity-20'>Conference rooms, parking, cafeteria</p>
                            <p className='pos rounded-xl mb-3 ml-2 bg-opacity-20'>For lease</p>
                        </div>
                        <div className='cpcard-desc'>
                            <p className='highlight'>Commercial</p>
                            <h1 className='cpcard-title text-xl md:text-2xl font-medium'>Midtown Plaza</h1>
                            <div className='plocat'>
                                <img src={loc} className='ploc' />
                                <p className='pcity'>Midtown</p>
                                <img src={dart} className='pdart' />
                                <p className='psqft'>50,000 sq. ft.</p>

                            </div>
                        </div>
                    </div>
                </div>

                <h2 className='cur_tit text-4xl font-medium my-10'>Past Properties</h2>

                <div className='w-full flex-col md:flex-row gap-5 flex'>
                    <div className='pcard md:w-1/2'>
                        <div className='cpimg'>
                            <img src={cp3} className='md:w-full md:h-auto' />
                            <p className='pos rounded-xl mb-3 ml-2 bg-opacity-20'>Achieved a 25% ROI</p>
                        </div>
                        <div className='cpcard-desc'>
                            <p className='highlight'>Successfully sold</p>
                            <h1 className='cpcard-title text-xl md:text-2xl font-medium'>Riverfront Residences</h1>
                            <div className='plocat'>

                                <img src={dart} className='pdart' />
                                <p className='psqft'>Testimonials</p>
                            </div>
                            <div className="pcas">
                                <p>"A great investment with excellent returns! - Annie Walker"</p>
                            </div>
                        </div>
                    </div>

                    <div className='pcard md:w-1/2'>
                        <div className='cpimg'>
                            <img src={cp4} className='md:w-full md:h-auto' />
                            <p className='pos rounded-xl mb-3 ml-2 bg-opacity-20'>Generated $2 million in annual revenue</p>
                        </div>
                        <div className='cpcard-desc'>
                            <p className='highlight'>Leased within six months</p>
                            <h1 className='cpcard-title text-xl md:text-2xl font-medium'>Green Acres Industrial Park</h1>
                            <div className='plocat'>

                                <img src={dart} className='pdart' />
                                <p className='psqft'>Case Study</p>

                            </div>
                            <div className="pcas">
                                <p>A great Efficient project management and strategic location led to rapid occupancy. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PropertiesSection