import React from 'react'
import LussoStandOut from './LussoStandOut'
import overlay from '../../../../assets/images/lusso/standoutOverlay.svg'

function StandOut() {
    return (
        <div className='bg-gradient-to-b from-[#050509] to-[#040306] relative pb-[100px]'>
            <div className="text-white p-8 font-sans md:w-[80%] mx-auto ">
                {/* Stand out */}
                <LussoStandOut />
            </div>
            <div className='md:w-[70%] absolute -top-[15%] -right-[20%] scale-110 z-10'>
                <img src={overlay} alt='map' />
            </div>
        </div>
    )
}

export default StandOut