import React, { useState } from 'react';

const NurwoodProducts = ({ products }: any) => {
    const [hoveredIndex, setHoveredIndex] = useState<any>(null);

    console.log(products)
    return (
        <div className="w-full flex overflow-x-auto overflow-y-hidden gap-3 mb-[50px] scrollbar-none">
            {
                products.map((item: any, index: number) => {
                    return (
                        <div className='py-5 flex flex-col gap-3 min-h-fit md:min-h-[600px] justify-center'>
                            <div
                                className={`-mt-5 top-0 left-5 w-12 h-12 rounded-full border-2 justify-center items-center flex border-[#E8E8E8] text-[#4a4848] bg-white text-[2rem] font-bold transition-all duration-300 ${hoveredIndex === index ? 'hidden' : ''}`}
                            >
                                {(index + 1).toString().padStart(2, '0')}
                            </div>
                            <div
                                className={`bluecard group cursor-pointer hover:transform hover:rotate-[10deg] hover:min-h-[400px] hover:mx-8 hover:bg-aqua transition-all duration-300 hover:my-10`}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                                key={item}
                                style={{
                                    backgroundImage: hoveredIndex === index ? `url(${item.image})` : '',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                }}>

                                <span className='tracking-tighter group-hover:hidden'>
                                    {
                                        item?.name.split(' ').map((word: string, index: number) => (
                                            <React.Fragment key={index}>
                                                {word}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                </span>
                                <p className='p-2 group-hover:hidden'>
                                    {item?.description}
                                </p>

                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
};

export default NurwoodProducts