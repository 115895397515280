import React from "react";
import Contact_us from "./Contact_us";
import ServicesSection from "./ServicesSection";
import HeroSection from "./HeroSection";
import TestimonialsSection from "./TestimonialsSection";
import CommitmentsSection from "./CommitmentsSection";
import "./landing.scss"
import FloatingButton from "../../components/common/FloatingButton";

const LandingPage: React.FC = () => {
  return (
    <div className="LandingPage">
      <HeroSection />
      <ServicesSection />
      <CommitmentsSection />
      <TestimonialsSection />
      <Contact_us />
      <FloatingButton link={'https://calendly.com/lussolabs/gaskon-meet-up'} />
    </div>
  );
};

export default LandingPage;
