import React, { useEffect, useState } from "react";
import "./TestimonialsSection.scss";
import RefreshIcon from "../../assets/images/icons/RefreshIcon.svg";
import commaIcon from "../../assets/images/icons/invert.png";
import { testimonialsData } from "../../utils/helperFunctions";


const TestimonialsSection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleTestimonials, setVisibleTestimonials] = useState(testimonialsData?.slice(0, 4));

  const shuffleCards = () => {
    const nextIndex = (currentIndex + 4) % testimonialsData?.length;
    setVisibleTestimonials(testimonialsData?.slice(nextIndex, nextIndex + 4));
    setCurrentIndex(nextIndex);
  };

  
  useEffect(() => {
    const interval = setInterval(shuffleCards, 10000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="testimonials-section">
      <div className="testimonials-header">
        <div
           style={{
            // padding: "10px 16px",
            borderRadius: "16px",
            // overflow: "hidden",
            border: "2px rgba(7, 186, 210, 0.75) solid",
            justifyContent: "center",
            alignItems: "center",
            // gap: 10,
            // display: "inline-flex",
          }} 
        className="flex justify-center w-28">
          <span className="font-normal text-xs uppercase px-4 py-1 text-center">Testimonials</span>
        </div>
        <h1 className="font-test-founders text-6xl font-semibold uppercase py-4">
          Don't just take our word for it
        </h1>
        <p className="font-normal text-base justify-center">Hear from the businesses and investors we've helped thrive.</p>
      </div>
      <div className="testimonials-content" style={{ marginBottom: "120px" }}>
        <div className="quote-left">
          <img src={commaIcon} alt="comma" />
        </div>

        {visibleTestimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <div className="name">{testimonial.name}</div>
            <div className="title">{testimonial.title}</div>
            <div className="review">{testimonial.review}</div>
          </div>
        ))}

        <div className="refresh-icon-container" onClick={shuffleCards}>
          <div className="refresh-icon">
            <img src={RefreshIcon} alt="Refresh Icon" />
          </div>
        </div>
        
        <div className="quote-right">
          <img src={commaIcon} alt="comma" />
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
