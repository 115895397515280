import React, { useEffect, useState } from 'react'
import './admin_home.scss';
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import { IoEyeOutline } from "react-icons/io5";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import Cookies from 'js-cookie';
import axios from 'axios';

const AdminHome = () => {
  const [loading, setLoading] = useState(false);
  const [totalRequestCount, setTotalRequestCount] = useState(0);
  const [approvedRequestCount, setApprovedRequestCount] = useState(0);
  const [pendingRequestCount, setPendingRequestCount] = useState(0);
  const [rejectedRequestCount, setRejectedRequestCount] = useState(0);
  const [requests, setRequests] = useState<{ requestId: string, status: string, email: string, createdOn: string }[]>([]);
  const [page, setPage] = useState(0);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  
  useEffect(() => {
    getApprovalRequests();
  }, []);

  const getApprovalRequests = () => {
    setLoading(true);
    const token = Cookies.get("authToken");
    let headers = {
      "Authorization": `Bearer ${token}`
    }
    axios.get(`${process.env.REACT_APP_BASE_URL}accessRequests?page=0&size=10`, { headers: headers }).then(response => {
      let requests = response.data.requests;
      setTotalRequestCount(response.data.recordsFiltered);
      setApprovedRequestCount(response.data.approvedRequestCount);
      setPendingRequestCount(response.data.pendingRequestCount);
      setRejectedRequestCount(response.data.rejectedRequestCount);
      setRequests(requests);
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setLoading(false);  // Ensure loading is stopped after the API call finishes
    });
  }

  const handleAccessRequestAction = (requestId: string, action: string) => {
    setLoading(true);
    const token = Cookies.get("authToken");
    let headers = {
      "Authorization": `Bearer ${token}`
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}accessRequestAction`, { requestId: requestId, action: action }, { headers: headers }).then(response => {
      if (response.status == 200) {
        getApprovalRequests();
      }
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setLoading(false);  // Ensure loading is stopped after the API call finishes
    });
  }

  const homeContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '20px' }}>
        <div style={{ color: '#1E1E1E', fontSize: '32px', fontFamily: 'Outfit', fontWeight: '600', lineHeight: '40px', wordWrap: 'break-word' }}>Investor Requests</div>
        <div style={{ height: '100%', paddingLeft: 20, paddingRight: 20, paddingTop: 8, paddingBottom: 8, background: 'rgba(224.72, 228.97, 227.52, 0.24)', borderRadius: 8, border: '1px rgba(128, 139, 139, 0.20) solid', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
          <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
            <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
              <div style={{ color: '#0177D1', fontSize: 12, fontFamily: 'Outfit', fontWeight: '500', lineHeight: '12px', wordWrap: 'break-word' }}>All</div>
              <div style={{ color: '#0177D1', fontSize: 12, fontFamily: 'Inter', fontWeight: '700', lineHeight: '12px', wordWrap: 'break-word' }}>({totalRequestCount})</div>
            </div>
          </div>
          <div style={{ width: 0, height: 17, transform: 'rotate(19deg)', transformOrigin: '0 0', opacity: 0.30, border: '1px #808B8B solid' }}></div>
          <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
            <div style={{ color: '#4B4B4B', fontSize: 12, fontFamily: 'Outfit', fontWeight: '500', lineHeight: '12px', wordWrap: 'break-word' }}>Pending</div>
            <div style={{ color: '#4B4B4B', fontSize: 12, fontFamily: 'Inter', fontWeight: '700', lineHeight: '12px', wordWrap: 'break-word' }}>({pendingRequestCount})</div>
          </div>
          <div style={{ width: 0, height: 17, transform: 'rotate(19deg)', transformOrigin: '0 0', opacity: 0.30, border: '1px #808B8B solid' }}></div>
          <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
            <div style={{ color: '#4B4B4B', fontSize: 12, fontFamily: 'Outfit', fontWeight: '500', lineHeight: '12px', wordWrap: 'break-word' }}>Approved</div>
            <div style={{ color: '#4B4B4B', fontSize: 12, fontFamily: 'Inter', fontWeight: '700', lineHeight: '12px', wordWrap: 'break-word' }}>({approvedRequestCount})</div>
          </div>
          <div style={{ width: 0, height: 17, transform: 'rotate(19deg)', transformOrigin: '0 0', opacity: 0.30, border: '1px #808B8B solid' }}></div>
          <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'flex' }}>
            <div style={{ color: '#4B4B4B', fontSize: 12, fontFamily: 'Outfit', fontWeight: '500', lineHeight: '12px', wordWrap: 'break-word' }}>Declined</div>
            <div style={{ color: '#4B4B4B', fontSize: 12, fontFamily: 'Inter', fontWeight: '700', lineHeight: '12px', wordWrap: 'break-word' }}>({rejectedRequestCount})</div>
          </div>
        </div>
        <div style={{ width: '100%', height: '100%', opacity: 0.10, border: '1px #808B8B solid' }}></div>
        <table className="table">
          <thead>
            <tr style={{ background: 'white' }}>
              <th align="left">Email</th>
              <th align="left" >Raised On</th>
              <th align="left">Status</th>
              <th align="left"></th>
            </tr>
          </thead>
          <tbody>
            {
              loading ?
                (<div className="skeleton">
                  <div className="skeleton-line"></div>
                  <div className="skeleton-line"></div>
                  <div className="skeleton-line"></div>
                  <div className="skeleton-line"></div>
                  <div className="skeleton-line"></div>
                </div>)
                : (
                  requests.map((request, index) => (
                    <tr key={index} style={{ padding: '8px', background: 'white', borderBottom: '1px #E1E5E4 solid' }} >
                      <td data-label="Name" align="left">
                        <div style={{ color: '#2B3940', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '500', lineHeight: '16px', wordWrap: 'break-word' }}>{request.email}</div>
                      </td>
                      <td data-label="Name" align="left">
                        <div style={{ color: '#2B3940', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '500', lineHeight: '16px', wordWrap: 'break-word' }}>{request.createdOn}</div>
                      </td>
                      <td data-label="Name" align="left">
                        <div style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4, background: request.status === "Approved" ? 'rgba(78, 167, 55, 0.24)' : request.status === "Rejected" ? '#F3E8E5' : '#FBF5E6', borderRadius: 8, justifyContent: 'center', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                          {request.status === "Approved" ? <TiTick color='#4EA737' /> : request.status === "Rejected" ? <RxCross2 color='#B53513' /> : <IoEyeOutline color='#D69C07' />}
                          <div style={{ color: request.status === "Approved" ? '#4EA737' : request.status === "Rejected" ? '#B53513' : '#D69C07', fontSize: 12, fontFamily: 'Outfit', fontWeight: '700', lineHeight: '12px', wordWrap: 'break-word' }}>{request.status}</div>
                        </div>
                      </td>
                      <td data-label="Name" align="right">
                        {request.status === "Pending" ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '20px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleAccessRequestAction(request.requestId, "Approve")}>
                            <TiTick color='black' />
                            <div style={{ color: '#989898', fontSize: '12px', fontFamily: 'Outfit', fontWeight: '500', lineHeight: '12px', letterSpacing: 0.12, wordWrap: 'break-word' }}>Approve</div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', cursor: 'pointer' }} onClick={() => handleAccessRequestAction(request.requestId, "Reject")}>
                            <RxCross2 color='black' />
                            <div style={{ color: '#989898', fontSize: '12px', fontFamily: 'Outfit', fontWeight: '500', lineHeight: '12px', letterSpacing: 0.12, wordWrap: 'break-word' }}>Reject</div>
                          </div>
                          <IoEllipsisHorizontalSharp color='black' />
                        </div> : ""}
                      </td>
                    </tr>
                  ))
                )
            }
          </tbody>
        </table>
      </div>
    );
  }

  return (
    homeContent()
  );
}

export default AdminHome