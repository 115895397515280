import React, { useState } from 'react';
import Profile from '../../../../assets/images/sampleProfile.png';
import quoteIcon from '../../../../assets/images/evolveEnergy/quote.svg'

const ClientReviewSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const reviews = [
        {
            quote: 'Partnering with Norwood Energy has been a game-changer for our business. Their commitment to innovation and sustainability aligns perfectly with our values. We\'ve seen significant improvements in efficiency and a reduction in our environmental impact. Highly recommend their services.',
            author: 'John M',
            role: 'Founder & COO, Blue Rhythm',
            brandIdentity: 'Brand Identity, UX UI Design, Website',
        },
        {
            quote: 'Norwood Energy has been instrumental in helping us achieve our sustainability goals. Their expertise and innovative solutions have transformed the way we operate. We\'re incredibly satisfied with the results and highly recommend their services to any business looking to reduce their environmental footprint.',
            author: 'Sarah P',
            role: 'CEO, Green Solutions',
            brandIdentity: 'Branding, Web Development, Consulting',
        },
        {
            quote: 'Working with Norwood Energy has been a game-changer for our organization. Their commitment to innovation and sustainability is unparalleled. We\'ve seen significant improvements in our energy efficiency and a noticeable reduction in our environmental impact. Highly recommend their services.',
            author: 'Michael T',
            role: 'CFO, Eco-friendly Enterprises',
            brandIdentity: 'Brand Strategy, UI/UX, Website Redesign',
        },
        {
            quote: 'Partnering with Norwood Energy has been a transformative experience for our business. Their expertise in sustainable solutions has helped us streamline our operations and reduce our carbon footprint. We\'re incredibly satisfied with the results and highly recommend their services to any organization looking to go green.',
            author: 'Emily S',
            role: 'COO, Sustainable Solutions',
            brandIdentity: 'Branding, Digital Marketing, Analytics',
        },
    ];

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? reviews.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === reviews.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="bg-white py-12 sm:py-24 rounded-3xl relative" style={{ boxShadow: '0px 8px 124px 0px #1E1E1E0A' }}>
            <button
                type="button"
                className="absolute -left-2 top-[45%] rounded-full bg-gray-100 p-1 text-gray-400 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                onClick={handlePrev}
                style={{ boxShadow: '0px 5px 24px 0px #00000014' }}
            >
                <svg
                    className="h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            <button
                type="button"
                className="absolute -right-2 top-[45%] rounded-full bg-gray-100 p-1 text-gray-400 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                onClick={handleNext}
                style={{ boxShadow: '0px 5px 24px 0px #00000014' }}
            >
                <svg
                    className="h-7 w-7"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col items-center">
                    <h2 className="ms-4 text-4xl md:text-7xl uppercase font-bold tracking-tight text-[#15171A]">What Our Clients Say</h2>
                    <div className="md:mt-8 w-full">
                        <div className="mx-auto md:max-w-5xl">
                            <div className="relative">
                                <div className="my-6 md:mt-12 md:mb-16 text-center text-xl md:text-4xl font-normal text-[#15171A]">
                                    <div className="flex items-center md:max-w-[80%] mx-auto">
                                        <img src={quoteIcon} alt="Quote Icon" className="w-6 h-6 md:w-8 md:h-8 mr-2" />
                                        <span>{reviews[currentIndex].quote}"</span>
                                    </div>

                                </div>
                                <div className='flex gap-0.5 w-full'>
                                    {reviews.map((_, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                border: index === currentIndex ? '2px' : '2px solid #E1E1E1',
                                            }}
                                            className={`flex-1 mx-1 ${index == currentIndex
                                                ? 'bg-gradient-to-r from-[#0597FF] to-[#90D1FF]'
                                                : 'border-[#E1E1E1]'
                                                }`}
                                        />
                                    ))}
                                </div>

                            </div>
                            <div className="mt-4 flex flex-col md:flex-row gap-5 md:gap-0 items-center justify-between">
                                <div className="flex-shrink-0">
                                    <div className="testimonial-author">
                                        <img src={Profile} alt="John M" className="author-photo" />
                                        <div className="author-infos">
                                            <span className="author-name">{reviews[currentIndex].author}</span>
                                            <span className="author-title">{reviews[currentIndex].brandIdentity}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center md:text-end">
                                    <p className="role-text">Brand Identity, UX/UI Design, Website</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientReviewSection