import React from 'react'
import './edge.scss'
import map from './Image/map.png'
import bag from './Image/bag.png'
import shop from './Image/shop.png'
import trans from './Image/trans.png'
import park from './Image/park.png'


export default function Edge() {
    return (
        <div className="md:max-w-[90%] mx-auto p-5 md:p-0 pb-10">
            <div className="relative md:py-[50px]">
                <div className="hidden md:block absolute top-0 bottom-0 left-10 w-px bg-gray-200"></div>
                <div className=' md:w-[90%] mx-auto'>
                    <div className='flex flex-col gap-5'>
                        <div className='md:ms-[150px] flex flex-col gap-2 md:w-[50%] mb-10'>
                            <div className='etits'>
                                <h2 className='text-3xl md:text-4xl font-medium'>Century 21 Realty's Edge</h2>
                            </div>
                            <div className='subetit flex flex-col gap-2 '>
                                <h4 className='text-[#0C070F] text-lg'>Superior Location Strategy</h4>
                                <p className='text-md'>Century 21 Realty excels in selecting strategic property locations. Our properties are situated in areas with high demand and excellent growth potential.</p>
                            </div>
                        </div>
                        <div className='flex flex-wrap gap-10 md:gap-[100px] md:mx-auto md:ms-[150px] '>
                            <div className='ecard'>
                                <div className='ehead'>
                                    <p>Century 21 Heights</p>
                                </div>
                                <div className='imap'>
                                    <img src={map} className='cmap' />
                                </div>
                                <div className='cdesc flex flex-col gap-2'>
                                    <div className='citems'>
                                        <img src={bag} />
                                        <p><span>School    </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={shop} />
                                        <p><span>Shopping  </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={park} />
                                        <p><span>Parks  </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={trans} />
                                        <p><span>Public Transport </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='ecard'>
                                <div className='ehead'>
                                    <p>Century 21 Heights</p>
                                </div>
                                <div className='imap'>
                                    <img src={map} className='cmap' />
                                </div>
                                <div className='cdesc flex flex-col gap-2'>
                                    <div className='citems'>
                                        <img src={bag} />
                                        <p><span>School    </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={shop} />
                                        <p><span>Shopping  </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={park} />
                                        <p><span>Parks  </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={trans} />
                                        <p><span>Public Transport </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='ecard'>
                                <div className='ehead'>
                                    <p>Century 21 Heights</p>
                                </div>
                                <div className='imap'>
                                    <img src={map} className='cmap' />
                                </div>
                                <div className='cdesc flex flex-col gap-2'>
                                    <div className='citems'>
                                        <img src={bag} />
                                        <p><span>School    </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={shop} />
                                        <p><span>Shopping  </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={park} />
                                        <p><span>Parks  </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={trans} />
                                        <p><span>Public Transport </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='ecard'>
                                <div className='ehead'>
                                    <p>Century 21 Heights</p>
                                </div>
                                <div className='imap'>
                                    <img src={map} className='cmap' />
                                </div>
                                <div className='cdesc flex flex-col gap-2'>
                                    <div className='citems'>
                                        <img src={bag} />
                                        <p><span>School    </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={shop} />
                                        <p><span>Shopping  </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={park} />
                                        <p><span>Parks  </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                    <div className='citems'>
                                        <img src={trans} />
                                        <p><span>Public Transport </span>0.7 miles to Washington High School, 0.8 miles to Riverside Elementary.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
