import { CrossIcon, MoreHorizontal, MoreVertical, Trash, Trash2, X } from "lucide-react";

const OptionsDropdown = ({ isOpen, onToggle, onRemove, onDelete }: any) => (
    <div className="relative">
        <button
            type='button'
            onClick={onToggle}
            className="hidden md:block dropdown-toggle"
        >
            <MoreVertical size={20} />
        </button>
        <button
            type='button'
            onClick={onToggle}
            className="md:hidden dropdown-toggle"
        >
            <MoreHorizontal size={20} />
        </button>
        {isOpen && (
            <div className="z-10 font-medium dropdown-menu absolute right-0 mt-3 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 block">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <button
                        onClick={onRemove}
                        className="flex items-center border-b-px border-b-gray-400 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        role="menuitem"
                    >
                        <X className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Remove
                    </button>
                    <button
                        onClick={onDelete}
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                        role="menuitem"
                    >
                        <X className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Cancel
                    </button>
                </div>
            </div>
        )}
    </div>
);

export default OptionsDropdown