import React from "react";
import "./ServicesSection.scss"; // Make sure to create this SCSS file with the styles
// import ArrowNE from "../../assets/images/icons/arrow-ne.svg";
import ArrowNE from "../../assets/images/icons/arrowWhite.png";
import animateArrow from "../../assets/images/icons/animateIcon.png";
import { useNavigate } from "react-router-dom";

const ServicesSection: React.FC = () => {
 const navigate = useNavigate()
  return (
    <div className="services-section">
      <div className="services-intro">
        <div className="hidden md:block circles-background">
          {/* Circles in the background */}
          <div className="hidden md:block circle large"></div>
          <div className="hidden md:block circle medium"></div>
          <div className="hidden md:block circle small"></div>
          <div className="hidden md:block circle smallest"></div>
        </div>
        <article className="text-balance">
          <div className="tag-container">
            <span className="font-normal text-xs uppercase" style={{border: "1px solid linear-gradient(92.96deg, rgba(0, 118, 208, 0.75) 37.17%, rgba(14, 254, 212, 0.75) 106.2%)"}}>
              Services We Offer
            </span>
          </div>
          <h1
            className="text-4xl font[Test Founders Grotesk X-Condensed] font-semibold uppercase py-6"
            style={{ color: "#FFFFFF" }}
          >
            Our Expertise at your Service
          </h1>
          <p
            className="text-wrap text-base font-[Outfit] font-normal mb-5 w-3/4"
            style={{ color: "#B9B9B9" }}
          >
            At Gaskon, we provide comprehensive business consultancy services
            tailored to your needs. Our expert team delivers strategic insights
            and practical solutions to drive your business forward.
          </p>
          <button
            className="lets-talk"
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={() =>  navigate("/Contact")}
          >
            <span
              className="font-[Outfit] text-base font-bold"
              style={{ paddingLeft: "19px", color: "#1E1E1E" }}
            >
              Contact Us
            </span>
            <div className="arrow-container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "40px",
                height: "40px",
                background: "black",
                borderRadius: 9999,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={ArrowNE} alt="arrow" className="arrow" />
              <img src={animateArrow} alt="arrow" className="new-arrow" />
            </div>
            </div>
          </button>
        </article>
      </div>
      <div className="services-list">
        <div className="service-item">
          <div className="icon market-analysis-icon"></div>
          <div className="service-content">
            <h3 className="font-bold text-xl">
              Market Analysis
              <span
                className="text-base font-normal"
                style={{ color: "#B9B9B9" }}
              >
                :{"  "} In-depth research to identify opportunities and
                competitive advantages.
              </span>{" "}
            </h3>
          </div>
        </div>
        <div className="service-item">
          <div className="icon strategy-development-icon"></div>
          <div className="service-content">
            <h3 className="font-bold text-xl">
              Business Strategy Development
              <span
                className="text-base font-normal"
                style={{ color: "#B9B9B9" }}
              >
                :{"  "} Crafting bespoke strategies to achieve your business
                goals.
              </span>
            </h3>
          </div>
        </div>
        <div className="service-item">
          <div className="icon financial-planning-icon"></div>
          <div className="service-content">
            <h3 className="font-bold text-xl">
              Financial Planning and Analysis
              <span
                className="text-base font-normal"
                style={{ color: "#B9B9B9" }}
              >
                :{"  "} Expert financial management to optimize performance and
                growth.
              </span>
            </h3>
          </div>
        </div>
        <div className="service-item">
          <div className="icon risk-management-icon"></div>
          <div className="service-content">
            <h3 className="font-bold text-xl">
              Risk Management
              <span
                className="text-base font-normal"
                style={{ color: "#B9B9B9" }}
              >
                : Proactive risk assessment and mitigation strategies to
                safeguard your business.
              </span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
