import { ArrowRight } from 'lucide-react';
import React from 'react';

const InvestmentCard = ({ number, title, description, bg, link }: any) => {
    return (
        <div className="relative text-white rounded-lg">
            {/* Large Number in Background */}
            <div className="absolute top-4 -mt-[60px] md:text-[100px] font-bold text-white card-number-new" style={{ color: bg }}>
                {number}
            </div>

            {/* Title */}
            <div className="relative mt-2">
                <h2 className="text-5xl font-bold mb-2 pt-[.5rem]">{title}</h2>
            </div>

            {/* Icon and Description */}
            <div className="relative  mt-4 flex items-start space-x-2">
                {/* Icon */}
                <div className="text-2xl">✦</div>
                {/* Description */}
                <p className="text-lg">
                    {description}

                    {/* See Available Projects Link */}
                    {link && <div className="relative mt-3">
                        <a href={link} className="underline inline-flex items-center text-white hover:underline">
                            See Available Projects
                            <ArrowRight className="ml-2 h-4 w-4" />
                        </a>
                    </div>}
                </p>
            </div>


        </div>
    );
};

export default InvestmentCard;
