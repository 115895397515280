
import React from 'react'
import './css/gallery.scss'
import Ga1 from './Image/ga1.png'
import Ga2 from './Image/ga2.png'
import Ga3 from './Image/ga3.png'
import Ga4 from './Image/ga4.png'
import cp1 from './Image/cpcard1.png'
import cp2 from './Image/cpcard2.png'
import loc from './Image/loc.png'
import dart from './Image/dart.png'

export default function Gallery() {
    return (
        <div className='bg-[#F8F6EF] pb-5 md:pb-[100px]'>
            <div className='pgal md:justify-end flex-col md:flex-row  w-full'>
                <div className='pitem1 flex flex-col md:flex-row md:justify-end gap-3 w-full md:w-auto p-3 md:p-0'>
                    <div className='md:max-w-[50%] flex flex-col gap-2 justify-start md:pr-10'>
                        <h1 className='text-wrap text-[#414141] text-xl md:text-5xl font-medium capitalize'>Gallery of Excellence</h1>
                        <p className='text-md md:text-lg text-[#767676]'>Witness the unique features and superior quality of our developments, showcasing both interiors and exteriors.</p>
                    </div>
                    <img src={Ga1} />
                </div>
                <div className='pitem'>
                    <img src={Ga3} />
                </div>
                <div className='pitem'>
                    <img src={Ga4} />
                </div>
                <div className='pitem'>
                    <img src={Ga2} className='md:bottom-[110px] md:right-[10px] md:h-[480px] md:relative' />
                </div>
            </div>


            <div className='md:max-w-[80%] mx-auto max-w-[90%]'>
                <div className='w-full flex-col md:flex-row gap-5 md:gap-[3rem] flex'>
                    <div className='pcard md:w-1/2'>
                        <div className='cpimg'>
                            <img src={cp1} className='md:w-full md:h-auto' />
                            <p className='sps rounded-xl mt-3 mr-2 bg-opacity-20'>Rooftop garden, gym, swimming pool</p>
                            <p className='pos rounded-xl mb-3 ml-2 bg-opacity-20'>For sale</p>
                        </div>
                        <div className='cpcard-desc'>
                            <p className='phighlights'>Residential</p>
                            <h1 className='cpcard-title text-xl md:text-2xl font-medium'>The Heights</h1>
                            <div className='plocat'>
                                <img src={loc} className='ploc' />
                                <p className='pcity'>Downtown</p>
                                <img src={dart} className='pdart' />
                                <p className='psqft'>3,000 sq. ft.</p>

                            </div>
                        </div>
                    </div>

                    <div className='pcard md:w-1/2'>
                        <div className='cpimg'>
                            <img src={cp2} className='md:w-full md:h-auto' />
                            <p className='sps rounded-xl mt-3 mr-2 bg-opacity-20'>Conference rooms, parking, cafeteria</p>
                            <p className='pos rounded-xl mb-3 ml-2 bg-opacity-20'>For lease</p>
                        </div>
                        <div className='cpcard-desc'>
                            <p className='phighlights'>Commercial</p>
                            <h1 className='cpcard-title text-xl md:text-2xl font-medium'>Midtown Plaza</h1>
                            <div className='plocat'>
                                <img src={loc} className='ploc' />
                                <p className='pcity'>Midtown</p>
                                <img src={dart} className='pdart' />
                                <p className='psqft'>50,000 sq. ft.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
