import React from 'react';

const focusAreas = [
    {
        title: "Product Development",
        description: "Enhancing AI-driven tools and refining our platform's capabilities."
    },
    {
        title: "Marketing",
        description: "Expanding brand visibility through targeted campaigns and increasing user adoption."
    },
    {
        title: "User Acquisition",
        description: "Scaling customer base and market penetration through partnerships and outreach."
    },
    {
        title: "Operational Scaling",
        description: "Building infrastructure and resources to handle rapid growth across multiple markets."
    }
];

export default function StrategicFocusAreas() {
    return (
        <div className="text-white flex flex-col items-center">
            {/* Title */}
            <div className='md:py-[100px] text-center'>
                <div className="relative mb-5">
                    <p className="text-[#01D8E2] text-sm uppercase tracking-wider mb-2">
                        Use of Funds
                    </p>
                    <h1 className="text-4xl md:text-4xl lg:text-4xl font-bold leading-tight uppercase">
                        Powering Lusso.ai's Growth
                    </h1>
                    <div className="w-[60%] absolute -bottom-4 left-1/2 transform -translate-x-1/2 h-px bg-gradient-to-r from-transparent via-white to-transparent opacity-30" />
                </div>
                <p className='text-md text-white md:max-w-5xl md:mx-auto'>The raised capital will be strategically allocated to key growth areas that will drive long-term success and sustainable ROI for investors:</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                {focusAreas.map((area, index) => (
                    <div key={index} className="flex flex-col items-center text-center md:max-w-xl">
                        <h2 className="text-2xl font-bold mb-4 flex items-center">
                            <span className="mr-2 text-xl">✦</span> {area.title}
                        </h2>
                        <p className="text-lg opacity-80">{area.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}