import React, { useState, useEffect, useRef } from 'react'

const sections = [
  {
    title: "Value Proposition",
    content: "Norwood Energy provides reliable, high-quality energy solutions through cutting-edge oil refining technology. Our commitment to sustainability and innovation ensures efficient, environmentally responsible production, delivering exceptional value to our customers while supporting a cleaner, more sustainable future for the energy industry.",
  },
  {
    title: "Market Opportunity",
    content: "The renewable energy market is projected to reach $1.5 trillion by 2027, driven by increasing environmental awareness and government incentives. Our target audience includes eco-conscious homeowners, businesses seeking sustainable operations, and governmental bodies promoting green initiatives. With a CAGR of 10%, the growth potential in this market is substantial.",
  },
  {
    title: "Business Model",
    content: "GreenTech Solutions generates revenue through the sale of renewable energy systems, installation services, and maintenance contracts. We also offer financing options and leasing programs to make our products more accessible to a broader audience.",
  },
]

export default function ScrollableSection({ img }: { img: string }) {
  const [currentSection, setCurrentSection] = useState(0)
  const [isFocused, setIsFocused] = useState(false)
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([])
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  // Handle interval only if the div is focused
  useEffect(() => {
    if (!isFocused) return

    const interval = setInterval(() => {
      setCurrentSection((prev) => (prev + 1) % sections.length)
    }, 3000)

    return () => clearInterval(interval)
  }, [isFocused, currentSection])

  // Scroll to the current section
  useEffect(() => {
    if (isFocused) {
      sectionRefs.current[currentSection]?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [currentSection])

  // Focus and blur event handlers
  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <div
      className="flex flex-col md:flex-row h-screen bgGr-scroll"
      tabIndex={0} // Make the div focusable
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={scrollContainerRef}
    >
      <div className="w-full md:w-1/2 p-6 flex items-center justify-center">
        <img
          src={img}
          alt="Oil refinery"
          width={550}
          height={550}
          className="rounded-lg shadow-lg"
        />
      </div>
      <div className="w-full md:w-1/2 overflow-hidden">
        <div className="h-full overflow-y-auto snap-y snap-mandatory">
          {sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (sectionRefs.current[index] = el)}
              className="h-full snap-start flex flex-col justify-center p-6"
            >
              <h2 className="text-3xl font-bold mb-4 text-gray-800">{section.title}</h2>
              <p className="text-lg text-gray-600 w-[80%]">{section.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
