import React from 'react'
import img1 from '../../../../assets/images/gaskon/benefit1.svg'
import img2 from '../../../../assets/images/gaskon/benefit2.svg'
import img3 from '../../../../assets/images/gaskon/benefit3.svg'

function BenefitsOfListing() {

    const features = [
        {
            title: "Expanded Reach",
            description: "Gaskon connects you to a global network of investors, ensuring your opportunity reaches those who align with your goals. Maximize visibility and secure the right partnerships to fuel your growth.",
            icon: (
                <img src={img1} alt='img 1' />
            )
        },
        {
            title: "AI & Data Insights",
            description: "Gaskon's AI-powered predictive analytics provide valuable insights, helping you optimize strategies for successful investment outcomes. Make data-driven decisions that lead to higher returns.",
            icon: (
                <img src={img2} alt='img 2' />

            )
        },
        {
            title: "Streamlined Process: Reduce Time To Market",
            description: "Our intuitive platform simplifies every step, from listing your opportunity to closing deals. Save time and resources with a seamless process designed for efficiency and success.",
            icon: (
                <img src={img3} alt='img 3' />

            )
        }
    ];


    const StripedBackground = () => (
        <div className="absolute inset-0 w-full h-full">
            {[...Array(13)].map((_, i) => (
                <div
                    key={i}
                    className="h-4 w-full"
                    style={{
                        background: i % 2 === 0 ? 'rgba(243, 244, 246, 0.3)' : 'transparent'
                    }}
                />
            ))}
        </div>
    );

    return (
        <div className='relative bg-white'>
            <div className="overflow-hidden py-24 md:min-h-[45rem]">

                {/* Content Container */}
                <div className="md:w-[80%] mx-auto px-4 relative" style={{ zIndex: 1 }}>
                    {/* Header Section */}
                    <div className="mb-16 md:mb-24 flex flex-col md:flex-row justify-between items-center gap-6">
                        <div className="text-2xl md:text-3xl font-bold mb-6 gap-2 md:w-[300px] text-start">
                            <span className="me-2 bg-gradient-to-r from-[#0076D0] via-cyan-400 to-[#0EFED4]  bg-clip-text text-transparent">
                                Benefits of Listing
                            </span>
                            <span className='text-[#1D1D1D]'>Your Opportunity with Gaskon</span>
                        </div>
                        <span className="md:w-[2/5] text-blue-500 w-full h-px bg-gradient-to-r from-[#0076D0] to-[#0EFED4]"></span>

                        <div className="flex items-center gap-4 flex-col md:flex-row md:w-[2/5]">
                            <p className="text-gray-600 max-w-xl">
                                Gaskon's platform is an all-in-one solution designed to streamline
                                investment opportunities, data insights, and project management
                            </p>
                        </div>
                    </div>

                    {/* Features Grid */}

                    <div className="mx-auto px-4 py-16 space-y-20">
                        {features.map((feature, index) => (
                            <div
                                key={feature.title}
                                className={` flex flex-col ${index % 2 ? 'md:flex-row-reverse' : 'md:flex-row'} items-center gap-8 md:gap-16`}
                            >

                                <div className="relative w-full md:w-1/2 flex justify-center">
                                    {/* Striped Background */}
                                    <StripedBackground />

                                    <div className="w-full md:w-1/2 flex justify-center z-10">
                                        {feature.icon}
                                    </div>
                                </div>

                                <div className={`w-full md:w-1/2 space-y-4 bg-[#DEEDF980] p-10 ${index % 2 == 0 ? 'md:rounded-r-3xl' : 'md:rounded-l-3xl'}`}>
                                    <h2 className="text-2xl md:text-2xl font-bold text-[#1D1D1D]">
                                        {feature.title}
                                    </h2>
                                    <p className="text-gray-600 text-lg">
                                        {feature.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>


                </div>
            </div>
        </div>
    )
}

export default BenefitsOfListing