import React from 'react';
import { Building2, Heart, Droplet, Monitor, Droplets } from 'lucide-react';
import buildingIcon from '../../../../assets/images/gaskon/trusted1.svg'

const CoreAreaCard = ({ title, icon: Icon }: { title: string, icon: any }) => {
  return (
    // <div className="relative group p-[.5px] bg-white rounded-xl border border-[#E3E3E3] shadow-sm transition-all duration-300 cursor-pointer 
    // hover:border-transparent hover:bg-gradient-to-r hover:from-[#0076D0] via-cyan-400 hover:to-[#0EFED4] hover:bg-clip-border"
    //   style={{
    //     boxShadow: '0px 1px 3px 0px #0000001F',
    //     border: '1px solid linear-gradient(92.96deg, #0076D0 37.17%, #0EFED4 106.2%)'
    //   }}>
    <div className="relative group p-[.5px] bg-white rounded-xl border border-[#E3E3E3] shadow-sm transition-all duration-300 cursor-pointer 
      hover:border-transparent hover:bg-gradient-to-r hover:from-[#0076D0] via-cyan-400 hover:to-[#0EFED4] hover:bg-clip-border 
      hover:border-[1px] hover:border-gradient-to-r  hover:via-cyan-400"
      style={{
        boxShadow: '0px 1px 3px 0px #0000001F',
      }}>
      <div className="flex justify-between p-6 items-center bg-white rounded-xl">
        <span className="text-xl font-medium text-gray-800 group-hover:text-gray-900">{title}</span>
        {/* {
          title === 'Real Estate' ? <img src={buildingIcon} alt='real estate' className='h-6 w-auto hover:fill-teal-300 ' /> : */}
        <Icon className="w-6 h-6 text-gray-400 group-hover:text-[#0177D1] group-hover:scale-110 transition-all duration-300" />
        {/* } */}
      </div>
    </div>

  );
};
const TrustedPartnerSection = () => {
  const coreAreas = [
    { title: 'Real Estate', icon: Building2 },
    { title: 'Healthcare', icon: Heart },
    { title: 'Oil & Gas', icon: Droplets },
    { title: 'Technology', icon: Monitor },
  ];

  return (
    <div className="md:w-[80%] mx-auto px-4 py-16 md:py-24">
      <h1 className="text-4xl md:text-5xl font-bold mb-6">
        Your{' '}
        <span className="bg-gradient-to-r from-[#0076D0] via-cyan-400 to-[#0EFED4]  bg-clip-text text-transparent">
          Trusted Partner
        </span>{' '}
        In Strategic Growth
      </h1>

      <p className="text-lg text-[#616161] mb-12 max-w-5xl">
        Gaskon Consulting is a global consulting firm that connects businesses with investors, leveraging AI-driven
        insights to unlock potential across various industries.
      </p>

      <div className="space-y-4">
        <h2 className="text-xl text-[#1D1D1D] mb-6">Core areas are:</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {coreAreas.map((area, index) => (
            <CoreAreaCard
              key={index}
              title={area.title}
              icon={area.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrustedPartnerSection;