import "./scrollLogos.scss";
import Logo from "../../assets/images/icons/Gaskonlogo.svg";
import { useEffect, useRef } from "react";

const ScrollLogos = () => {
  const logoContainerRef = useRef(null);
  const logoImgRef = useRef(null);

  useEffect(() => {
    const logoContainer: any = logoContainerRef.current;
    const logoImg: any = logoImgRef.current;

    const appendLogo = () => {
      const clone = logoImg.cloneNode(true);
      logoContainer.appendChild(clone);
    };

    const intervalId = setInterval(() => {
      appendLogo();
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <div className="companies-logos">
    <div className="logos-scrolls" ref={logoContainerRef}>
      <img
        src={Logo}
        className="logoImg"
        alt="Company Logo"
        ref={logoImgRef}
        onError={(e) => {
          if (e.target instanceof HTMLImageElement) {
            e.target.src = Logo;
          }
        }}
      />
    </div>
  </div>
  );
};

export default ScrollLogos;
