import React, { useState } from 'react';
import { ChevronDown, Mail, Phone, Check, Eye } from 'lucide-react';
import StatusDropdown from '../StatusDropdown';
import moment from 'moment';

const MobileDataView = ({ openDropdownIndex, openDropdownOptionIndex, setOpenDropdownIndex, handleDropdownOptionToggle, requests, OptionsDropdown, handleStatusChange, setOpenDropdownOptionIndex }: any) => {

    const getStatusColor = (status: string) => {
        switch (status.toLowerCase()) {
            case 'approved': return 'bg-green-100 text-green-800';
            case 'pending': return 'bg-yellow-100 text-yellow-800';
            default: return 'bg-gray-100 text-gray-800';
        }
    };

    const getInitials = (name: string) => {
        return name ? name.charAt(0).toUpperCase() : 'A';
    };


    return (
        <div className="space-y-4">
            {requests.map((request: any, index: number) => (
                <div key={request.requestId} className="bg-white shadow rounded-lg text-[#1e1e1e] py-3">
                    <div className="flex items-center justify-between px-4 mb-2">
                        <div className="text-sm font-semibold bg-[#9D9A9A1A] rounded-lg p-2">Gulf Ventures</div>
                    </div>

                    <div className="flex items-center px-4 py-1 mb-4">
                        <div className={`w-12 h-12 rounded-full flex items-center justify-center text-white bg-green-400`}>
                            {getInitials(request.name)}
                        </div>
                        <div className="ml-4">
                            <div className="font-semibold">{request.name || 'Unknown'}</div>
                            <div className="text-sm text-gray-500 flex items-center">
                                <Mail className="w-4 h-4 mr-1" />{request.email || 'N/A'}
                            </div>
                            <div className="text-sm text-gray-500 flex items-center">
                                <Phone className="w-4 h-4 mr-1" />{request.phoneNumber || 'N/A'}
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-1 text-sm font-medium  text-gray-500 w-full my-2 border-t-[.5px] border-t-[#808B8B]">
                        Raised on: <span className='font-semibold text-md ms-2'>{request.createdOn ? moment(request.createdOn).format('DD MMM, YYYY') : 'n/a'}</span>
                    </div>
                    <div className="px-4 py-1 flex justify-between items-center gap-4">
                        <div className='flex-1'>
                            <StatusDropdown
                                data={request}
                                initialStatus={request.status}
                                index={index}
                                isOpenIndex={openDropdownIndex}
                                setIsOpenIndex={setOpenDropdownIndex}
                                handleStatusChange={handleStatusChange}
                            />
                        </div>

                        <OptionsDropdown
                            isOpen={(openDropdownOptionIndex) === index}
                            onRemove={() => handleStatusChange(request.requestId, 'Revoke', true)}
                            onDelete={() => setOpenDropdownOptionIndex(null)}
                            onToggle={() => handleDropdownOptionToggle(index)}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MobileDataView;