import React from 'react'
import './css/pcard.scss'
import icons from './Image/Star.png'

export default function Pcard() {
    return (
        <div className='relative'>
            <div className='md:max-w-[80%] mx-auto relative'>
                <div className="hidden md:block absolute top-0 bottom-0 left-0 w-px bg-gray-200"></div>
                <div className="md:py-16 md:px-12">
                    <div className='pccon p-3 flex-col md:flex-row md:rounded-2xl '>
                        <div className='p-5 md:px-8 md:py-12 flex flex-col gap-2 justify-center md:border-r-[#D6D6D6] md:border-r-2 md:w-[1/3]'>
                            <div className='sno'>
                                <p>01</p>
                            </div>
                            <div className='pcctit'>
                                <h3 className='text-xl font-medium'>New Property Acquisitions</h3>
                            </div>
                            <div className='pccdecs'>
                                <p className='text-md md:text-lg'>
                                    We are expanding our portfolio in high-demand areas with strategic acquisitions of prime properties. Our focus is on identifying locations with strong growth potential and excellent returns on investment.
                                </p>
                            </div>
                        </div>

                        <div className='p-5 md:px-8 md:py-12 flex flex-col gap-2 justify-center md:border-r-[#D6D6D6] md:border-r-2 md:w-[1/3]'>
                            <div className='sno'>
                                <p>02</p>
                            </div>
                            <div className='pcctit'>
                                <h3 className='text-xl font-medium'>Infrastructure Improvements</h3>
                            </div>
                            <div className='pccdecs'>
                                <p className='text-md md:text-lg'>
                                    We aim to enhance property value through key upgrades and infrastructure improvements. By investing in essential amenities and advanced facilities, we ensure our properties remain competitive and attractive to tenants and buyers.                     </p>
                            </div>
                        </div>

                        <div className='p-5 md:px-8 md:py-12 flex flex-col gap-2 justify-center md:w-[1/3]'>
                            <div className='sno'>
                                <p>03</p>
                            </div>
                            <div className='pcctit'>
                                <h3 className='text-xl font-medium'>Development Projects</h3>
                            </div>
                            <div className='pccdecs'>
                                <p className='text-md md:text-lg'>
                                    We are dedicated to creating sustainable residential and commercial developments. Our projects emphasize eco-friendly construction practices and innovative designs to meet the needs of modern living and working spaces.                     </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sban py-10'>
                <div className='sim'>
                    <img src={icons} />
                </div>
                <div className='scons'>
                    <h2 className='text-3xl font-medium mb-2'>Seeking 15% equity investment for $75 million</h2>
                    <p className='text-md font-medium'>- 2020 Investment: $50 million yielded 25% ROI over four years -</p>
                </div>
                <div className='sim'>
                    <img src={icons} />
                </div>
            </div>
        </div>
    )
}
