import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../../assets/images/icons/logo.svg';
import RightArrow from '../../../assets/images/icons/arrow-right-cropped.svg';
import './EmailVerification.scss';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';

const EmailVerification = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [employeeCount, setEmployeeCount] = useState<string | null>(null);
    const [otherType, setOtherType] = useState<string>('');
    const [businessName, setBusinessName] = useState<string | null>(null);
    const [businessHQ, setBusinessHQ] = useState<string | null>(null);
    const [annualRevenue, setAnnualRevenue] = useState<string | null>(null);
    const [businessGoals, setBusinessGoals] = useState<string | null>(null);
    const [businessExp, setBusinessExp] = useState<string | null>(null);
    const [websiteUrl, setWebsiteUrl] = useState<string>('');
    const [websiteUrlChecked, setWebsiteUrlChecked] = useState(false);
    const [invalidField, setInvalidField]: any = useState('');
    const [timeLeft, setTimeLeft] = useState<number>(60);
    const [email, setEmail] = useState<any>('');
    
    useEffect(()=>{
      let email = Cookies.get('registeredEmail');
      console.log('email', email);
      setEmail(email);
      return(()=>{
        // Cookies.remove('registeredEmail');
      })
    },[])

    const employeeCountTypes = [ '1-50 People', '51-200 People', '201+ People'];

  const businessTypes = [
    'Retail', 'Service', 'Manufacturing', 'Hospitality', 'Education',
    'Finance', 'Real Estate', 'Agriculture', 'Industrial', 'Oil and Gas',
    'Transportation and Logistics', 'Construction', 'Entertainment', 'Energy', 'Other'
  ];


  const handleTypeClick = (type: string) => {
    setSelectedType(type);
    if (type !== 'Other') {
      setOtherType('');
    }
  };

  const handleEmployeeCountSelection = (type: string) => {
    setEmployeeCount(type);
  }

    const handleChange = (element: HTMLInputElement, index: number) => {
        if (isNaN(Number(element.value))) return false;
        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        const enteredOtp = otp.join('');

        if (enteredOtp.length === 5) {
            setActive(true);
        }

        // Focus on next input box
        if (element.nextSibling) {
            (element.nextSibling as HTMLInputElement).focus();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Backspace' && otp[index] === '' && index !== 0) {
            (inputRefs.current[index - 1] as HTMLInputElement).focus();
        }
    };

    const handleSubmit = () => {
        if (!validateFields()) return;
        const otpString = otp.join('');
        // Call the API with the enteredOtp
        setLoading(true);
        const token = Cookies.get("authToken");
        let headers = {
        "Authorization" : `Bearer ${token}`
        }
        axios.post('https://api.gaskon.io/api/v1/verifyEmail', { token: otpString }, { headers: headers })
      .then((response) => {
        setLoading(false);
        setCurrentStep(currentStep + 1);
      })
      .catch((error) => {
        setCurrentStep(currentStep + 1);
        console.log('error', error);
        setLoading(false);
        setInvalidField('otperror');
      });
    };

    const Resend = () => {
        let token = Cookies.get('authToken');
        if (!token) {
          navigate('/auth');
        }
        let headers = {
          'Authorization': `Bearer ${token}`
        }
    
        axios.post('https://api.gaskon.io/api/v1/sendEmailVerification', {}, { headers: headers })
          .then((response) => {
            console.log('response', response);
            setTimeLeft(60);
            setInvalidField('');
          })
          .catch((error) => {
            console.log('error', error);
          });
      }

    const validateFields = () => {
        if (!otp.every(char => /^[A-Za-z0-9]{1}$/.test(char))) { // Updated regex
          setInvalidField('otperror');
          return false;
        }
        return true;
      }

    const handleBusinessSelection = () => {
        if(currentStep === 6) {
            createProfile();
            navigate("/dashboard");
        } else {
            console.log('check', businessExp, businessHQ)
            setCurrentStep(currentStep + 1);
        }
    };

    const createProfile = () => {
        setLoading(true);
        const token = Cookies.get("authToken");
        let headers = {
        "Authorization" : `Bearer ${token}`
        }
        let body = {
            "businessName" : businessName,
            "businessType" : selectedType,
            "businessHQ" : businessHQ,
            "businessGoals" : businessGoals,
            "employeeCount": employeeCount,
            "websiteUrl" : websiteUrl

        }
        axios.post("https://api.gaskon.io/api/v1/profile", body, {headers: headers}).then(resp => {
            navigate("/dashboard");
        }).catch(err => {
            console.log(err);
        });
        setLoading(false);
    }

    const businessNameInput = () => {
        return (
            <div className='verify-container'>
                <h1 className='h1' style={{ marginBottom: "60px" }}>What's your business name ?</h1>
                <div style={{color: '#1E1E1E', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '400', lineHeight: '16px', wordWrap: 'break-word'}}>Please enter your full legal name as it appears on official documents.</div>
                <div style={{height: '25px'}}/>
                <input
                  type="text"
                  className="business-name-input"
                  value={businessName!}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
            <div style={{height: '25px'}}/>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: '12px', width:'484px'}}>
            <input style={{ accentColor: websiteUrlChecked ? '#4EA737' : '' }} type="checkbox" checked={websiteUrlChecked} onChange={() => {setWebsiteUrlChecked(!websiteUrlChecked)}}/>
            <div style={{color: '#686868', fontSize: '14px', fontFamily: 'Outfit', fontWeight: '500', lineHeight: '14px', wordWrap: 'break-word', textAlign:'left'}}>I have my Business website</div>
            </div>
            {websiteUrlChecked && <input
              type="text"
              className="business-name-input"
              placeholder='Website Url'
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
            />}
            <div style={{height: '48px'}}/>
            <div className="button-container" onClick={(websiteUrlChecked && websiteUrl && businessName) || (!websiteUrlChecked && businessName)  ? handleBusinessSelection : undefined}>
                <button className="continue-button" style={{ background: (websiteUrlChecked && websiteUrl && businessName) || (!websiteUrlChecked && businessName) ? '#1E1E1E' : '#B9B9B9' }}>
                    Continue
                </button>
            </div>
            </div>
        )
    }

    const businessHQInput = () => {
        return (
            <div className='verify-container'>
                <h1 className='h1' style={{ marginBottom: "60px" }}>Where is your business headquartered?</h1>
                <div style={{color: '#1E1E1E', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '400', lineHeight: '16px', wordWrap: 'break-word'}}>Please enter your Business headquarters or primary location</div>
                <div style={{height: '25px'}}/>
            <input
              type="text"
              className="business-name-input"
            //   placeholder='Website Url'
              value={businessHQ!}
              onChange={(e) => setBusinessHQ(e.target.value)} />
            <div style={{height: '48px'}}/>
            <div className="button-container" onClick={businessHQ  ? handleBusinessSelection : undefined}>
                <button className="continue-button" style={{ background: businessHQ ? '#1E1E1E' : '#B9B9B9' }}>
                    Continue
                </button>
            </div>
            </div>
        )
    }

    const annualRevenueInput = () => {
        return (
            <div className='verify-container'>
                <h1 className='h1' style={{ marginBottom: "60px" }}>What's your current annual revenue or last year's revenue</h1>
                <div style={{color: '#1E1E1E', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '400', lineHeight: '16px', wordWrap: 'break-word'}}>Enter your business's current or last year's revenue (Optional)</div>
                <div style={{height: '25px'}}/>
            <input
              type="text"
              className="business-name-input"
            //   placeholder='Website Url'
              value={annualRevenue!}
              onChange={(e) => setAnnualRevenue(e.target.value)} />
            <div style={{height: '48px'}}/>
            <div className="button-container" onClick={handleBusinessSelection}>
                <button className="continue-button" style={{ background: !loading? '#1E1E1E': '' }}>
                {loading && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1
                          }}
                        >
                          <div className="loader"></div>
                        </div>
                      )} Continue
                </button>
            </div>
            </div>
        );
    }

    const businessGoalsAndDescription = () => {
        return (
            <div className='verify-container'>
                <h1 className='h1' style={{ marginBottom: "60px" }}>How would you describe your business and its main goals?</h1>
                <div style={{color: '#1E1E1E', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '400', lineHeight: '16px', wordWrap: 'break-word'}}>Brief overview of the business and its objectives</div>
                <div style={{height: '25px'}}/>
            <textarea
              className="business-goals-textarea"
            //   placeholder='Website Url'
              onChange={(e) => setBusinessGoals(e.target.value)} />
            <div style={{height: '48px'}}/>
            <div className="button-container" onClick={businessHQ  ? handleBusinessSelection : undefined}>
                <button className="continue-button" style={{ background: businessGoals ? '#1E1E1E' : '#B9B9B9' }}>
                    Continue
                </button>
            </div>
            </div>
        )
    }

    const businessBg = () => {
        return (
            <div className='verify-container'>
                <h1 className='h1' style={{ marginBottom: "60px" }}>Years in business and number of employees?</h1>
                <div style={{color: '#1E1E1E', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '400', lineHeight: '16px', wordWrap: 'break-word'}}>How long the business has been in operation</div>
                <div style={{height: '25px'}}/>
            <input 
             type='text'
             className="business-name-input"
              placeholder='0 Years'
              value={businessExp || ''}
              onChange={(e) => setBusinessExp(e.target.value)}
            />
              <div style={{height: '26px'}}/>
              <div style={{color: '#1E1E1E', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '400', lineHeight: '16px', wordWrap: 'break-word'}}>Current number of employees or team members.</div>
            <div style={{height: '26px'}}/>
            <div className="business-type-container">
          {employeeCountTypes.map((type, index) => (
            <div
              key={index}
              className={`business-type ${employeeCount === type ? 'selected' : ''}`}
              onClick={() => handleEmployeeCountSelection(type)}
            >
              {type}
            </div>
          ))}          
        </div>
            <div className="button-container" onClick={(businessExp && employeeCount)  ? handleBusinessSelection : undefined}>
                <button className="continue-button" style={{ background: (businessExp && employeeCount) ? '#1E1E1E' : '#B9B9B9' }}>
                    Continue
                </button>
            </div>
            </div>
        )
    }

    

    const otpVerificationContent = () => {
        return (
            <div className="verify-container">
                {/* <img src={Logo} alt="Logo" className='logo' /> */}
                <h1 className="ev-title" style={{ marginBottom: "60px" }}>Verify your email</h1>
                <div style={{ color: '#989898', fontSize: 18, fontFamily: 'Outfit', fontWeight: '400', wordWrap: 'break-word' }}>We have sent a 6 - digit code to  </div>
                <div style={{ height: "16px" }}></div>
                <div style={{ display: 'flex', 'flexDirection': 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <div style={{ color: '#1E1E1E', fontSize: 18, fontFamily: 'Outfit', fontWeight: '400', wordWrap: 'break-word' }}>{ email }</div>
                    <div style={{ width: "8px" }}></div>
                    <div style={{ color: '#0177D1', fontSize: 16, fontFamily: 'Outfit', fontWeight: '600', textDecoration: 'underline', wordWrap: 'break-word', cursor: 'pointer' }} onClick={() => navigate(`/signup`)}>Change</div>
                </div>
                <div style={{ height: "49px" }}></div>
                <div className="otp-input-container">
                    {otp.map((data, index) => {
                        return (
                            <input
                                type="text"
                                maxLength={1}
                                key={index}
                                value={data}
                                onChange={e => handleChange(e.target, index)}
                                onKeyDown={e => handleKeyDown(e, index)}
                                ref={el => (inputRefs.current[index] = el)}
                                style={{
                                    width: '65px',
                                    height: '65px',
                                    background: 'white',
                                    boxShadow: '0px 24px 54px rgba(24, 24, 24, 0.07)',
                                    borderRadius: '8px',
                                    border: '1px #E1E5E4 solid',
                                    textAlign: 'center',
                                    fontSize: '24px',
                                }}
                            />
                        );
                    })}
                </div>
                <div className="button-container" onClick={active ? handleSubmit : () => { }} >
                    <button className="continue-button" style={{ background: active || !loading ? '#1E1E1E' : '#B9B9B9' }}>
                    {loading && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1
                          }}
                        >
                          <div className="loader"></div>
                        </div>
                      )} Verify Email</button>
                </div>
                <div className="didnt-receive" onClick={Resend}>
                    Didn't receive? <span className='resend-link'>Resend code</span>
                </div>
                <div style={{ gap: "20px", marginTop: "71px", "display": 'flex', "flexDirection": "row", justifyContent: "center", "alignItems": 'center' }}>
                    <a href="#" className="footer-link">Privacy Policy</a> |
                    <a href="#" className="footer-link">FAQ</a> |
                    <a href="#" className="footer-link">Terms of Use</a>
                </div>
            </div>);
    }

    const businessSelectionContent = () => {
        return (
            <div className="verify-container">
                <h1 className="title" style={{ marginBottom: "76px" }}> What type of business do you own ?</h1>
                <div style={{ width: '100%', textAlign: 'center', color: '#1E1E1E', fontSize: 18, fontFamily: 'Outfit', fontWeight: '400', wordWrap: 'break-word' }}>Select your business type to get started!</div>
                <div style={{ height: "60px" }}></div>
                <div className="business-type-container">
          {businessTypes.map((type, index) => (
            <div
              key={index}
              className={`business-type ${selectedType === type ? 'selected' : ''}`}
              onClick={() => handleTypeClick(type)}
            >
              {type}
            </div>
          ))}          
        </div>
        {selectedType === 'Other' && (
            <input
              type="text"
              className="business-type-input"
              placeholder="Please specify your business type here*"
              value={otherType}
              onChange={(e) => setOtherType(e.target.value)}
            />
          )}
        <div style={{ height: "48px" }}></div>
        <div className="button-container" onClick={selectedType ? handleBusinessSelection : undefined}>
          <button className="continue-button" style={{ background: selectedType ? '#1E1E1E' : '#B9B9B9' }}>
            Continue
          </button>
        </div>
                <div style={{ gap: "20px", marginTop: "71px", "display": 'flex', "flexDirection": "row", justifyContent: "center", "alignItems": 'center' }}>
                    <a href="#" className="footer-link">Privacy Policy</a> |
                    <a href="#" className="footer-link">FAQ</a> |
                    <a href="#" className="footer-link">Terms of Use</a>
                </div>
            </div>
        );
    }

    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return otpVerificationContent();
            case 1:
                return businessNameInput();
            case 2:
                return businessSelectionContent();
            case 3:
                return businessHQInput();
            case 4:
                return businessBg();
            case 5:
                return businessGoalsAndDescription();
            case 6:
                return annualRevenueInput();
            default:
                return otpVerificationContent();
        }
    };


    return (
        <div style={{width: '100%', height: "100%"}}>
            <div style={{display: 'flex', alignItems: 'center', position: 'relative', width: '100%', marginTop: '40px'}}>
                <Link to="/signup" style={{ position: 'absolute', left: 0 }}>
                <div className='back-button'>
                    <div style={{ "display": 'flex', 'flexDirection': 'column', justifyContent: "center" }}><img src={RightArrow} alt="Logo" className='right-arrow' /></div>
                    <div style={{ "display": 'flex', 'flexDirection': 'column', justifyContent: "center", marginLeft: "10px", color: 'black' }}>Back</div>
                </div></Link>
                <div style={{ margin: '0 auto' }}>
                    <img src={Logo} alt="Logo" className='gaskon-logo' />
                </div>
                
            </div>
            {renderStepContent()}
        </div>
    );
};

export default EmailVerification;
