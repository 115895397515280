import React from 'react';
import coreValueImg from '../../../../assets/images/century/coreValueBg.svg'

const CoreValuesSection = () => {
    return (
        <div className="relative min-h-screen bg-cover bg-center flex items-center" style={{ backgroundImage: `url(${coreValueImg})` }}>
            {/* Overlay for background dimming */}
            <div className="absolute inset-0 bg-opacity-20"></div>

            {/* Centered Text Box */}
            <div className="relative flex flex-col items-center justify-center h-full p-5 md:pl-10">
                <div className="relative bg-[#95AEBF] bg-opacity-30 text-center p-8 rounded-lg shadow-md md:max-w-xl mx-auto rounded-r-xl"
                    style={{
                        backdropFilter: 'blur(50px)',
                    }}>
                    {/* Left Gradient Border */}
                    <div className="absolute left-0 top-0 bottom-0 w-1 bg-gradient-to-b from-[#E3A611] to-[#E3A611] rounded-l-lg"></div>

                    <h2 className="text-4xl md:text-5xl font-bold text-white mb-4">Core Values & Vision</h2>
                    <p className="text-lg md:text-xl text-white">
                        UrbanNest Realty is driven by Integrity, Innovation, Excellence, and Sustainability.
                        Our vision is to be a leader in sustainable real estate development, providing exceptional value to our investors and communities.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CoreValuesSection;
