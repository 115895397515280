import { apiEndPoints } from "./apiEndPoints";
import axiosInstance from "./axiosInstance";


export const makeApiCall = async (
  key: keyof typeof apiEndPoints,
  payload: any
) => {
  const endPoint = apiEndPoints[key];

  if (!endPoint) throw new Error("Invalid Api Endpoint");
  console.log("endPoint", endPoint)

//   const formData: any = new FormData();
//   formData.append("jsonStr", JSON.stringify(payload));

  const response = await axiosInstance({
    url: endPoint?.url ?? "",
    method: endPoint?.method ?? "",
    data: payload ?? "",
  });

  return response?.data;
};


