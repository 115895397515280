import React from 'react';
import teamImg from '../../../../assets/images/lusso/team.png'
import handshake from '../../../../assets/images/lusso/handshake.svg'
import bg from '../../../../assets/images/lusso/teambg.svg'

const teamMembers = [
    {
        name: 'John Anderson',
        title: 'FOUNDER & CEO',
        bio: 'With over 15 years of experience in AI and digital transformation, John founded Lusso.ai to revolutionize how businesses leverage AI-driven solutions. Previously, John led successful AI ventures and has been instrumental in scaling startups from the ground up. He\'s also a thought leader in AI ethics and innovation, regularly speaking at major industry conferences.'
    },
    {
        name: 'Sarah Thompson',
        title: 'CHIEF TECHNOLOGY OFFICER',
        bio: 'Sarah has 10+ years of experience in blockchain development and AI infrastructure. Her technical expertise has been crucial in launching multiple AI-driven platforms and advancing blockchain applications in the fintech industry. Sarah\'s work has been recognized for enhancing efficiency and security in digital ecosystems.'
    },
    {
        name: 'Emily Brooks',
        title: 'CHIEF OPERATING OFFICER',
        bio: 'Mark brings extensive experience in business development and operational scaling, having previously overseen growth at a leading AI startup. His strategic insight has helped Lusso.ai secure partnerships with major players and expand rapidly across global markets.'
    }
];

export default function MeetTheTeam() {
    return (
        <div className="bg-[#020202] text-white">
            <div className='bg-cover bg-center bg-no-repeat w-full md:min-h-[100rem] p-8 md:py-[100px]' 
                style={{ backgroundImage: `url(${bg})`, backgroundPosition: 'top' }}>
                <div className="md:max-w-[80%] mx-auto md:py-[200px]">
                    <div className="text-center mb-20 relative">
                        <h1 className="text-4xl font-bold mb-4 relative z-20">MEET THE TEAM</h1>
                        <img src={handshake} alt='handshake' className='absolute left-1/2 transform -translate-x-1/2 -mt-[180px] z-10 h-[250px] w-auto' />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-12">
                        {teamMembers.map((member, index) => (
                            <div key={index} className={`flex gap-8 flex-col md:flex-row items-center md:items-start relative ${index % 2 === 0 ? '' : 'md:flex-row-reverse'}`}>
                                <img src={teamImg} alt={member.name} className="w-44 h-auto mb-4 md:mb-0 md:mr-4" />
                                <div className='md:max-w-[50%] ps-3 relative inline-block inset-0 before:absolute before:inset-0 before:w-[1.5px] before:h-full before:bg-gradient-to-b before:from-[#010101] before:via-white before:to-[#010101]'>
                                    <h3 className="text-sm text-[#01D8E2] mb-1">{member.title}</h3>
                                    <h2 className="text-2xl font-semibold mb-2">{member.name}</h2>
                                    <p className="text-md text-gray-400">{member.bio}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}