import React from 'react';

const RoadmapSection = () => {
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <h2 className="text-4xl md:text-6xl font-bold mb-8 uppercase">Our <br /> Roadmap</h2>
            <div className="md:mt-10 mt-5 flex flex-col gap-8 relative md:pl-10">
                <div className="hidden md:block absolute left-0 top-0 bottom-0 w-2 bg-gradient-to-b from-[#0395FF] to-[#0395FF] rounded-l-lg shadow-[0_8px_124px_0_rgba(30,30,30,0.04)]"></div>

                <div className="border-[#1E1E1E] border-[1px] rounded-2xl p-6 flex-1 relative">
                    <div className="bg-[#373838] py-2 px-4 text-white rounded-full inline-block mb-4">
                        Q1 2025
                    </div>
                    <h3 className="text-xl md:text-3xl uppercase text-[#1E1E1E] font-bold mb-2">
                        Launch eco-friendly fuel options
                    </h3>
                    <p className="text-gray-700">
                        Implement eco-friendly fuel options to reduce carbon footprint.
                    </p>
                </div>
                <div className="border-[#1E1E1E] border-[1px] rounded-2xl p-6 flex-1 relative">
                    <div className="bg-[#373838] text-white py-2 px-4 rounded-full inline-block mb-4">
                        Q3 2025
                    </div>
                    <h3 className="text-xl md:text-3xl uppercase text-[#1E1E1E] font-bold mb-2">
                        Establish international offices and distribution centers
                    </h3>
                    <p className="text-gray-700">
                        Expand global presence by setting up international offices and
                        distribution centers.
                    </p>
                </div>
                <div className="border-[#1E1E1E] border-[1px] rounded-2xl p-6 flex-1 relative">
                    <div className="bg-[#373838] text-white py-2 px-4 rounded-full inline-block mb-4">
                        Q1 2026
                    </div>
                    <h3 className="text-xl md:text-3xl uppercase text-[#1E1E1E] font-bold mb-2">
                        Form alliances with global energy leaders
                    </h3>
                    <p className="text-gray-700">
                        Collaborate with industry-leading energy companies to accelerate
                        growth and innovation.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RoadmapSection;