import React, { useState } from 'react';
import Bannar from './Bannar';
import Header from '../../../../components/Header'; // Corrected import for Header component
import Footer from '../../../../components/Footer'; // Corrected import for Footer component
import Key_Highlights from './Key_Highlights';
import FooterCommon from '../../../../components/common/FooterCommon';
import Gallery from './Gallery';
import CoreValuesSection from './CoreValuesSection';
import PropertiesSection from './PropertiesSection';
import Hotspot from './Hotspot';
import NearbyHotspots from './NearbyHotspots';
import Finance from './Finance';
import FinancialPerformance from './FinancialPerformance';
import Fy_Bannar from './Fy_Bannar';
import Pcard from './Pcard';
import Plan from './Plan';
import Edge from './Edge';
import Mid_bannar from './Mid_bannar';
import SustainabilitySection from './SustainabilitySection';
import FuturePlansGoals from './FuturePlansGoals';
import InvestorFeedbackSlide from './InvestorFeedbackSlide';
import ComprehensiveSection from './ComprehensiveSection';
import ContactInfo from './ContactInfo';
import NewTopHeader from '../../../../components/NewHeader';

export default function Portfolio() {

  return (
    <div className='bg-[#F8F6EF]'>
       <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <div style={{
          position: 'absolute', top: 10, width: '80%', backdropFilter: 'blur(30px)',
          background: '#FFFFFF30', zIndex: '1000', borderRadius: 30
        }}>
          <NewTopHeader />
        </div>
      </div>
      <Bannar />
      <Key_Highlights />
      <CoreValuesSection />
      <PropertiesSection />
      <Gallery />
      <NearbyHotspots />
      <FinancialPerformance />
      <Fy_Bannar />
      <Pcard />
      <Plan />
      <Edge />
      <SustainabilitySection />
      <FuturePlansGoals />
      <InvestorFeedbackSlide />
      <ComprehensiveSection />
      <ContactInfo />
      <FooterCommon /> 
    </div>
  );
}