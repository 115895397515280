import React from 'react';
import './TasksAndDeadlines.scss';

const TasksAndDeadlines: React.FC = () => {
  return (
    <div className="tasks-deadlines">
      <div className="header">
        <h2>Tasks and Deadlines</h2>
        <a href="#">View More</a>
      </div>
      <div className="task-list">
        <div className="task">
          <div className="info">
            <strong>Submit Quarterly Report</strong>
            <span>Due on June 30, 2024</span>
          </div>
          <div className="status pending">Pending</div>
        </div>
        <div className="task">
          <div className="info">
            <strong>Update Marketing Strategy</strong>
            <span>Due on July 15, 2024</span>
          </div>
          <div className="status in-progress">In Progress</div>
        </div>
      </div>
    </div>
  );
};

export default TasksAndDeadlines;
