// @ts-nocheck
import { Clipboard, Edit } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import FilterByDropdown from '../../components/common/FilterByDropdown';
import Cookies from 'js-cookie';
import axios from 'axios';
import { MobileCard } from './PortfolioManagement/MobileCard';
import Swal from 'sweetalert2';
import moment from 'moment';
import Loader from '../../components/common/Loader';
import { clientPaths } from '../../hooks/common.utils';
import { toast } from 'react-toastify';
import FloatingButton from '../../components/common/FloatingButton';


const PortfolioManagement = () => {
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [alert, setAlert] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [portfolios, setPortfolios] = useState<{ clientId: string, active: boolean, businessName: string, createdOn: string, email: string }[] | any>([]);

    useEffect(() => {
        getPortfolioList();
    }, []);

    const getPortfolioList = () => {
        setLoading(true);
        const token = Cookies.get("authToken");
        let headers = {
            "Authorization": `Bearer ${token}`,
        };

        axios.get(`${process.env.REACT_APP_BASE_URL}clients?page=0&size=10`, { headers })
            .then(response => {
                let clients = response.data.clients;
                setPortfolios(clients);
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false)); // Ensure loading is stopped after the API call finishes
    };

    const handleMouseEnter = (index: number) => setHoveredIndex(index);
    const handleMouseLeave = () => setHoveredIndex(null);

    const handleCopy = (email: string) => {
        navigator.clipboard.writeText(email);
        setAlert(`Copied: ${email}`);

        // Hide the alert after 2 seconds
        setTimeout(() => setAlert(null), 2000);
    };


    const handleToggleStatus = (index: number, clientId: string, active: boolean) => {
        const updatedStatus = !active;

        // Show SweetAlert confirmation dialog
        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to ${updatedStatus ? 'activate' : 'deactivate'} this portfolio?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, confirm it!',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                // User confirmed, proceed with the API call
                // setLoading(true);

                const token = Cookies.get("authToken");
                let headers = {
                    "Authorization": `Bearer ${token}`,
                };

                axios.put(`${process.env.REACT_APP_BASE_URL}clients/${clientId}?active=${updatedStatus}`, {}, { headers })
                    .then(response => {
                        if (response.data.message) {
                            // Update the UI based on a successful API response
                            setPortfolios(prevList =>
                                prevList.map((item: any, i: number) =>
                                    i === index ? { ...item, active: updatedStatus } : item
                                )
                            );
                            // Show success alert
                            Swal.fire({
                                title: `Updated!`,
                                text: `The portfolio status has been ${updatedStatus === 'activate' ? 'Activated' : 'Deactivated'}.`,
                                icon: "success",
                                showConfirmButton: false,
                                timer: 2000
                            });
                        } else {
                            // Show error alert if API response message is not "Success"
                            Swal.fire('Failed!', response.data.message, 'error');
                        }
                    })
                    .catch(err => {
                        // Handle error and show alert
                        console.error('API error:', err);
                        Swal.fire('Error!', 'Something went wrong. Please try again.', 'error');
                    })
                // .finally(() => setLoading(false));
            }
        });
    };


    const handleEdit = (index: number) => setEditingIndex(index);

    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        setPortfolios(prevList =>
            prevList.map((item: any, i: number) =>
                i === index ? { ...item, [name]: value } : item
            )
        );
    };

    const handleSubmit = (index: number) => setEditingIndex(null); // Exit edit mode after saving


    const clientRedirect = (clientId) => {
        if (clientPaths[clientId]) {
            window.open(clientPaths[clientId], '_blank');
        } else {
            toast.warning('Route not found', { theme: 'dark' })
        }
    };

    return (
        <div className="p-2 md:p-6 relative">
            <Loader isLoading={loading} />

            <div className='flex flex-row justify-between items-center mb-4 gap-2'>
                <h1 className="text-xl md:text-3xl font-bold">Portfolio Management</h1>
                <FilterByDropdown />
            </div>

            {/* Table */}
            <div className="hidden md:block rounded-lg overflow-x-auto scrollbar-none">
                <table className='min-w-full divide-y divide-gray-200'>
                    <thead className='bg-gray-50'>
                        <tr>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                Portfolio Link
                            </th>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                Company Name
                            </th>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                Created On
                            </th>
                            <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody className='bg-white divide-y divide-gray-200'>
                        {portfolios.map((row: any, index: number) => (
                            <tr key={index}>
                                {/* <td
                                    className='px-6 py-4 relative whitespace-nowrap cursor-pointer'
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => handleCopy(row.email)}
                                >
                                    <span
                                        className={`font-medium ${hoveredIndex === index ? 'text-[#43474E]' : 'text-gray-500'
                                            }`}
                                    >
                                        {row.email}
                                    </span>

                                    {hoveredIndex === index && (
                                        <button
                                            onClick={() => handleCopy(row.email)}
                                            className='absolute top-1/2 transform -translate-y-1/2'
                                        >
                                            <Clipboard className="w-5 h-5 text-gray-500 hover:text-blue-500" />
                                        </button>
                                    )}
                                </td> */}
                                <td className='px-6 py-4'>
                                    <span onClick={() => clientRedirect(row.clientId)} className={`font-medium ${clientPaths[row.clientId] ? 'cursor-pointer' : 'text-red-400 cursor-not-allowed'} ${hoveredIndex === index ? 'text-[#43474E]' : 'text-gray-500'}`}>
                                        {clientPaths[row.clientId] || 'Route Not Found'}
                                    </span>
                                </td>
                                <td className='px-6 py-4'>
                                    <span>{row.businessName}</span>
                                </td>
                                <td className='px-6 py-4'>
                                    <div className="text-sm md:text-lg text-gray-900">{row.createdOn ? moment(row.createdOn).format('DD MMM, YYYY') : 'n/a'}</div>
                                </td>
                                <td className='px-6 py-4'>
                                    <button
                                        onClick={() => handleToggleStatus(index, row.clientId, row.active)}
                                        className={`relative inline-flex h-6 w-11 items-center rounded-full ${row.active ? 'bg-green-600' : 'bg-gray-300'
                                            }`}
                                    >
                                        <span className='sr-only'>Toggle Status</span>
                                        <span
                                            className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${row.active ? 'translate-x-6' : 'translate-x-1'
                                                }`}
                                        />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Mobile View */}
            <div className='md:hidden'>
                {portfolios.map((row: any, index: number) => (
                    <MobileCard key={index} row={row} index={index} handleToggleStatus={handleToggleStatus} clientRedirect={clientRedirect} />
                ))}
            </div>

            {/* Alert */}
            {alert && (
                <div className='fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-4 p-4 bg-green-500 text-white rounded-md shadow-lg 
                        transition-transform duration-300 ease-out animate-slide-up'>
                    {alert}
                </div>
            )}

            <FloatingButton link={'https://calendly.com/lussolabs/gaskon-meet-up'} />

        </div>
    );
};

export default PortfolioManagement;
