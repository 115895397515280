import React from 'react';
import blueFolderIcon from '../../../../assets/images/document/folderBlue.svg'
import { ArrowRight, ChevronRight } from 'lucide-react';

const folders = [
    { name: 'Team Project', files: 4 },
    { name: 'Financials', files: 3 },
    { name: 'Plan Structure', files: 6 },
    { name: 'Pitch Decks', files: 12 },
    { name: 'Performance', files: 6 },
];

const FolderStructure = () => {
    return (
        <div className="bg-white text-[#1e1e1e]">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-[#1e1e1e] text-3xl font-bold">Folders</h2>
                <a href="#" className="text-[#686868] hover:text-[#1e1e1e] hover:underline font-medium text-lg flex gap-1">View All <ChevronRight /></a>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3 md:gap-6">
                {folders.map((folder, index) => (
                    <div key={index} className="bg-white hover:bg-[#E1E5E43D] p-4 border-[1px] border-[#E8E8E8] rounded-xl flex gap-4 cursor-pointer">
                        <img src={blueFolderIcon} alt='blueFolderIcon' className='h-12 w-12' />
                        <div className="flex flex-col justify-center">
                            <span className="font-semibold">{folder.name}</span>
                            <p className="text-sm text-gray-600">{folder.files} Files</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FolderStructure;