import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import TopHeader from "../../components/NewHeader";

const LandingLayout = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        //   marginBottom: "63px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 10,
            width: "80%",
            backdropFilter: "blur(30px)",
            background: "#FFFFFF80",
            zIndex: "1000",
            borderRadius: 30,
          }}
        >
          <TopHeader />
        </div>
      </div>
      <Outlet />
      <Footer />
    </div>
  );
};

export default LandingLayout;
