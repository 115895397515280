
import { useMutation } from "@tanstack/react-query"
import { makeApiCall } from "../services/apiCall";

export const useApiMutation = (key: any) => {
    return useMutation({
        mutationFn: async (payload) => {
            try {
                const data = await makeApiCall(key, payload);
                return data
            } 
            finally {
                // dispatch(toggleLoader(false));                
            }
        },
        onError: () => {
          },
    })
}