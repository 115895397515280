import React from 'react'
import arrow from '../../assets/images/arrow-right.png'
import dot from '../../assets/images/dot.png'
import mem1 from '../../assets/images/mem1.png'
import mem2 from '../../assets/images/mem2.png'
import mem3 from '../../assets/images/mem3.png'
import mem4 from '../../assets/images/mem4.png'
import mem5 from '../../assets/images/mem5.png'
import joins from '../../assets/images/joins.png'
import { FaLinkedinIn, FaXTwitter } from "react-icons/fa6";

import './css/our_team.scss'

const teamMembers = [
    {
        name: "Ethan Reed",
        role: "Chief Investment Officer",
        src: mem1
    },
    {
        name: "Samantha Carter",
        role: "Head of Business Strategy",
        src: mem2
    },
    {
        name: "Olivia Brooks",
        role: "Financial Planning Expert",
        src: mem3
    },
    {
        name: "James Parker",
        role: "Risk Management Specialist",
        src: mem4
    },
    {
        name: "Grace Mitchel",
        role: "Market Analysis Director",
        src: mem5
    },
    {
        name: "Ryan Bennet",
        role: "Sustainability Consultant",
        src: mem1
    },
    {
        name: "Madison Harper",
        role: "Corporate Development Manager",
        src: mem2
    },
    {
        name: "Lucas Morgan",
        role: "Strategic partnership Director",
        src: mem3
    },{
        name: "Emily Scott",
        role: "Strategic partnership Director",
        src: mem3
    },
    {
        name: "Madison Harper",
        role: "Corporate Development Manager",
        src: mem2
    },
    {
        name: "Lucas Morgan",
        role: "Strategic partnership Director",
        src: mem3
    },{
        name: "Emily Scott",
        role: "Strategic partnership Director",
        src: mem3
    }
]

export default function Our_Team() {
  return (
    <div style={{display: 'flex', justifyContent: 'space-between', paddingRight: '100px', paddingTop: '75px', height: '100vh', overflow: 'hidden'}}>

<div className="ot-gradient-circles">
        <div className="ot-circle"></div>
        <div className="ot-circle"></div>
        <div className="ot-circle"></div>
        <div className="ot-circle"></div>
        <div className="ot-circle"></div>
        <div className="ot-circle"></div>
        <div className="ot-circle"></div>
        <div className="ot-circle"></div>
        <div className="ot-circle"></div>
        <div className="ot-circle"></div>
        <div className="ot-circle"></div>
    </div>

    {/* Left Content - Fixed */}
    <div style={{flexDirection: 'column', display: 'flex', gap: '34px', paddingRight: '25px', position: 'sticky', top: '0', alignSelf: 'flex-start', maxWidth: '50%'}}>
        <div style={{color: '#1E1E1E', fontSize: '60px', fontFamily: 'Outfit', fontWeight: '600', textTransform: 'uppercase', lineHeight: '72px', wordWrap: 'break-word', paddingLeft: '100px'}}>
            Empower Your Vision with Our Expert Team
        </div>
        <div style={{color: '#686868', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '400', lineHeight: '24px', wordWrap: 'break-word', paddingLeft: '100px'}}>
            We synergies expertise and innovation to empower organisations with strategic insights and tailored solutions. Our dynamic team of seasoned professionals is dedicated to driving client success through proactive problem-solving and sustainable growth strategies.
        </div>
    </div>

    {/* Right Content - Scrollable Grid */}
    <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '24px', overflowY: 'scroll', width: '100%',maxHeight: '100vh', paddingRight: '25px', paddingBottom: '25px', scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
        {
            teamMembers.map((member, index) => {
                return (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '23px', width: '220px',}} key={index}>
                        <img src={member.src} alt="Member Img" style={{width: '220px', height: '220px'}}/>
                        <div style={{color: '#1E1E1E', fontSize: '32px', fontFamily: 'Outfit', fontWeight: '600', textTransform: 'uppercase', wordWrap: 'break-word'}}>
                            {member.name}
                        </div>
                        <div style={{color: '#676767', fontSize: '16px', fontFamily: 'Outfit', fontWeight: '400', wordWrap: 'break-word'}}>
                            {member.role}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: '20px'}}>
                            <FaLinkedinIn />
                            <FaXTwitter />
                        </div>
                    </div>
                )
            })
        }
    </div>
</div>


  )
}
