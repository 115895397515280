import { useState } from 'react';
import Logo from '../../../assets/images/icons/logo.svg';
import Success from '../../../assets/images/icons/success-tick.png';
import './forgotpassword.scss';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import RightArrow from "../../../assets/images/icons/arrow-right-cropped.svg";
import { CheckCircle } from 'lucide-react';

// Define types for props
interface ForgotPassProps {
  email: string;
  setEmail: (value: string) => void;
  onAction: any;
  loading: boolean;
}

interface EmailProps {
  email: string;
}

// ForgotPass Component (with props typed)
const ForgotPass: React.FC<ForgotPassProps> = ({ email, setEmail, onAction, loading }) => {

  return (
    // <div className="forgot-container">
    //   <div>
    //     <img src={Logo} alt="" />
    //   </div>
    //   <div>
    //     <span className="title-pass">Forgot your password?</span>
    //   </div>
    //   <div className="arrange">
    //     <div>
    //       <input
    //         value={email}
    //         type="text"
    //         className="forgot-pass-input"
    //         placeholder="Registered Email ID"
    //         onChange={(e) => setEmail(e.target.value)}
    //       />
    //     </div>
    //     <div>
    //       <span className="text-style">We’ll email you a password reset link.</span>
    //     </div>
    //     <div>
    //       <button onClick={onAction} className="continue-button" disabled={loading}>
    //         {loading ? 'Sending...' : 'Reset Password'}
    //       </button>
    //     </div>
    //     <div>
    //       <span className="text-style">
    //         If you have any trouble resetting your password, contact us at support@gaskonio.com
    //       </span>
    //     </div>
    //   </div>
    // </div>

    <div className="relative md:max-w-xl mx-auto px-4 py-8">
      <div className="flex flex-col items-center mb-4">
        <img src={Logo} alt="Gaskon Logo" className="w-32 mb-4" />
        <span className="text-3xl font-bold text-center mb-4">Forgot your password?</span>
      </div>

      <form onSubmit={onAction} className="max-w-md mx-auto">
        <div className="bg-white rounded-lg shadow-sm p-6 space-y-4">
          <div className="border border-[#E8E8E8] rounded-md p-5 font-medium">
            <input
              value={email}
              type="email"
              className="w-full text-sm text-[#1e1e1e] placeholder:text-[#B9B9B9] focus:outline-none"
              placeholder="Registered Email id"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div>
            <span className="text-sm text-[#989898]">We'll email you a password rest link.</span>
          </div>

          <button
            type="submit"
            className="w-full bg-black text-white py-3 rounded-md text-md font-medium"
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Reset password'}
          </button>
          {/* {message && (
            <div className="text-sm text-center" style={{ color: message.includes('successfully') ? 'green' : 'red' }}>
              {message}
            </div>
          )} */}
          <div className="text-start">
            <span className="text-sm font-medium text-[#989898]">
              If you have any trouble resetting your password, contact us at{' '}
              <a href="mailto:support@gaskonio.com" className="text-[#989898] underline">
                support@gaskonio.com
              </a>
            </span>
          </div>
        </div>
      </form>
    </div>

  );
};

// EmailSent Component
const EmailSent: React.FC<EmailProps> = ({ email }) => {
  return (
    <div className="forgot-container">
      <div>
        <img src={Logo} alt="" />
      </div>
      <div>
        <span className="title-pass">ITS SORTED!</span>
      </div>
      <div className="arrange">
        <div
          className="forgot-pass-input"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 8,
            }}
          >
            <img src={Success} alt="" />
            <span>Sent Successfully!</span>
          </div>
          <span className="text-style">
            We have sent an email to <span style={{ color: '#000' }}>{email}</span> with password reset
            instructions.
          </span>
        </div>
        <div>
          <span className="text-style">
            If the email doesn’t show up, check your spam folder. We sent it from{' '}
            <span style={{ color: '#000' }}>contact@gaskonio.com</span>
          </span>
        </div>
        <div>
          <button className="continue-button">Return to login</button>
        </div>
      </div>
    </div>
  );
};

const PasswordResetSuccess = ({ email, onReturnToLogin }: any) => {
  const maskedEmail: string = email.replace(
    /^(.{2})(.*)(@.*)$/,
    (_: string, start: string, middle: string, end: string) => start + '*'.repeat(middle.length) + end
  );

  return (
    <div className="relative md:max-w-xl mx-auto px-4 py-8">
      <div className="flex flex-col items-center mb-4">
        <img src={Logo} alt="Gaskon Logo" className="w-32 mb-4" />
        <span className="text-3xl font-bold text-center mb-4">Its Sorted!</span>
      </div>

      <div className="bg-white rounded-lg p-6 mb-4 border-[1px] border-[#E8E8E8]" style={{ boxShadow: '0px 24px 54px 0px #18181812' }}>
        <div className="flex items-center mb-2">
          <CheckCircle className="text-green-500 mr-2" />
          <span className="font-semibold">Sent Successfully!</span>
        </div>
        <p className="text-gray-600">
          We have sent mail to {maskedEmail} with password rest instructions.
        </p>
      </div>

      <p className="text-sm text-gray-500 mb-4">
        If the email doesn't show up check your spam folder. We sent it from{' '}
        <span className="font-semibold text-[#1E1E1E]">contact@gaskonio.com</span>
      </p>

      <button
        onClick={onReturnToLogin}
        className="w-full bg-black text-white py-3 rounded-md text-sm font-medium"
      >
        Return to login
      </button>
    </div>
  );
};

// Main ForgotPassword Component
const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>(''); // State with typing
  const [emailSent, setEmailSent] = useState<boolean>(false); // State with typing
  const [loading, setLoading] = useState<boolean>(false); // State with typing
  const navigate = useNavigate()
  // Action function with proper typing
  const action = (e: any) => {
    e.preventDefault()
    setLoading(true);
    const token = Cookies.get('authToken');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}sendResetPasswordMail?email=${email}`, { headers })
      .then(() => {
        setLoading(false);
        setEmailSent(true);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const onReturnToLogin = () => {
    navigate('/login')
  }

  return (
    <div>
      <Link to="/home" className="flex items-center text-black hover:text-gray-700 absolute top-5 left-6">
        <img src={RightArrow} alt="Back" className="w-4 h-4 mr-2 transform" />
        <span>Back</span>
      </Link>
      {!emailSent ? (
        <ForgotPass email={email} setEmail={setEmail} onAction={action} loading={loading} />
      ) : (
        // <EmailSent email={email} />
        <PasswordResetSuccess email={email} onReturnToLogin={onReturnToLogin} />
      )}
    </div>
  );
};

export default ForgotPassword;
