// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { MoveLeft } from 'lucide-react';
import rightArrow from '../../../../assets/images/lusso/rightArrow.svg'
import r1 from '../../../../assets/images/lusso/r1.svg'
import r2 from '../../../../assets/images/lusso/r2.svg'
import r3 from '../../../../assets/images/lusso/r3.svg'
import r4 from '../../../../assets/images/lusso/r4.svg'
import rightImg from '../../../../assets/images/lusso/roadmap.gif'
import subIcon from '../../../../assets/images/lusso/subRoadIcon.svg'
import overlay from '../../../../assets/images/lusso/roadmapOverlay.svg'
import step1Icon from '../../../../assets/images/lusso/roadmapS1.svg'
import step2Icon from '../../../../assets/images/lusso/roadmapS2.svg'
import step3Icon from '../../../../assets/images/lusso/roadmapS3.svg'
import step4Icon from '../../../../assets/images/lusso/step4Icon.svg'

const TimelineNode = ({ icon, isActive }: any) => (
    <div
        style={{
            background: `linear-gradient(122.72deg, rgba(79, 79, 79, 0.1) 1.74%, rgba(79, 79, 79, 0.1) 1.75%, rgba(255, 255, 255, 0.1) 33.05%, rgba(79, 79, 79, 0.1) 97.16%)`
        }}
        className={`relative flex items-center justify-center w-12 h-12 z-20 rounded-full bg-[#1D1D1D] ${isActive ? 'ring-2 ring-[#281D29] ring-opacity-50' : ''}`}>
        {React.cloneElement(icon, { size: 20, className: isActive ? 'text-purple-500' : 'text-white' })}
    </div>
);

const TimelineItem = ({ icon, phase, title, date, isActive, isCompleted, children, isLast, hasDot = false }: any) => (
    <div className="flex mb-12 relative">
        <div className="relative">
            <TimelineNode icon={icon} isActive={isActive} />
            {!isLast && <div className="z-10 absolute top-12 w-0.5 left-1/2 h-full bg-[#363636] transform -translate-x-1/2"></div>}
            {isActive && !isLast && (
                <>
                    <div className="absolute top-12 left-1/2 blur-sm h-44 bg-[#ef8cff] transform -translate-x-1/2 z-10 animate-spin-slow"
                        style={{
                            border: '3px solid #E962FF'
                        }}></div>
                    {hasDot && <div className="z-10 absolute flex-shrink-0 h-3 w-3 bg-[#ef8cff] animate-bounce rounded-full left-[20px] blur-sm -bottom-7" />}
                </>
            )}
        </div>
        <div className="ml-6 pt-2">
            {phase && <p className="text-[#CC00F2] text-sm font-semibold mb-1">{phase}</p>}
            <div className="flex items-center">
                <h3 className="text-white text-2xl font-bold mr-2">{title}</h3>
                {isCompleted && <span className="text-gray-400 text-sm">» Completed</span>}
            </div>
            <div className='flex flex-col gap-3'>
                <div className="md:flex gap-3">
                    {date && <p className="text-gray-400 text-lg mb-2">{date}</p>}
                    {children && <div className='flex items-start pt-2'>
                        <img src={rightArrow} alt='title' />
                    </div>}
                    {children}
                </div>

                {/* {
                    subchildren &&
                    <div className="md:flex gap-3">
                        <img src={subIcon} alt='sub' />
                        {subdate && <p className="text-gray-400 text-lg mb-2">{subdate}</p>}

                    </div>
                } */}
            </div>

        </div>
    </div>
);

const Roadmap = () => {
    return (
        <div className="bg-[#010101] p-8 md:p-0 md:pb-[100px] relative">
            <div className="md:w-[80%] md:mx-auto">
                <div className='flex justify-between'>
                    <div className='md:w-[70%]'>
                        <h2 className="text-white text-4xl font-bold mb-12 relative pb-2 inline-block after:content-[''] after:absolute 
                            after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-gradient-to-r after:from-white after:to-transparent">
                            ROAD MAP
                        </h2>
                        <div className="relative">
                            <TimelineItem
                                icon={<img src={step1Icon} alt='r1' />}
                                phase="PHASE 1"
                                title="Ask Lusso"
                                isCompleted={true}
                                isActive={true}
                            />
                            <TimelineItem
                                icon={<img src={step2Icon} alt='r2' />}
                                date="Mar - Sep 2024"
                                title=""
                                hasDot={true}
                                isActive={true}
                            >
                                <div className='pb-12'>
                                    <h3 className="text-white text-3xl font-bold mr-2">{'Market Place'}</h3>
                                    <div className='mt-2 pl-2 relative inline-block inset-0 before:absolute before:inset-0 before:w-[1.5px] before:h-full before:bg-gradient-to-b before:from-white before:to-[#010101]'>
                                        <p className="text-gray-400 text-md ml-2">Seed Build Pre/Launch<br />&amp; Business Development</p>
                                        <p className="text-gray-400 text-md mt-2 ml-2">Advanced Features<br />&amp; Custom AI</p>
                                    </div>
                                </div>

                                <div className="absolute -bottom-10 left-10 flex gap-2 items-center">
                                    <MoveLeft color='#7D3CF3' fill='#EF9EFE' size={28} /> <span
                                        style={{
                                            boxShadow: '0px 0px 5.15px 0px #986DE4',
                                        }}
                                        className="bg-[#9F70F3] text-white text-md uppercase font-medium py-1 px-4 tracking-tight rounded">WE ARE HERE</span>
                                </div>
                            </TimelineItem>
                            <TimelineItem
                                icon={<img src={step3Icon} alt='r3' />}
                                date="Oct 2024 - Jan 2025"
                                title=""
                            >
                                <div className="md:flex flex-col gap-5">
                                    <div>
                                        <h3 className="text-white text-3xl font-bold mr-2">{'DEVELOPERS T&D EXPANDED'}</h3>
                                        <div className='mt-2 pl-2 relative inline-block inset-0 before:absolute before:inset-0 before:w-[1.5px] before:h-full before:bg-gradient-to-b before:from-white before:to-[#010101]'>
                                            <p className="text-gray-400 text-md ml-2">Custom Tools &amp; Integrations</p>
                                            <p className="text-gray-400 text-md mt-2 ml-2">LIVE Training &amp; Feedback</p>
                                        </div>
                                    </div>
                                    <div className='flex flex-col md:flex-row gap-2 text-white items-start'>
                                        <div className='flex gap-2 items-center'>
                                            <img src={subIcon} alt='sub' />
                                            <img src={rightArrow} alt='title' />
                                            <span className='text-lg'>Oct 2024 - Jan 2025</span>
                                            <img src={rightArrow} alt='title' />

                                        </div>
                                        <div>
                                            <h3 className="text-white text-3xl font-bold mr-2">{'Market Place PRO'}</h3>
                                            <div className='mt-2 pl-2 text-end'>
                                                <p className="text-gray-400 text-md ml-2">Expansion - Pro Version</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </TimelineItem>
                            <TimelineItem
                                icon={<img src={step4Icon} alt='r4' />}
                                date="Oct - Dec 2024"
                                title=""
                            >
                                <div>
                                    <h3 className="text-white text-3xl font-bold mr-2">{'ENTERPRISE SERVICES'}</h3>
                                    <div className='mt-2 pl-2 relative inline-block inset-0 before:absolute before:inset-0 before:w-[1.5px] before:h-full before:bg-gradient-to-b before:from-white before:to-[#010101]'>
                                        <p className="text-gray-400 text-md ml-2">Custom Solutions</p>
                                        <p className="text-gray-400 text-md ml-2">LIVE Training &amp; Feedback</p>
                                    </div>
                                </div>
                            </TimelineItem>
                            <TimelineItem
                                icon={<img src={step4Icon} alt='r5' />}
                                date="Jan - Feb 2025"
                                title=""
                                isLast={true}
                            >
                                <div>
                                    <h3 className="text-white text-3xl font-bold mr-2">{'ENTERPRISE SERVICES'}</h3>
                                    <div className='mt-2 pl-2 relative inline-block inset-0 before:absolute before:inset-0 before:w-[1.5px] before:h-full before:bg-gradient-to-b before:from-white before:to-[#010101]'>
                                        <p className="text-gray-400 text-md ml-2">Custom Solutions</p>
                                        <p className="text-gray-400 text-md ml-2">LIVE Training &amp; Feedback</p>
                                    </div>
                                </div>
                            </TimelineItem>
                        </div>
                    </div>
                    <div className='md:w-[30%] relative text-center'>
                        <div className='md:h-[70%] w-full relative flex justify-start items-center'>
                            <img className='' src={rightImg} alt='image' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:w-[70%] absolute -top-[22%] -right-[20%] scale-125 z-20'>
                <img src={overlay} alt='map' className='z-10' />
            </div>
        </div>
    );
};

export default Roadmap;