import Developments from '../../../../assets/images/developments.png';
import Contracts from '../../../../assets/images/contracts.png';

const AchievementSection = () => {
    return (
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-4xl md:text-6xl font-bold uppercase mb-8 md:mb-10">Achievements</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="bg-white rounded-2xl p-6 flex" style={{ boxShadow: '0px 8px 124px 0px #1E1E1E0A' }}>
                    <img src={Developments} alt="Development Icon" className="w-16 h-16 mr-4" />
                    <div className='flex flex-col gap-1.5'>
                        <h3 className="text-2xl font-bold uppercase">Development</h3>
                        <p className="text-gray-700">A proprietary high-efficiency solar panel.</p>
                    </div>
                </div>
                <div className="bg-white rounded-2xl p-6 flex" style={{ boxShadow: '0px 8px 124px 0px #1E1E1E0A' }}>
                    <img src={Contracts} alt="Contracts Icon" className="w-16 h-16 mr-4" />
                    <div className='flex flex-col gap-1.5'>
                        <h3 className="text-2xl font-bold uppercase">Contracts</h3>
                        <p className="text-gray-700">With major commercial clients.</p>
                    </div>
                </div>
                <div className="bg-white rounded-2xl p-6 flex" style={{ boxShadow: '0px 8px 124px 0px #1E1E1E0A' }}>
                    <img src={Contracts} alt="Certified Icon" className="w-16 h-16 mr-4" />
                    <div className='flex flex-col gap-1.5'>
                        <h3 className="text-2xl font-bold uppercase">Certified</h3>
                        <p className="text-gray-700">ISO certification for quality & sustainability.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AchievementSection