import React from 'react';
import { Clock, Copy, FilePlus, Square, ThumbsDown, ThumbsUp, X } from 'lucide-react';
import newChatIcon from '../../../../assets/images/document/newChat.svg'
import DocumentInput from './DocumentInput';
import docIcon from '../../../../assets/images/document/docIcon.svg'
import pptIcon from '../../../../assets/images/document/pptIcon.svg'
import xlsIcon from '../../../../assets/images/document/xlsIcon.svg'
import imgIcon from '../../../../assets/images/document/imgIcon.svg'

const FullScreenModal = ({ isOpen, onClose, selectedFiles, onFileSelect, handleAISubmit }: any) => {
    if (!isOpen) return null;

    const getFileIcon = (type: string) => {
        switch (type) {
            case 'pdf':
                return 'PDF'; // You can use a PDF icon here if available
            case 'doc':
                return <img src={docIcon} alt="DOC Icon" className="w-12 h-12" />;
            case 'ppt':
                return <img src={pptIcon} alt="PPT Icon" className="w-12 h-12" />;
            case 'xls':
                return <img src={xlsIcon} alt="XLS Icon" className="w-12 h-12" />;
            case 'img':
                return <img src={imgIcon} alt="Image Icon" className="w-12 h-12" />;
            default:
                return 'Unknown';
        }
    }

    // render
    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">
            <div className="bg-white w-full h-full rounded-lg shadow-lg flex">
                {/* Left Sidebar */}
                <div className="w-20 md:w-40 bg-[#F7F7F7] p-4 flex flex-col items-center gap-2">
                    <div className='flex gap-2 justify-center items-center min-h-[70px]'>
                        <button
                            className="text-[#1e1e1e] bg-[#F2F3F2] rounded-lg p-1 border-[1px] border-[#808B8B33]"
                            onClick={onClose}
                        >
                            <X size={16} />
                        </button>
                        <span className='text-xl font-bold'>Ask AI</span>
                    </div>
                    <div className='max-h-[80%] overflow-y-auto scrollbar-none'>
                        {selectedFiles.map((file: any, idx: number) => (
                            <div key={idx} className="mb-4 flex flex-col items-center">
                                <div className={`text-white flex justify-center items-center rounded-md`}>
                                    {getFileIcon(file.type?.toLowerCase())}
                                </div>
                                <div className="text-xs mt-1 text-center">{file.name}</div>
                            </div>
                        ))}
                    </div>

                    <button
                        onClick={() => onFileSelect({ name: 'New Document', type: 'PDF' })}
                        className="gap-2 w-fit mt-4 py-2 px-4 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-md flex justify-center items-center text-md"
                    >
                        <FilePlus size={15} />
                        Add
                    </button>
                </div>

                {/* Main Content */}
                <div className="flex-1 overflow-y-auto relative">
                    {/* Top Bar */}
                    <div className="bg-[#F7F7F7] flex flex-col md:flex-row gap-2 justify-end items-center mb-4 p-4 h-[70px]">
                        <div className="flex items-center space-x-2 p-2">
                            <button className="flex items-center space-x-1 text-[#686868] hover:text-[#1e1e1e]">
                                <Clock size={18} />
                                <span className="text-sm font-medium">Sessions</span>
                            </button>
                            <button className="flex items-center space-x-1 bg-white text-[#1e1e1e] hover:bg-gray-100 py-1 px-3 rounded-md border border-gray-300 shadow-sm">
                                <span className="text-sm font-medium">New Session</span>
                                <img src={newChatIcon} alt='newChatIcon' className='w-4 h-4' />
                            </button>
                        </div>
                    </div>

                    {/* Document Content */}
                    <div className="bg-white p-6 rounded-lg">
                        {/* User Message */}
                        <div className="flex justify-end items-center mb-10">
                            {/* <h2 className="text-xl font-semibold text-gray-800">Summary of Safety Procedure Manual</h2> */}
                            <button className="bg-blue-100 text-blue-700 px-4 py-2 rounded-md text-sm font-medium">
                                Give me a summary of @Safety Procedure Manual
                            </button>
                        </div>
                        {/* Bot Message */}
                        <>
                            <div className="text-gray-700 space-y-4">
                                <p>
                                    The Sustainability Plan 2024 outlines key strategies and initiatives to reduce environmental impact and promote sustainable growth. The document focuses on three primary areas: energy efficiency, waste reduction, and community engagement.
                                </p>
                                <p>Key highlights include:</p>
                                <ul className="list-disc pl-5 space-y-2">
                                    <li>
                                        <strong>Energy Efficiency:</strong> Commitment to reducing carbon emissions by 20% through the adoption of renewable energy sources and improved energy management systems.
                                    </li>
                                    <li>
                                        <strong>Waste Reduction:</strong> Implementation of a company-wide recycling program aimed at achieving a 50% reduction in waste output by the end of 2024.
                                    </li>
                                    <li>
                                        <strong>Community Engagement:</strong> Partnerships with local organizations to promote sustainability education and support green initiatives in surrounding communities.
                                    </li>
                                </ul>
                                <p>
                                    The plan also includes detailed timelines, expected outcomes, and methods of monitoring progress to ensure targets are met by year-end.
                                </p>
                            </div>
                            <div className="flex space-x-4 mt-6">
                                <button className="p-2 hover:bg-gray-100 rounded">
                                    <Copy size={20} className="text-gray-500" />
                                </button>
                                <button className="p-2 hover:bg-gray-100 rounded">
                                    <ThumbsUp size={20} className="text-gray-500" />
                                </button>
                                <button className="p-2 hover:bg-gray-100 rounded">
                                    <ThumbsDown size={20} className="text-gray-500" />
                                </button>
                            </div>
                        </>
                    </div>

                    {/* Input Field */}
                    <div className='absolute bottom-5 w-full justify-center flex'>
                        <DocumentInput onSubmit={handleAISubmit} />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default FullScreenModal;