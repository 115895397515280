import React from 'react';
import PhoneIcon from '../../../../assets/images/phoneIcon.svg';
import Instagram from '../../../../assets/images/instagramlogo.png';
import LinkedIn from '../../../../assets/images/linkedinlogo.png';
import X from '../../../../assets/images/X.png';
import RightArrow from '../../../../assets/images/arrow-right-black.svg';

const ContactInfo = () => {
    return (
        <div className="relative text-[#767676]">
            <div className='md:max-w-[80%] mx-auto relative p-3'>
                <div className="hidden md:block absolute top-0 bottom-0 left-0 w-px bg-gray-200"></div>
                <div className="pt-8 md:py-[100px] md:pl-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-center">
                        <div className="space-y-4">
                            <h1 className="text-4xl font-bold mb-6 text-[#414141]">Get in touch</h1>

                            <p className="text-[#767676] text-lg">
                                At Century 21 Realty, we value our investors and strive to provide exceptional support and transparent communication. For inquiries or to discuss investment opportunities, please reach out to our dedicated investor relations team. We're here to assist you with detailed information, personalized guidance, and any questions you may have about our projects and strategies.
                            </p>
                            <button className="flex items-center justify-center">
                                <div className="bg-[#15171A] text-white px-2 py-1.5 ps-4 rounded-full flex items-center justify-between w-fit cursor-pointer">
                                    <span className="font-medium me-2">Contact Us</span>
                                    <img src={RightArrow} alt="Phone Icon" className="ml-3 p-2 bg-white rounded-full" />
                                </div>
                            </button>
                        </div>

                        <div className="space-y-6">
                            <div className='p-5 bg-white rounded-2xl'>
                                <h2 className="text-xl font-semibold mb-2 text-[#414141]">Company Address</h2>
                                <p>1234 Real Estate Ave, Suite 567,<br />Metropolis, USA</p>
                            </div>
                            <div className='p-5 bg-white rounded-2xl'>
                                <h2 className="text-xl font-semibold mb-2 text-[#414141]">Website</h2>
                                <a href="https://www.century21realty.com" className="hover:underline text-[#414141]">www.century21realty.com</a>
                            </div>
                        </div>

                        <div className="space-y-6">
                            <div className='p-5 bg-white rounded-2xl'>
                                <h2 className="text-xl font-semibold mb-2 text-[#414141]">Investor Relations</h2>
                                <p>Email:<br /><a href="mailto:investor.relations@century21.com" className="text-[#414141] hover:underline">investor.relations@century21.com</a></p>
                                <p className="mt-2  text-[#414141]">Mobile:<br />+1-555-123-4567</p>
                            </div>
                            <div className="flex space-x-4">
                                <a href="https://twitter.com" className="social-icon twitter" aria-label="Twitter">
                                    <img src={X} alt='' />
                                </a>
                                <a href="https://linkedin.com" className="social-icon linkedin" aria-label="LinkedIn">
                                    <img src={LinkedIn} alt='' />
                                </a>
                                <a href="https://instagram.com" className="social-icon instagram" aria-label="Instagram">
                                    <img src={Instagram} alt='' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;