import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './FooterAnimation.scss';

const texts = [
  "Business",
  "Success",
  "Enterprise"
];

const FooterAnimation = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="footer-animation">
      <TransitionGroup>
        <CSSTransition
          key={index}
          timeout={500}
          classNames="fade"
        >
          <div>{texts[index]}</div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default FooterAnimation;
