// @ts-nocheck
import React from 'react'
import bg from '../../../../assets/images/lusso/valuationBg2.svg'
import SubscriptionMetricsTable from './SubscriptionMetricsTable'
import one from '../../../../assets/images/lusso/1.svg'
import two from '../../../../assets/images/lusso/2.svg'
import three from '../../../../assets/images/lusso/3.svg'
import SummeryInfoCard from './SummeryInfoCard'
import LussoStandOut from './LussoStandOut'

function ValuationGrowth() {

    let infoData = [
        {
            img: one,
            title: 'Initial Valuation with Active Subscribers',
            desc: [
                "Lusso.ai's business model is heavily driven by subscription revenue, providing a clear path to valuation growth.",
                "With 500 active subscribers, the platform can generate a revenue of $200K annually, leading to a sellable valuation of $1.5 million, based on a 3x revenue multiplier."
            ]
        },
        {
            img: two,
            title: 'Scaling the Subscriber Base',
            desc: [
                "1,000 active subscribers can generate $400K in revenue, with a potential valuation of $3million.",
                "5,000 active subscribers would generate $2 million in revenue, pushing the valuation up to $10 million.",
                "10,000 active subscribers results in $4 million in revenue, placing the company at a valuation of $20 million."
            ]
        },
        {
            img: three,
            title: 'Projected Subscriber Growth',
            desc: [
                "Our conservative estimates project that Lusso.ai can onboard 20,000 active subscribers by the end of year three, translating into $8 million in annual revenue and an overall valuation of $40 million.",
                "This potential is driven by high-value partnerships, targeted marketing, and product scalability across multiple industries."
            ]
        }
    ];

    return (

        <>
            <div className='bg-gradient-to-r from-black via-[#010101] to-[#010101]'>
                <div className='bg-cover bg-center bg-no-repeat w-full' style={{
                    backgroundImage: `url(${bg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'top',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                }}>
                    <div className=" text-white p-8 font-sans md:w-[80%] mx-auto md:pt-[150px] ">
                        {/* Header */}
                        <div className="relative text-center mb-10">
                            <p className="text-[#01D8E2] text-sm uppercase tracking-wider mb-2">
                                SUBSCRIPTION BASED METRICS
                            </p>
                            <h1 className="text-4xl md:text-4xl lg:text-4xl font-bold leading-tight">
                                VALUATION & GROWTH POTENTIAL
                            </h1>
                            <div className="w-[60%] absolute -bottom-4 left-1/2 transform -translate-x-1/2 h-px bg-gradient-to-r from-transparent via-white to-transparent opacity-30" />
                        </div>

                        {/* Boxes */}
                        <div className='flex flex-col md:flex-row gap-8 md:justify-center md:my-[100px]'>
                            {
                                infoData?.map(e => (
                                    <SummeryInfoCard img={e?.img} title={e?.title} desc={e?.desc} />
                                ))
                            }
                        </div>

                        {/* Table */}
                        <SubscriptionMetricsTable />

                        <div className='my-10 md:my-[200px] text-center'>
                            <div className="relative mb-5">
                                <p className="text-[#01D8E2] text-sm uppercase tracking-wider mb-2">
                                    Case Study
                                </p>
                                <h1 className="text-4xl md:text-4xl lg:text-4xl font-bold leading-tight uppercase">
                                    Fast Subscriber Growth
                                </h1>
                                <div className="w-[60%] absolute -bottom-4 left-1/2 transform -translate-x-1/2 h-px bg-gradient-to-r from-transparent via-white to-transparent opacity-30" />

                            </div>
                            <p className='text-md text-white md:max-w-5xl md:mx-auto'>In a recent campaign using Lusso.ai’s proprietary marketing tools, we onboarded 500+ subscribers in under 24 hours, generating $100K in revenue at a marketing cost of only $19K. This rapid onboarding shows the scalability and market potential of the platform.</p>
                        </div>


                    </div>
                </div>
            </div>

           
        </>
    )

}

export default ValuationGrowth