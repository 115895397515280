import React from 'react';
import './RecentActivities.scss';

const RecentActivities: React.FC = () => {
  return (
    <div className="recent-activities">
      <div className="header">
        <h2>Recent Activities</h2>
      </div>
      <div className="activity-list">
        <div className="activity">
          <div className="avatar"></div>
          <div className="info">
            <span>Document Uploaded:</span>
            <strong>Business_Plan.pdf</strong>
            <span>by Sarah Johnson</span>
            <time>Yesterday 13:49</time>
          </div>
        </div>
        <div className="activity">
          <div className="avatar"></div>
          <div className="info">
            <span>Portfolio Edited: </span>
            <strong>Tech Innovations</strong>
            <span>Portfolio by Michael Lee</span>
            <time>Yesterday 11:41</time>
          </div>
        </div>
        <div className="activity">
          <div className="avatar"></div>
          <div className="info">
            <span>Investor Feedback: </span>
            <strong>Investor Robert Brown left feedback on Green Energy Portfolio</strong>
            <div className="feedback">@Greewee Your portfolio showcases impressive innovation and growth potential. Consider highlighting recent milestones to attract more investors</div>
            <time>Yesterday 11:41</time>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentActivities;
